import { serverTimestamp } from "firebase/firestore";
import assets from "../utils/assets";

export const cardBacks = ["B0006", "B0008", "B0009", "B0011"];

export const deckConstructor = (options = {}) => {
  const backImageID = cardBacks[0];
  return {
    name: options.name || "My Deck",
    deckID: options.deckID,
    backImage: {
      type: "standard",
      id: backImageID,
      src: {
        horizontal: assets[`${backImageID}_horizontal.png`],
        vertical: assets[`${backImageID}_vertical.png`],
      },
    },
    sharedWith: [],
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    deleted_at: null,
  };
};

export const albumConstructor = (options = {}) => {
  return {
    name: options.name || "Default",
    status: "display",
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    deleted_at: null,
  };
};
