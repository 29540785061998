import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./auth-provider";
import { Navigate } from "react-router-dom";
import Loading from "./loading";

// Protect routes that require authenticated users by checking for
// a logged in user before rendering the children

const RequireAuth = ({ children, redirectTo }) => {
  const { currentUser, pending } = useContext(AuthContext);

  const location = useLocation();

  // console.log(location.pathname);

  const state = {
    from: {
      pathname: location.pathname,
      search: location.search,
    },
  };

  if (pending) {
    return <Loading />;
  }

  return !!currentUser ? children : <Navigate to={redirectTo} state={state} />;
};

export default RequireAuth;
