import React, { useContext, useEffect, useState } from "react";

import AppContext from "./app-context";
import { AuthContext } from "./auth-provider";
import { useParams } from "react-router-dom";
import theme from "../styles/theme";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import {
  HEADER_HEIGHT_PX,
  PROOFS_CARD_LONG_DIM_PX,
  BORDER_RADIUS_RATIO,
  CARD_ASPECT_RATIO,
} from "../utils/constants";

// Firebase
import firebaseApp from "../utils/firebase";
import {
  getDocs,
  query,
  getFirestore,
  collection,
  orderBy,
  onSnapshot,
  collectionGroup,
  where,
  limit,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

const headerHeight = `${HEADER_HEIGHT_PX}px`;

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  mobileRoot: {
    width: "100vw",
    height: "150vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  modalRoot: {
    width: "100vw",
    height: "100vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  appBar: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    height: headerHeight,
    backgroundColor: theme.palette.primary.main,
  },
  continueButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  gallery: {
    background: "#A9A9A9",
    padding: "5px 5px 80px 5px",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    width: "100%",
  },
  proofImage: {
    margin: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "white",
    backgroundSize: "cover",
  },
  logoDiv: {
    width: "60px",
    height: "60px",
    margin: "10px",
    borderRadius: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    overflow: "hidden",
    cursor: "pointer",
  },
  logo: {
    width: "60px",
    height: "auto",
  },
  logoDivMobile: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "60px",
    height: "60px",
    margin: "10px",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "0 0.5rem 1rem #808080",
  },
  modal: {
    zIndex: 1000,
    position: "fixed",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    // height: '400px',
    maxWidth: "350px",
    padding: "10px",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  loading: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px",
  },
};

function DigitalDeck() {
  const { state, dispatch } = useContext(AppContext);
  const { isAdmin } = useContext(AuthContext);

  const navigate = useNavigate();
  const { id } = useParams();

  const [proofList, setProofList] = useState([]);
  const [printRef, setPrintRef] = useState();

  const [cardDimensions, setCardDimensions] = useState({
    long: 0,
    short: 0,
  });

  useEffect(() => {
    if (!id) return;

    const getPrintRef = async () => {
      const thisPrintQuery = query(
        collectionGroup(db, "prints"),
        where("printID", "==", id),
        limit(1)
      );
      const thisPrintRef = await getDocs(thisPrintQuery)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].ref;
          } else {
            dispatch({
              type: "ADD_ALERT",
              payload: {
                alert: {
                  type: "error",
                  message: `There is no deck with the id [${id}]`,
                },
              },
            });
            console.error(
              `Error displaying gallery. There is no deck with the id [${id}]`
            );
            navigate("/deck");
            return null;
          }
        })
        .catch((err) => {
          dispatch({
            type: "ADD_ALERT",
            payload: {
              alert: {
                type: "error",
                message: `Error displaying gallery. Please try again or contact support.`,
              },
            },
          });
          console.error(`Error displaying gallery for id [${id}]`, err);
          navigate("/deck");
          return null;
        });

      // Only let the owner see this
      // But for now make the proofs gallery public so people can share
      // if (!isAdmin) {
      //     const ownerID = thisPrintRef.parent.parent.id
      //     if (userID !== ownerID) {
      //         dispatch({ type: "ADD_ALERT", payload: { alert: { type: 'error', message: `You are not authorized to view this deck.` } } })
      //         console.error(`Error displaying gallery. User is not authorized to access it.`)
      //         navigate('/deck')
      //         return
      //     }
      // }

      // console.log(thisPrintRef.id)

      if (!!thisPrintRef) setPrintRef(thisPrintRef);
    };

    getPrintRef();
  }, [dispatch, navigate, id, isAdmin]);

  useEffect(() => {
    const updateData = async () => {
      let unSubscribe = () => {};
      try {
        const printsQuery = query(
          collection(printRef, "images"),
          orderBy("orientation", "desc")
        );

        unSubscribe = onSnapshot(
          printsQuery,
          (querySnapshot) => {
            const images = [];
            querySnapshot.forEach((doc) => {
              images.push(doc.data());
            });
            setProofList(images);
          },
          (error) => {
            process.env.REACT_APP_ENV === "dev" &&
              console.log(`onSnapshot error: ${error}`);
          }
        );
        dispatch({
          type: "LISTENER_UNSUBSCRIBE",
          payload: { func: unSubscribe },
        });
      } catch (error) {
        console.log("Error querying data for proof list: ", error);
      } finally {
        return () => unSubscribe();
      }
    };

    if (!printRef || !printRef.id) return;

    updateData();
  }, [dispatch, printRef]);

  useEffect(() => {
    // The long dimension of the cards in pixels
    let cardLongPX;

    // Set card size based on the platform
    if (state.app.isMobile) {
      // On mobile, fit the card to the width in landscape orientation
      cardLongPX = window.innerWidth * 0.95;
    } else {
      cardLongPX = PROOFS_CARD_LONG_DIM_PX;
    }

    const cardShortPX = Math.round(cardLongPX * CARD_ASPECT_RATIO);
    cardLongPX = Math.round(cardLongPX);

    const borderRadiusPX = cardLongPX * BORDER_RADIUS_RATIO;

    setCardDimensions({
      longPX: cardLongPX,
      shortPX: cardShortPX,
      borderRadiusPX,
    });
  }, [state.app.isMobile]);

  const goHome = () => {
    navigate("/deck");
  };

  const rootClass = state.app.isMobile ? styles.mobileRoot : styles.root;

  const cardStyles = {
    horizontal: {
      width: `${cardDimensions.longPX}px`,
      height: `${cardDimensions.shortPX}px`,
      borderRadius: `${cardDimensions.borderRadiusPX}px`,
    },
    vertical: {
      width: `${cardDimensions.shortPX}px`,
      height: `${cardDimensions.longPX}px`,
      borderRadius: `${cardDimensions.borderRadiusPX}px`,
    },
  };

  if (proofList.length === 0) {
    return (
      <div style={styles.modalRoot}>
        <div style={styles.modal}>
          <div style={styles.headingBig}>
            <Typography variant="inherit">Loading Images</Typography>
          </div>

          <div style={styles.loading}>
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={rootClass}>
      {/* <AppBar position="sticky" color='default' style={classes.appBar}>
                <Button variant='contained' color="primary" onClick={handleContinue} style={classes.continueButton}>Approve</Button>
                <IconButton aria-label="close" style={classes.closeButton} onClick={handleClose}>
                    <CancelIcon />
                </IconButton>
            </AppBar> */}

      <div
        onClick={goHome}
        style={state.app.isMobile ? styles.logoDivMobile : styles.logoDiv}
      >
        <img src="/logo.png" alt="logo" style={styles.logo} />
      </div>

      <div style={styles.gallery}>
        {proofList.map((proof) => (
          <div
            key={proof.src}
            style={{
              ...cardStyles[proof.orientation],
              backgroundImage: `url(${proof.src})`,
              ...styles.proofImage,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default DigitalDeck;
