// app-context.js
import React, { useContext, createContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useImmerReducer } from "use-immer";
import { AuthContext } from "./auth-provider";
import { deckConstructor, albumConstructor } from "../utils/data";
import { stripePromise } from "../utils/stripe";

// Firebase
import firebaseApp from "../utils/firebase";
import { getFirestore } from "firebase/firestore";
import {
  serverTimestamp,
  collection,
  collectionGroup,
  doc,
  writeBatch,
  setDoc,
  getDocs,
  getDoc,
  updateDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

const userAgent =
  typeof window.navigator === "undefined" ? "" : navigator.userAgent;
const isMobile = Boolean(
  userAgent.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  )
);

// Initial app context state
// TODO: load from the database
const initialState = {
  app: {
    gitCommit: process.env.REACT_APP_VERSION,
    isLoading: true,
    isMobile,
    unSubscribes: [],
    alerts: {},
    modal: {},
    uploadStatus: null,
    controls: {
      backSelectOpen: false,
      addFabOpen: false,
      flip: false,
      drawerIsOpen: false,
      decksOpen: false,
      sharedDecksOpen: false,
      libraryOpen: false,
      triggerUpload: false,
      orderIsProcessing: false,
    },
    cardPreviews: {},
    cardPrice: 2,
  },
  userData: {},
  decks: {
    deckList: [],
    sharedDeckList: [],
    activeDeckRef: null,
    activeDeck: {},
    activeCardList: [],
    albumSourceSet: new Set(),
  },
  library: {
    allbumlist: [],
    // initialAlbumID: "ebb61632-8c51-47b3-a2e2-8ccf4373bde4",
    activeAlbumRef: null,
    activeAlbum: {},
    activeAlbumCardList: [],
  },
};

// AppContext is the high level data store context for the App
const AppContext = createContext(null);

export function AppProvider({ children }) {
  // Check to see if the user is authenticated before fetching data
  const { currentUser, pending } = useContext(AuthContext);
  const urlParams = useParams();
  // const navigate = useNavigate();

  // Main reducer for App context
  const reducer = (draft, action) => {
    process.env.REACT_APP_DEBUG === "true" && console.log({ action });
    switch (action.type) {
      case "RESET_STATE":
        draft = initialState;
        return draft;
      case "APP_LOADED":
        draft.app.isLoading = false;
        return draft;
      case "ADD_ALERT": // Add alert to the list of active alerts
        // type: success, info, warning, error
        // message: string
        draft.app.alerts[action.payload.key || Date.now()] =
          action.payload.alert;
        return draft;
      case "CLEAR_ALERT":
        delete draft.app.alerts[action.payload.key];
        return draft;
      case "MODAL": // Set open/closed draft of App dialogs
        draft.app.modal = action.payload;
        return draft;
      case "CONTROL": // Top level draft of app controls
        draft.app.controls[action.payload.name] = action.payload.state;
        return draft;
      case "RESET_FLIP": // Reset flip initiator
        draft.app.controls.flip = false;
        return draft;
      case "FLIP_CARDS": // Initiate card flip sequence
        draft.app.controls.flip = true;
        return draft;
      case "SET_PREVIEW_IMAGE": {
        // args: cardID, src // Insert the preview into draft.cardPreviews with the cardID as the key
        let preview = {
          src: action.payload.src,
          orientation: action.payload.orientation,
        };
        draft.app.cardPreviews[action.payload.cardID] = preview;
        return draft;
      }
      case "CLEAR_PREVIEW_IMAGE": // Delete the image keyed off the cardID
        // args: cardID
        delete draft.app.cardPreviews[action.payload.cardID];
        return draft;
      case "START_UPLOAD": // Configure and open upload modal
        if (!draft.app.uploadStatus) {
          draft.app.uploadStatus = {
            data: {
              totalFiles: action.payload.totalFiles,
              totalBytes: action.payload.totalBytes,
              uploadCount: 0,
              failedCount: 0,
              failedNames: [],
              fileBytes: {},
            },
          };
        } else {
          draft.app.uploadStatus.data.totalFiles += action.payload.totalFiles;
          draft.app.uploadStatus.data.totalBytes += action.payload.totalBytes;
        }

        return draft;
      case "FILE_UPLOAD_UPDATE": {
        if (draft.app.uploadStatus) {
          draft.app.uploadStatus.data.fileBytes[action.payload.cardID] =
            action.payload.bytes;
        }
        return draft;
      }
      case "FILE_UPLOAD_SUCCESS": // Register one successful file uploaded
        if (draft.app.uploadStatus) {
          draft.app.uploadStatus.data.uploadCount++;
          // TODO: Clean up cards for failed uploads and notify the user
          // Even if they have a preview image, they need an original image to be valid
          if (
            draft.app.uploadStatus.data.uploadCount +
              draft.app.uploadStatus.data.failedCount ===
            draft.app.uploadStatus.data.totalFiles
          ) {
            draft.app.uploadStatus = null;
          }
        }
        return draft;
      case "FILE_UPLOAD_FAILURE": // Register a failed file upload
        // args: fileName
        if (draft.app.uploadStatus) {
          draft.app.alerts[Date.now()] = {
            type: "error",
            message: `Upload failed for ${action.payload.fileName}`,
          };
          draft.app.uploadStatus.data.failedCount++;
          draft.app.uploadStatus.data.failedNames.push(action.payload.fileName);
          // Even if they have a preview image, they need an original image to be valid
          if (
            draft.app.uploadStatus.data.uploadCount +
              draft.app.uploadStatus.data.failedCount ===
            draft.app.uploadStatus.data.totalFiles
          ) {
            // TODO: Open file failure modal to show names and let the user confirm
            draft.app.uploadStatus = null;
          }
        } else {
          console.error(
            "Attempt to register file upload failure but file upload modal is not open."
          );
        }
        return draft;
      case "UPDATE_ACTIVE_DECK_REF":
        // Skip the update if there is already an active deck
        // and this update is coming from the initialization method
        if (!draft.decks.activeDeckRef || !action.payload.isInit) {
          draft.decks.activeDeckRef = action.payload.activeDeckRef;
        }
        return draft;
      case "CLEAR_ACTIVE_DECK_REF":
        draft.decks.activeDeckRef = null;
        draft.decks.activeDeck = {};
        draft.decks.activeCardList = [];
        return draft;
      case "CLEAR_ACTIVE_ALBUM_REF":
        draft.library.activeAlbumRef = null;
        draft.library.activeAlbum = {};
        draft.library.activeAlbumCardList = [];
        return draft;
      case "UPDATE_USER_DATA":
        draft.userData = action.payload.userData;
        return draft;
      case "UPDATE_DECK_LIST":
        draft.decks.deckList = action.payload.deckList;
        return draft;
      case "UPDATE_SHARED_DECK_LIST":
        draft.decks.sharedDeckList = action.payload.sharedDeckList;
        return draft;
      case "UPDATE_ACTIVE_DECK":
        draft.decks.activeDeck = action.payload.activeDeck;
        return draft;
      case "UPDATE_ACTIVE_CARD_LIST":
        draft.decks.activeCardList = action.payload.activeCardList;
        return draft;
      case "UPDATE_ALBUM_SOURCE_SET":
        const newSourceSet = new Set();
        action.payload.sourceSet.forEach((sourceID) => {
          newSourceSet.add(sourceID);
        });
        draft.decks.albumSourceSet = newSourceSet;
        return draft;
      case "UPDATE_ACTIVE_ALBUM_CARD_LIST":
        draft.library.activeAlbumCardList = action.payload.activeAlbumCardList;
        return draft;
      case "UPDATE_ALBUM_LIST":
        draft.library.albumList = action.payload.albumList;
        return draft;
      case "UPDATE_ACTIVE_ALBUM_REF":
        draft.library.activeAlbumRef = action.payload.activeAlbumRef;
        return draft;
      case "UPDATE_ACTIVE_ALBUM":
        draft.library.activeAlbum = action.payload.activeAlbum;
        return draft;
      case "LISTENER_UNSUBSCRIBE":
        draft.app.unSubscribes.push(action.payload.func);
        return draft;
      default:
        return draft;
    }
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  // RESET STATE
  // 1) When there is no currently logged in user
  // 2) When the user changes
  useEffect(() => {
    // There is no currently authenticated user, reset the state to clear stale data
    if (!pending && !currentUser) {
      dispatch({ type: "RESET_STATE" });
      return;
    }
  }, [dispatch, pending, currentUser]);

  // INITIALIZE ACTIVE DECK from url
  // When the app loads with a deckID in the URL, use that ID to set the active deck
  // useEffect(() => {
  //   if (!currentUser) return;
  //   const urlDeckRef = doc(db, "users", currentUser.uid, "decks", urlDeckID);
  //   if (urlDeckID) {
  //     dispatch({ type: "UPDATE_ACTIVE_DECK_REF" });
  //   }
  // }, [urlDeckID]);

  // USER DATA
  // Listen for updates to userData and initialize active deck
  // If no user data, set up new user and new deck
  useEffect(() => {
    // Initialize the user account the first time a user signs in
    const setupNewUser = async (user) => {
      const userRef = doc(db, "users", user.uid);
      const deckRef = doc(collection(userRef, "decks"));
      const newDeck = deckConstructor({
        deckID: deckRef.id,
      });
      const newUser = {
        userID: user.uid,
        lastActiveDeck: deckRef,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        deleted_at: null,
      };
      const batch = writeBatch(db);
      batch.set(userRef, newUser);
      batch.set(deckRef, newDeck);
      batch.commit().catch((error) => {
        console.error("Error setting up new user: ", error);
      });
    };

    // Initialize the user's first deck when they first sign in or when their last deck gets deleted
    const setupInitialDeck = async (userRef) => {
      const deckRef = doc(collection(userRef, "decks"));
      const newDeck = deckConstructor();
      return await setDoc(deckRef, newDeck)
        .then(() => deckRef)
        .catch((error) => {
          console.log("Error creating new deck for user:", userRef.id);
          return error;
        });
    };

    const initializeActiveDeckRef = async (userRef, deckRef) => {
      let activeDeckRef = deckRef;

      if (deckRef) {
        // Check if deck exists
        activeDeckRef = await getDoc(deckRef).then((doc) => {
          if (doc.exists() && !doc.get("deleted_at")) {
            return deckRef;
          } else {
            return null;
          }
        });
      }

      // If not, check if there are other decks to set it to and set it to the first one
      if (!activeDeckRef) {
        // The original activeDeck no longer exists, so check fo any other decks
        const activeDeckQuery = query(
          collection(userRef, "decks"),
          where("deleted_at", "==", null),
          limit(1)
        );
        activeDeckRef = await getDocs(activeDeckQuery).then((querySnapshot) => {
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].ref;
          } else {
            return null;
          }
        });
      }

      // If no decks, create one and set it
      if (!activeDeckRef) {
        await setupInitialDeck(userRef)
          .then((newDeckRef) => (activeDeckRef = newDeckRef))
          .catch((error) => {
            console.log(
              "Unable to initialize activeDeckRef. Creating new deck failed.",
              error
            );
          });
      }

      if (activeDeckRef) {
        dispatch({
          type: "UPDATE_ACTIVE_DECK_REF",
          payload: { activeDeckRef, isInit: true },
        });
        updateDoc(userRef, { lastActiveDeck: activeDeckRef }).catch((error) => {
          console.error(
            "Error writing activeDeckRef to user document in database: ",
            error
          );
        });
        // navigate(`/deck/${activeDeckRef.id}`, { replace: true });
      }
    };

    // If there is no logged-in user, do nothing
    if (!currentUser) return;

    // Set up the unSubscribe function for the snapshot listener
    // This gets returned as the clean-up function to useEffect
    // It also gets saved to a global array to clean up all listeners before
    // logging the user out
    let unSubscribe = () => {};

    try {
      const userRef = doc(db, "users", currentUser.uid);
      unSubscribe = onSnapshot(
        userRef,
        (doc) => {
          if (doc.exists() && !doc.get("deleted_at")) {
            const userData = doc.data();
            userData.ref = doc.ref;
            userData.userID = doc.ref.id;
            if (!state.decks.activeDeckRef) {
              let initialDeckRef;
              if (urlParams.deckID) {
                initialDeckRef = doc(
                  db,
                  "users",
                  currentUser.uid,
                  "decks",
                  urlParams.deckID
                );
              } else {
                initialDeckRef = userData.lastActiveDeck;
              }
              initializeActiveDeckRef(userRef, initialDeckRef);
            }
            dispatch({ type: "UPDATE_USER_DATA", payload: { userData } });
          } else {
            setupNewUser(currentUser);
          }
        },
        (error) => {
          Sentry.captureException(error);
          process.env.REACT_APP_ENV === "dev" &&
            console.log(`onSnapshot error: ${error}`);
        }
      );
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error subscribing to updates for user data: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [dispatch, currentUser, state.decks.activeDeckRef, urlParams.deckID]);

  // DECK LIST
  // Listen for updates to deck list (based on currentUser)
  useEffect(() => {
    if (!currentUser) return;
    let unSubscribe = () => {};
    try {
      const decksQuery = query(
        collection(db, "users", currentUser.uid, "decks"),
        where("deleted_at", "==", null)
      );
      unSubscribe = onSnapshot(
        decksQuery,
        (querySnapshot) => {
          const deckList = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists()) {
              const data = doc.data();
              const deck = { ref: doc.ref, ...data };
              deckList.push(deck);
            }
          });
          dispatch({ type: "UPDATE_DECK_LIST", payload: { deckList } });
        },
        (error) => {
          Sentry.captureException(error);
          process.env.REACT_APP_ENV === "dev" &&
            console.log(`onSnapshot error: ${error}`);
        }
      );
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error subscribing to updates for deck list: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [dispatch, currentUser]);

  // SHARED DECK LIST
  // Listen for updates to shared deck list (based on currentUser)
  useEffect(() => {
    if (!currentUser) return;
    let unSubscribe = () => {};
    try {
      const userRef = doc(db, "users", currentUser.uid);
      const decksQuery = query(
        collectionGroup(db, "decks"),
        where("sharedWith", "array-contains", userRef.id),
        where("deleted_at", "==", null)
      );
      unSubscribe = onSnapshot(decksQuery, (querySnapshot) => {
        const deckList = [];
        querySnapshot.forEach(
          (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              const deck = { ref: doc.ref, ...data };
              deckList.push(deck);
            }
          },
          (error) => {
            process.env.REACT_APP_ENV === "dev" &&
              console.log(`onSnapshot error: ${error}`);
          }
        );
        dispatch({
          type: "UPDATE_SHARED_DECK_LIST",
          payload: { sharedDeckList: deckList },
        });
      });
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error subscribing to updates for shared deck list: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [dispatch, currentUser]);

  // ALBUM LIST
  // Listen for updates to album list (based on currentUser)
  useEffect(() => {
    if (!currentUser) return;

    const updateData = () => {
      let unSubscribe = () => {};
      try {
        const albumsQuery = query(
          collection(db, "albums"),
          where("status", "==", "display"),
          where("deleted_at", "==", null)
        );
        // const albumsQuerySnap = await getDocs(albumsQuery);
        unSubscribe = onSnapshot(
          albumsQuery,
          (querySnapshot) => {
            const albumList = [];
            querySnapshot.forEach((doc) => {
              if (doc.exists()) {
                const data = doc.data();
                const album = { ref: doc.ref, ...data };
                albumList.push(album);
              }
            });
            dispatch({ type: "UPDATE_ALBUM_LIST", payload: { albumList } });
          },
          (error) => {
            process.env.REACT_APP_ENV === "dev" &&
              console.log(`onSnapshot error: ${error}`);
          }
        );
        dispatch({
          type: "LISTENER_UNSUBSCRIBE",
          payload: { func: unSubscribe },
        });
      } catch (error) {
        console.log("Error subscribing to updates for album list: ", error);
      } finally {
        return () => unSubscribe();
      }
    };

    return updateData();
  }, [dispatch, currentUser]);

  // ACTIVE DECK
  // Listen for updates to activeDeck (based on state.activeDeckRef)
  useEffect(() => {
    if (!currentUser || !state.decks.activeDeckRef) return;
    let unSubscribe = () => {};
    try {
      const activeDeckRef = state.decks.activeDeckRef;
      unSubscribe = onSnapshot(
        activeDeckRef,
        async (doc) => {
          if (doc.exists() && !doc.get("deleted_at")) {
            const activeDeck = doc.data();
            activeDeck.ref = doc.ref;

            const cardsQuery = query(
              collection(activeDeck.ref, "cards"),
              where("deleted_at", "==", null),
              where("display", "==", true)
            );

            activeDeck.isEmpty = await getDocs(cardsQuery).then(
              (querySnapshot) => {
                return querySnapshot.empty;
              }
            );

            dispatch({ type: "UPDATE_ACTIVE_DECK", payload: { activeDeck } });
          } else {
            dispatch({ type: "CLEAR_ACTIVE_DECK_REF" });
          }
        },
        (error) => {
          Sentry.captureException(error);
          process.env.REACT_APP_ENV === "dev" &&
            console.log(`onSnapshot error: ${error}`);
        }
      );
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error subscribing to updates for active deck: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [dispatch, state.decks.activeDeckRef, currentUser]);

  // LAST ACTIVE DECK
  // Set lastActiveDeck for the user
  useEffect(() => {
    if (!currentUser || !state.decks.activeDeckRef) return;
    const userRef = doc(db, "users", currentUser.uid);
    updateDoc(userRef, { lastActiveDeck: state.decks.activeDeckRef }).catch(
      (error) => {
        console.log(
          "Error setting lastActiveDeck for user: ",
          userRef.id,
          error
        );
      }
    );
  }, [dispatch, state.decks.activeDeckRef, currentUser]);

  // ACTIVE CARD LIST
  // Listen for changes to activeCardList
  useEffect(() => {
    if (!currentUser || !state.decks.activeDeckRef) return;

    const updateData = () => {
      let unSubscribe = () => {};
      try {
        const activeDeckRef = state.decks.activeDeckRef;
        const cardsQuery = query(
          collection(activeDeckRef, "cards"),
          where("deleted_at", "==", null),
          where("display", "==", true),
          orderBy("orientation", "desc"),
          orderBy("created_at", "asc")
        );
        // const cardsQuerySnap = getDocs(cardsQuery);
        unSubscribe = onSnapshot(
          cardsQuery,
          (querySnapshot) => {
            const activeCardList = [];
            const sourceSet = [];
            querySnapshot.forEach((doc) => {
              if (doc.exists()) {
                const card = doc.data();
                card.ref = doc.ref;
                activeCardList.push(card);
                if (card.image_source_id) {
                  sourceSet.push(card.image_source_id);
                }
              }
            });
            dispatch({
              type: "UPDATE_ACTIVE_CARD_LIST",
              payload: { activeCardList },
            });
            dispatch({
              type: "UPDATE_ALBUM_SOURCE_SET",
              payload: { sourceSet },
            });
            dispatch({ type: "APP_LOADED", payload: {} });
          },
          (error) => {
            process.env.REACT_APP_ENV === "dev" &&
              console.log(`onSnapshot error: ${error}`);
          }
        );
        dispatch({
          type: "LISTENER_UNSUBSCRIBE",
          payload: { func: unSubscribe },
        });
      } catch (error) {
        console.log("Error querying data for activeCardList: ", error);
      } finally {
        return () => unSubscribe();
      }
    };

    return updateData();
  }, [dispatch, state.decks.activeDeckRef, currentUser]);

  // ACTIVE ALBUM
  // Fetch data for activeAlbum (based on state.activeAlbumRef)
  useEffect(() => {
    const setupDefaultAlbum = async () => {
      const albumRef = doc(collection(db, "albums"));
      const newAlbum = albumConstructor();
      return await setDoc(albumRef, newAlbum)
        .then(() => albumRef)
        .catch((error) => {
          console.log("Error creating default album:");
          return error;
        });
    };

    const initializeActiveAlbumRef = async () => {
      let activeAlbumRef = "";

      // TODO: Remove this or change to another way of specifying the default album
      // if (state.library.initialAlbumID) {
      //   activeAlbumRef = doc(db, "albums", state.library.initialAlbumID);
      //   // Check if album exists
      //   activeAlbumRef = await getDoc(activeAlbumRef).then((doc) => {
      //     if (doc.exists() && !doc.get("deleted_at")) {
      //       return activeAlbumRef;
      //     } else {
      //       return null;
      //     }
      //   });
      // }

      // If the album does not exist, check if there are other albums to set it to and set it to the first one
      if (!activeAlbumRef) {
        // The original activeAlbum no longer exists, so check for any other albums
        const activeAlbumQuery = query(
          collection(db, "albums"),
          where("status", "==", "display"),
          where("deleted_at", "==", null),
          limit(1)
        );
        activeAlbumRef = await getDocs(activeAlbumQuery).then(
          (querySnapshot) => {
            if (!querySnapshot.empty) {
              return querySnapshot.docs[0].ref;
            } else {
              return null;
            }
          }
        );
      }

      // If still no albums, create one and set it
      if (!activeAlbumRef) {
        activeAlbumRef = await setupDefaultAlbum()
          .then((newAlbumRef) => newAlbumRef)
          .catch((error) => {
            console.log(
              "Unable to initialize activeAlbumRef. Creating new album failed.",
              error
            );
          });
      }

      if (activeAlbumRef) {
        dispatch({
          type: "UPDATE_ACTIVE_ALBUM_REF",
          payload: { activeAlbumRef },
        });
      }
    };

    if (!currentUser) return;
    if (!state.library.activeAlbumRef) {
      try {
        currentUser && initializeActiveAlbumRef();
      } catch (error) {
        console.log("Error initializing active album: ", error);
      }
      return;
    }

    let unSubscribe = () => {};
    try {
      const activeAlbumRef = state.library.activeAlbumRef;
      unSubscribe = onSnapshot(
        activeAlbumRef,
        (doc) => {
          if (doc.exists()) {
            const activeAlbum = doc.data();
            if (!activeAlbum.deleted_at) {
              activeAlbum.ref = doc.ref;
              dispatch({
                type: "UPDATE_ACTIVE_ALBUM",
                payload: { activeAlbum },
              });
            }
          }
        },
        (error) => {
          Sentry.captureException(error);
          process.env.REACT_APP_ENV === "dev" &&
            console.log(`onSnapshot error: ${error}`);
        }
      );
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error subscribing to active album updates: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [
    dispatch,
    state.library.activeAlbumRef,
    // state.library.initialAlbumID,
    currentUser,
  ]);

  // ACTIVE ALBUM CARD LIST
  // Listen for changes to activeAlbumCardList
  useEffect(() => {
    if (!currentUser || !state.library.activeAlbumRef) return;

    const updateData = () => {
      const activeAlbumRef = state.library.activeAlbumRef;
      let unSubscribe = () => {};
      try {
        const cardsQuery = query(
          collection(activeAlbumRef, "cards"),
          where("deleted_at", "==", null),
          where("display", "==", true),
          orderBy("orientation", "desc"),
          orderBy("created_at", "asc")
        );
        // const cardsQuerySnap = getDocs(cardsQuery);
        unSubscribe = onSnapshot(
          cardsQuery,
          (querySnapshot) => {
            const cardList = [];
            querySnapshot.forEach((doc) => {
              if (doc.exists()) {
                const card = doc.data();
                card.ref = doc.ref;
                cardList.push(card);
              }
            });
            dispatch({
              type: "UPDATE_ACTIVE_ALBUM_CARD_LIST",
              payload: { activeAlbumCardList: cardList },
            });
          },
          (error) => {
            process.env.REACT_APP_ENV === "dev" &&
              console.log(`onSnapshot error: ${error}`);
          }
        );
        dispatch({
          type: "LISTENER_UNSUBSCRIBE",
          payload: { func: unSubscribe },
        });
      } catch (error) {
        console.log("Error querying data for activeAlbumCardList: ", error);
      } finally {
        return () => unSubscribe();
      }
    };

    return updateData();
  }, [currentUser, dispatch, state.library.activeAlbumRef]);

  // TODO: preload images for deck and library?

  return (
    <AppContext.Provider value={{ state, dispatch, stripePromise }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;
