import React, { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import firebaseApp from "../utils/firebase";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AppContext from "./app-context";
import Loading from "./loading";

export default function Logout() {
  const navigate = useNavigate();

  const { state } = useContext(AppContext);

  const logout = async () => {
    const auth = getAuth(firebaseApp);

    try {
      const unSubscribes = state.app.unSubscribes;
      if (unSubscribes && unSubscribes.length > 0) {
        unSubscribes.forEach((func) => func());
      }
    } catch (err) {
      Sentry.captureEvent(err, "info");
    }

    await auth.signOut();
    navigate("/signup");
  };

  useEffect(() => {
    logout();
  });

  return <Loading />;
}
