/** @jsxImportSource @emotion/react */
import React, { useState, useContext, useRef, useEffect } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import { css, keyframes } from "@emotion/react";

import CardBack from "./card-back";
import AppContext from "./app-context";
// import { AuthContext } from "./auth-provider";

const CARD_FRAME_MARGIN_PX = 0;

const fadeInKeyframes = keyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
});

const fadeInCSS = css({
  animation: `${fadeInKeyframes} 1s forwards linear`,
});

const cardFlipKeyframes = keyframes({
  "0%": {
    transform: "rotateY(0deg)",
  },
  "20%": {
    transform: "rotateY(90deg)",
  },
  "35%": {
    transform: "rotateY(180deg)",
  },
  "65%": {
    transform: "rotateY(180deg)",
  },
  "80%": {
    transform: "rotateY(270deg)",
  },
  "100%": {
    transform: "rotateY(360deg)",
  },
});

const cardFlipCSS = css({
  animation: `${cardFlipKeyframes} 1.5s forwards linear`,
});

const styles = {
  cardContainer: {
    zIndex: 10, // position above Dropzone
    perspective: "1000px",
    margin: "5px",
    position: "relative",
    transition: "width 0.25s, height 0.25s",
  },
  cardFrame: {
    padding: "0 !important",
    background: "transparent",
    transformStyle: "preserve-3d",
    transition: "width 0.25s, height 0.25s, transform 0.5s",
  },
  imageDiv: {
    position: "absolute",
    top: `-${CARD_FRAME_MARGIN_PX}px`,
    left: `-${CARD_FRAME_MARGIN_PX}px`,
    transition: "width 0.25s, height 0.25s, background-image 1s",
  },
  imageFrame: {
    // boxShadow: "0 0.5rem 1rem #808080",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    // boxShadow:
    //   "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    // boxShadow:
    //   "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // boxShadow:
    //   "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    overflow: "hidden",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "width 0.25s, height 0.25s",
    backfaceVisibility: "hidden",
  },
  selectedLabel: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedTextBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    padding: "5px",
    borderRadius: "5px",
  },
  selectedText: {
    color: "gray",
  },
  translucent: {
    opacity: 0.5,
  },
  cardBack: {
    backfaceVisibility: "hidden",
    transform: "rotateY(-180deg)",
    transition: "width 0.25s, height 0.25s",
  },
};

function cropOutside({ cropData, imageData }) {
  let cropIsOutside = false;
  if (cropData.x < 0) {
    cropIsOutside = true;
  }

  if (cropData.y < 0) {
    cropIsOutside = true;
  }

  if (cropData.x + cropData.width > imageData.width) {
    cropIsOutside = true;
  }

  if (cropData.y + cropData.height > imageData.height) {
    cropIsOutside = true;
  }
  return cropIsOutside;
}

// Base component to display cards in the gallery
// card: the card data object
// flip: flip state (bool)
// backImage: the image to display on the back of the card (name)
// selected: used to identify cards in the Library that are already added to the active deck
// cardDimensions: Dimensions in pixels for the card {longPX, shortPX, borderRadiusPX}
// view: the view context for this gallery deck|library|welcome
// children: used to display the overlay buttons for interaction
function Card({
  card,
  flip,
  backImage,
  selected,
  cardDimensions,
  view,
  children,
}) {
  const [isHover, setIsHover] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  // const { currentUser } = useContext(AuthContext);

  const cardDomRef = useRef(null);

  // const [fadeIn, setFadeIn] = useState({});
  const [backgroundTreatment, setBackgroundTreatment] = useState();
  const [selectedIconSize, setSelectedIconSize] = useState(0);
  const [selectedTextSize, setSelectedTextSize] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCroppedOutsideImage, setIsCroppedOutsideImage] = useState(false);

  // Keep the src as state so that it only changes from an operation that creates a true update
  // This is to prevent re-render when the src changes from the blob URL to the URL in cloud storage
  const [src, setSrc] = useState("");

  const [orientation, setOrientation] = useState(null);

  const cardPreview = state.app.cardPreviews[card.cardID];
  const deckName = state.decks.activeDeck ? state.decks.activeDeck.name : "";

  useEffect(() => {
    const newSize = cardDimensions.longPX
      ? Math.round(cardDimensions.longPX * 0.2)
      : 0;
    setSelectedIconSize(newSize);
    setSelectedTextSize(newSize / 5);
  }, [cardDimensions]);

  useEffect(() => {
    if (cardPreview) {
      setSrc(cardPreview.src);
      setOrientation(cardPreview.orientation);
    }
  }, [cardPreview]);

  // Set the src image for the card and the orientation
  // If the src has changed or there was another crop, fade in the card using css
  useEffect(() => {
    if (card && !card.newcard) {
      let newSrc = "";
      // baseline is that orientation remains the same
      let newOrientation = card.orientation;
      // avoid error if card.cropData is null or undefined

      // Set the card src
      // If there is a current value for croppedImage (the cropped version of the original) then display that
      if (card.croppedImage && card.croppedImage.src) {
        newSrc = card.croppedImage.src;
        setBackgroundTreatment("cover");
        setIsLoading(false);
      } else if (cardPreview) {
        // If there is an active cardPreview, then that has next priority
        newSrc = cardPreview.src;
        newOrientation = cardPreview.orientation;
        setBackgroundTreatment("cover");
        setIsLoading(false);
      } else if (card.displayImage && card.displayImage.src) {
        // Otherwise we default to the original image and apply any crop data that exists
        // We only apply crop data if there is no preview or display image because those are already cropped
        newSrc = card.displayImage.src;
        setBackgroundTreatment("cover");
        setIsLoading(false);
        if (card.displayCropData) {
          setBackgroundTreatment("crop");
        }
      }

      if (newSrc === "" && !isLoading) {
        setIsLoading(true);
      }

      setSrc(newSrc);
      setOrientation(newOrientation);
    }
  }, [
    card,
    isLoading,
    dispatch,
    cardPreview,
    backgroundTreatment,
    // fadeIn.css,
    // fadeIn.timestamp,
    src,
    orientation,
  ]);

  // Check to see if the crop is outside the boundaries of the image
  // Controlled by a boolean value in the user profile that
  // is false by default
  useEffect(() => {
    // Bail if the user is not an admin
    if (!state.userData.showCropWarning) {
      setIsCroppedOutsideImage(false);
      return;
    }

    // Bail if there is no crop data or no original image
    if (!card.originalCropData || !card.originalImage) {
      setIsCroppedOutsideImage(false);
      return;
    }

    setIsCroppedOutsideImage(
      cropOutside({
        cropData: card.originalCropData,
        imageData: card.originalImage,
      })
    );
  }, [card, state.userData.showCropWarning]);

  // useEffect(() => {
  //   if (card.newcard) return;
  //   // Fade the card in and scroll it into view if it has changed recently
  //   if (
  //     card.updated_at !== null &&
  //     Date.now() / 1000 - card.updated_at.seconds < 30
  //   ) {
  //     if (!fadeIn.css) {
  //       // Only add the class and scroll into view if it hasn't already been done
  //       setFadeIn({
  //         css: fadeInCSS,
  //         timestamp: Date.now(),
  //       });
  //       cardDomRef.current &&
  //         cardDomRef.current.scrollIntoView({
  //           behavior: "smooth",
  //           block: "center",
  //           inline: "center",
  //         });
  //     }
  //   } else if (!!fadeIn.css && Date.now() - fadeIn.timestamp > 30000) {
  //     // remove the fadeIn class once it has done its job
  //     setFadeIn({});
  //   }
  // }, [fadeIn.css, fadeIn.timestamp, card.newcard, card.updated_at, cardDomRef]);

  const cardWidthPX =
    orientation === "horizontal"
      ? cardDimensions.longPX
      : cardDimensions.shortPX;
  const cardHeightPX =
    orientation === "horizontal"
      ? cardDimensions.shortPX
      : cardDimensions.longPX;

  const cardSize = {
    width: `${cardWidthPX}px`,
    height: `${cardHeightPX}px`,
    borderRadius: `${cardDimensions.borderRadiusPX}px`,
  };

  // Trim 1 pixel around the outside to avoid the white edges that can come from images not
  // displaying properly as backgrounds
  const cardFrameStyle = {
    width: `${cardWidthPX - 2 * CARD_FRAME_MARGIN_PX}px`,
    height: `${cardHeightPX - 2 * CARD_FRAME_MARGIN_PX}px`,
    borderRadius: `${cardDimensions.borderRadiusPX}px`,
  };

  // Style the card with the image as the background
  // Background image works better in animation (less artifacts) than img element inside div
  // auto-crop with background-sixe and background-position css properties
  const cardImageStyle = {
    backgroundImage: src ? `url(${src})` : "",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "white",
    backgroundSize: "cover",
  };

  if (selected) {
    cardImageStyle.opacity = 0.4;
  }

  if (isLoading) {
    cardImageStyle.opacity = 0.5;
  }

  if (!card.newcard && backgroundTreatment === "crop") {
    const referenceDimension =
      card.orientation === "vertical"
        ? card.displayCropData.height
        : card.displayCropData.width;
    const scaleRatio = cardDimensions.longPX / referenceDimension;
    const backgroundImageHeight = card.displayImage.height * scaleRatio;
    const backgroundImageWidth = card.displayImage.width * scaleRatio;
    const backgroundPositionX = -card.displayCropData.x * scaleRatio;
    const backgroundPositionY = -card.displayCropData.y * scaleRatio;
    cardImageStyle.backgroundSize = `${backgroundImageWidth}px ${backgroundImageHeight}px`;
    cardImageStyle.backgroundPosition = `${backgroundPositionX}px ${backgroundPositionY}px`;
  }

  const cropHighlight = isCroppedOutsideImage
    ? {
        boxShadow: "0 0 5px 5px yellow",
      }
    : {};

  // On touch devices, there is no hover state
  const handleMouseOver = state.app.isMobile
    ? undefined
    : () => setIsHover(true);

  // On touch devices, clicking activates the hover state
  // TODO: add behavior for non-touch devices on click
  const handleClick = state.app.isMobile
    ? () => setIsHover(!isHover)
    : undefined;

  const selectedIconStyle = {
    fontSize: selectedIconSize,
  };

  const selectedTextStyle = {
    fontSize: selectedTextSize,
  };

  const selectedLabel = (
    <div style={styles.selectedLabel}>
      <CheckCircleIcon style={selectedIconStyle} />
      <div style={styles.selectedTextBox}>
        <Typography style={{ ...styles.selectedText, ...selectedTextStyle }}>
          Added to
        </Typography>
        <Typography style={{ ...styles.selectedText, ...selectedTextStyle }}>
          {deckName}
        </Typography>
      </div>
    </div>
  );

  return (
    <div style={{ ...styles.cardContainer, ...cardSize, ...cropHighlight }}>
      <div
        style={{ ...styles.cardFrame, ...cardSize }}
        css={flip ? cardFlipCSS : null}
      >
        <div style={{ ...styles.imageFrame, ...cardFrameStyle }}>
          <div
            ref={cardDomRef}
            // css={view === "deck" ? fadeIn.css : null}
            css={fadeInCSS}
            style={{ ...styles.imageDiv, ...cardImageStyle, ...cardSize }}
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseLeave={() => setIsHover(false)}
          >
            {/* use cloneElement to add a handleClick method to every overlay button with a fuction from this component */}
            {(card.newcard || isHover) &&
              React.Children.map(children, (child) =>
                React.cloneElement(child, {
                  handleClick: () => setIsHover(false),
                })
              )}
          </div>
          {selected && selectedLabel}
        </div>
        {backImage && (
          <CardBack
            backImage={backImage}
            card={card}
            styles={styles}
            cardSize={cardSize}
          />
        )}
      </div>
    </div>
  );
}

export default Card;
