import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./auth-provider";
import { Navigate } from "react-router-dom";
import Loading from "./loading";

// Protect Admin -only routes by checking admin status before rendering children

const RequireAdmin = ({ children, redirectTo }) => {
  const { currentUser, isAdmin, pending } = useContext(AuthContext);

  const location = useLocation();

  const [returnVal, setReturnVal] = useState(<Loading />);

  useEffect(() => {
    // If the auth state is pending, return the default (<Loading/>)
    if (pending) return;

    // If user is not logged in, redirect as normal including "from" information so that they
    // can be sent back here after sign in
    if (!currentUser) {
      const state = {
        from: {
          pathname: location.pathname,
          search: location.search,
        },
      };
      setReturnVal(<Navigate to={redirectTo} state={state} />);
    }

    // If user is logged in but not an admin, redirecting to sign in will create a redirect loop
    // Non-admins shouldn't be trying to come here, so log them out
    if (currentUser && !isAdmin) {
      process.env.REACT_APP_ENV !== "prod" && console.log(currentUser);
      process.env.REACT_APP_ENV !== "prod" &&
        console.log("Logged in but not admin.");
      process.env.REACT_APP_ENV !== "prod" && console.log({ currentUser });
      Sentry.captureMessage("Non-admin user attempted to access admin route.", {
        tags: { type: "security" },
      });
      setReturnVal(<Navigate to={"/logout"} />);
    }

    // If user is logged in and is an admin, render the children
    if (isAdmin) {
      setReturnVal(children);
    }
  }, [
    currentUser,
    isAdmin,
    pending,
    children,
    location.pathname,
    location.search,
    redirectTo,
    setReturnVal,
  ]);

  return returnVal;
};

export default RequireAdmin;
