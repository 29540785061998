import React from "react";
import { Routes, Route } from "react-router-dom";
// import PrivateRoute from "./components/private-route";
import RequireAuth from "./components/require-auth";
import RequireAdmin from "./components/require-admin";

import CreateWelcome from "./components/create-welcome";
import CreateDeck from "./components/create-deck";
import CreateLibrary from "./components/create-library";
import Loading from "./components/loading";
import SignIn from "./components/sign-in";
import Logout from "./components/logout";
import NotFound from "./components/not-found";
import SignUp from "./components/sign-up";
import ForgotPassword from "./components/forgot-password";
import LinkAccounts from "./components/link-accounts";
import LinkPhotos from "./components/link-photos";
import Gift from "./components/gift";
import DigitalDeck from "./components/digital-deck";
import Cart from "./components/cart";
import OrderDetail from "./components/order-detail";
import AdminTest from "./components/admin-test";
import AdminOrders from "./components/admin-orders";

function App() {
  return (
    <Routes>
      {/* Server-side redirect set up for the base route "/" using Firebase hosting config*/}

      {/* Public Routes */}

      {/* Render 404 for any unspecified routes */}
      <Route path="*" element={<NotFound />} />

      <Route path="/welcome" element={<CreateWelcome />} />

      <Route path="/signin" element={<SignIn />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/loading" element={<Loading />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/gift" element={<Gift />} />
      <Route path="/digitaldeck/:id" element={<DigitalDeck />} />

      {/* Protected Routes */}
      <Route
        path="/"
        element={
          <RequireAuth redirectTo="/welcome">
            <CreateDeck />
          </RequireAuth>
        }
      />
      <Route
        path="/deck"
        element={
          <RequireAuth redirectTo="/signin">
            <CreateDeck />
          </RequireAuth>
        }
      />
      <Route
        path="/deck/:deckID"
        element={
          <RequireAuth redirectTo="/signin">
            <CreateDeck />
          </RequireAuth>
        }
      />
      <Route
        path="/library"
        element={
          <RequireAuth redirectTo="/signin">
            <CreateLibrary />
          </RequireAuth>
        }
      />
      <Route
        path="/library/:albumID"
        element={
          <RequireAuth redirectTo="/signin">
            <CreateLibrary />
          </RequireAuth>
        }
      />

      {/* TODO: Add direct editing of Library albums without active deck */}
      {/* <Route
        path="/library"
        element={
          <RequireAuth redirectTo="/signin">
            <CreateLibrary />
          </RequireAuth>
        }
      />
      <Route
        path="/library/:albumID"
        element={
          <RequireAuth redirectTo="/signin">
            <CreateLibrary />
          </RequireAuth>
        }
      /> */}

      <Route
        path="/linkaccounts"
        element={
          <RequireAuth redirectTo="/signin">
            <LinkAccounts />
          </RequireAuth>
        }
      />

      <Route
        path="/linkphotos"
        element={
          <RequireAuth redirectTo="/signin">
            <LinkPhotos />
          </RequireAuth>
        }
      />
      <Route
        path="/cart"
        element={
          <RequireAuth redirectTo="/signin">
            <Cart />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/:code"
        element={
          <RequireAuth redirectTo="/signin">
            <OrderDetail />
          </RequireAuth>
        }
      />
      {/* Thie route handles order success redirects from Stripe */}
      <Route
        path="/orders"
        element={
          <RequireAuth redirectTo="/signin">
            <OrderDetail />
          </RequireAuth>
        }
      />

      {/* ADMIN ROUTES */}

      <Route
        path="/admin/orders/"
        element={
          <RequireAdmin redirectTo="/signin">
            <AdminOrders />
          </RequireAdmin>
        }
      />

      {/* Dev Testing Route */}
      <Route
        path="/debug"
        element={
          <RequireAdmin redirectTo="/signin">
            <AdminTest />
          </RequireAdmin>
        }
      />
    </Routes>
  );
}

export default App;
