import React from "react"; //

import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
};

function Loading() {
  return (
    <div style={styles.root}>
      <CircularProgress />
    </div>
  );
}

export default Loading;
