import React, { useContext, useState } from "react"; //

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import AppContext from "./app-context";
import { apiCall } from "../utils/firebase";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../styles/theme";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  addButton: {
    height: "30px",
    width: "50px",
  },
  removeButton: {
    // height: "30px",
    // width: "50px",
  },
  addEmail: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "20px",
    marginLeft: "20px",
  },
  listEmail: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
  },
  listBox: {
    maxHeight: "260px",
    overflowY: "scroll",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  checkoutSpinner: {
    color: "#FFFFFF",
  },
};

export default function ShareDeckDialog({ handleClose }) {
  const { state } = useContext(AppContext);

  const deck = state.decks.activeDeck;
  const sharedWithEmails =
    state.decks.activeDeck && state.decks.activeDeck.sharedWithEmails;

  const [processing, setProcessing] = useState(false);

  const handleAdd = async (evt) => {
    evt.preventDefault();
    setProcessing(true);
    const form = evt.target;
    const { email } = evt.target.elements;
    try {
      const addShareEmailApi = apiCall("addShareEmail");
      const args = {
        email: email.value,
        deckID: deck.deckID,
      };

      console.log({ args });

      await addShareEmailApi(args);
    } catch (error) {
      alert(error);
    } finally {
      form.reset();
      setProcessing(false);
    }
  };

  const handleRemove = (email) => async () => {
    try {
      const removeShareEmailApi = apiCall("removeShareEmail");
      const args = {
        email,
        deckID: deck.deckID,
      };

      await removeShareEmailApi(args);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add emails to share</DialogTitle>
      {handleClose && (
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}

      <form onSubmit={handleAdd}>
        <div style={styles.addEmail}>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="text"
            fullWidth
          />
          <Button
            style={styles.addButton}
            type="submit"
            color="primary"
            variant="contained"
            size="small"
          >
            {processing ? (
              <CircularProgress size={20} style={styles.checkoutSpinner} />
            ) : (
              "ADD"
            )}
          </Button>
        </div>
      </form>

      <DialogContent>
        <div style={styles.listBox}>
          {sharedWithEmails &&
            sharedWithEmails
              .slice(0)
              .reverse()
              .map((email) => (
                <div key={email} style={styles.listEmail}>
                  {email}

                  <IconButton
                    style={styles.removeButton}
                    onClick={handleRemove(email)}
                    color="secondary"
                    variant="contained"
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              ))}
        </div>
      </DialogContent>
      <DialogActions></DialogActions>

      <hr />

      <Button onClick={handleClose} variant="contained" color="primary">
        DONE
      </Button>
    </Dialog>
  );
}
