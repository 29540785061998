import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AppContext from "./app-context";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { AuthContext } from "./auth-provider";
import PersonIcon from "@mui/icons-material/Person";
import theme from "../styles/theme";

// Size the user profile photo
const styles = {
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: "5px",
    marginLeft: "5px",
    cursor: "pointer",
    color: "gray",
  },
  userNav: {
    display: "flex",
    flexFlow: "row nowrap",
  },
};

function UserNav() {
  const { currentUser, isAdmin } = useContext(AuthContext);

  const { dispatch, state } = useContext(AppContext);

  const navigate = useNavigate();

  const picture = currentUser && currentUser.photoURL;

  const logout = () => {
    setAnchorEl(null);
    // const auth = getAuth(firebaseApp);
    // auth.signOut();
    navigate("/logout");
  };

  const privacyPolicy = () => {
    setAnchorEl(null);
    window.open("https://moments.cards/privacy-policy");
  };

  const help = () => {
    setAnchorEl(null);
    dispatch({ type: "MODAL", payload: { type: "help" } });
    if (!state.app.isMobile) {
      dispatch({
        type: "CONTROL",
        payload: { name: "drawerIsOpen", state: true },
      });
      dispatch({
        type: "CONTROL",
        payload: { name: "libraryOpen", state: true },
      });
      dispatch({
        type: "CONTROL",
        payload: { name: "decksOpen", state: true },
      });
    }
  };

  const profile = () => {
    setAnchorEl(null);
    dispatch({ type: "MODAL", payload: { type: "profile" } });
  };

  // Set and store the anchor element to attach the user nav menu to
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [adminLinks, setAdminLinks] = useState([]);

  // Set up additional links for admin users
  useEffect(() => {
    if (!isAdmin) return;

    // if not navigating to a new route, need to clear menu with setAnchorEl(null)

    const links = [];
    links.push(
      <MenuItem key="orders" onClick={() => navigate("/admin/orders")}>
        Orders
      </MenuItem>
    );
    links.push(
      <MenuItem key="debug" onClick={() => navigate("/debug")}>
        Debug
      </MenuItem>
    );
    setAdminLinks(links);
  }, [isAdmin, navigate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={styles.userNav}>
      {/* <Avatar alt="User" src={picture} style={classes.avatar} onClick={handleClick} /> */}
      <PersonIcon
        alt="User"
        src={picture}
        style={styles.avatar}
        onClick={handleClick}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem>{currentUser && currentUser.email}</MenuItem>
        <MenuItem onClick={profile}>Profile</MenuItem>
        <MenuItem onClick={help}>Help</MenuItem>
        <MenuItem onClick={privacyPolicy}>Privacy Policy</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
        {adminLinks.map((link) => link)}
      </Menu>
    </div>
  );
}

export default UserNav;
