import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import theme from "../styles/theme";
import AppContext from "./app-context";
import Button from "@mui/material/Button";

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRoot: {
    width: "100vw",
    height: "150vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2vh",
  },
  orderStatusBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    height: "575px",
    maxWidth: "350px",
    // maxHeight: '600px',
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  headingSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  stepsHeading: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "left",
  },
  backDesignImage: {
    height: "40px",
    borderRadius: "5px",
    boxShadow: "0 0.25rem 0.5rem #808080",
  },
  listRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "15px",
  },
  listHeading: {
    width: "65px",
    padding: "5px",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 auto",
    height: "100%",
  },
  listItemAddIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 0.25rem 0.5rem #808080",
    marginBottom: "5px",
  },
  listItemCropIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 0.25rem 0.5rem #808080",
    marginBottom: "5px",
  },
  listItemBackDesign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
  },
  listText: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    marginLeft: "10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.2em",
    color: "dark-gray",
  },
  dividerFull: {
    width: "100%",
    height: "1px",
    color: "#A9A9A9",
    marginTop: "10px",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Gift({ handleClose }) {
  const { state } = useContext(AppContext);

  const query = useQuery();

  const navigate = useNavigate();

  const [message, setMessage] = useState();

  const rootClass = state.app.isMobile ? styles.mobileRoot : styles.root;

  useEffect(() => {
    // let statusMessage = query.get("success") ? 'Success!' : 'Failed!'

    setMessage("Gift Form");
  }, [query]);

  const home = () => [navigate("/deck")];

  return (
    <div style={rootClass}>
      <div style={styles.orderStatusBox}>
        {handleClose && (
          <IconButton
            aria-label="close"
            style={styles.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
        <div style={styles.headingBig}>
          <Typography variant="inherit">{message}</Typography>
        </div>

        <Button variant="contained" color="primary" onClick={home}>
          Checkout
        </Button>
      </div>
    </div>
  );
}
