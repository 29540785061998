import React from "react";

// Card Back is a div with the back image as a background
// Background is used instead of <img> element because it works better for the animation
const CardBack = ({ backImage, card, styles, cardSize }) => {
  const src = backImage.src[card.orientation];
  const style = {
    backgroundImage: `url(${src})`,
    backgroundSize: "cover",
    ...cardSize,
  };

  return <div style={{ ...style, ...styles.cardBack }} />;
};

export default CardBack;
