import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "./app-context";

// Material UI

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
// import PhotoSizeSelectLargeOutlinedIcon from "@mui/icons-material/PhotoSizeSelectLargeOutlined";
import CropOutlinedIcon from "@mui/icons-material/CropOutlined";
import { Tooltip } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";

// MUI Theme
import theme from "../styles/theme";

//Paths to static assets
import assets from "../utils/assets";

const styles = {
  // container: {
  //   background: "#A9A9A9",
  //   padding: "5px 5px 80px 5px",
  //   display: "flex",
  //   flexFlow: "row wrap",
  //   justifyContent: "space-around",
  //   alignItems: "center",
  //   overflowY: "auto",
  //   overflowX: "hidden",
  //   height: "100%",
  //   width: "100%",
  // },
  scrim: {
    zIndex: 999,
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
  },
  box: {
    zIndex: 1000,
    position: "fixed",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    // height: '400px',
    maxWidth: "350px",
    padding: "10px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  welcomeBox: {
    zIndex: 1000,
    position: "fixed",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    // height: '400px',
    maxWidth: "350px",
    padding: "10px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  headingSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  stepsHeading: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "left",
  },
  dialogBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
    margin: "20px 0px 0px 0px",
  },
  dialogSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.2em",
    textAlign: "center",
    color: "#707070",
    margin: "10px 0px 20px 0px",
    padding: "0px",
  },
  backDesignImage: {
    height: "45px",
    borderRadius: "3px",
    // boxShadow: "0 0.25rem 0.5rem #808080",
  },
  listRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "60px",
  },
  listHeading: {
    width: "65px",
    padding: "5px",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 auto",
    height: "100%",
  },
  listItemIcon: {
    width: "40px",
    height: "40px",
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  listItemBackDesign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  listText: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    marginLeft: "10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1em",
    color: "#707070",
  },
  dividerFull: {
    width: "100%",
    height: "1px",
    color: "#A9A9A9",
    marginTop: "10px",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  signInArea: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "5px",
  },
  signInText: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "1.2em",
    color: "#707070",
  },
  signInButton: {
    margin: "10px",
  },
};

function SignInDialog({ handleClose, open }) {
  const navigate = useNavigate();

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xs">
      <DialogTitle style={styles.dialogBig}>Let's get started!</DialogTitle>
      <DialogContentText style={styles.dialogSmall}>
        Please sign in to start creating your cards.
      </DialogContentText>
      <DialogActions>
        {/* <Button size="large" onClick={handleClose}>
          Cancel
        </Button> */}
        <Button
          size="large"
          onClick={() => {
            navigate("/signup");
          }}
          variant="outlined"
        >
          Create Account
        </Button>
        <Button
          size="large"
          onClick={() => {
            navigate("/signin");
          }}
          variant="contained"
        >
          Sign In
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// A Welcome Modal that is re-used in three views
// 1) Unauthenticated user welcome [view="welcome", parentClose=null]
// 2) Authenticated user with empty deck [view="deck", parentClose=parent method to close modal]
// 3) When the user clicks on [Help]
export default function ModalHelp({ view, handleClose }) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);

  const [signInDialogOpen, setSignInDialogOpen] = useState(false);

  handleClose = handleClose || (() => dispatch({ type: "MODAL", payload: {} }));

  const signInDialog = () => {
    setSignInDialogOpen(true);
  };

  const handleClick = view === "welcome" ? signInDialog : handleClose;

  const scrimClick = () => {
    handleClick();
  };

  const handleUpload = () => {
    dispatch({
      type: "CONTROL",
      payload: { name: "triggerUpload", state: true },
    });
    handleClose();
    navigate("/deck");
  };

  const handleLibrary = () => {
    handleClose();
    navigate("/library");
  };

  // When modal opens, open drawer and expand lists if not on mobile
  useEffect(() => {
    if (!state.app.isMobile && view === "help") {
      dispatch({
        type: "CONTROL",
        payload: { name: "drawerIsOpen", state: true },
      });
      dispatch({
        type: "CONTROL",
        payload: { name: "libraryOpen", state: true },
      });
      dispatch({
        type: "CONTROL",
        payload: { name: "decksOpen", state: true },
      });
    }
  }, [dispatch, view, state.app.isMobile]);

  return (
    <>
      <SignInDialog
        handleClose={() => setSignInDialogOpen(false)}
        open={signInDialogOpen}
      />
      <div style={styles.scrim} onClick={scrimClick}></div>
      <div
        id="modalHelp"
        style={view === "welcome" ? styles.welcomeBox : styles.box}
      >
        {handleClick && (
          <IconButton
            aria-label="close"
            style={styles.closeButton}
            onClick={handleClick}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
        {view === "welcome" && (
          <div style={styles.headingBig}>
            <Typography variant="inherit">Welcome!</Typography>
          </div>
        )}
        <div style={styles.headingSmall}>
          <Typography variant="inherit">Let's create your deck.</Typography>
        </div>
        <hr style={styles.dividerFull} />
        <div>
          {/* <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <IconButton
                aria-label="upload"
                onClick={handleUpload}
                size="medium"
              >
                <div style={styles.listItemIcon}>
                  <PhotoSizeSelectLargeOutlinedIcon fontSize="large" />
                </div>
              </IconButton>
            </div>
            <div style={styles.listText}>
              <Typography variant="inherit">Choose your card size</Typography>
            </div>
          </div> */}
          <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <IconButton
                aria-label="upload"
                onClick={handleUpload}
                size="medium"
              >
                <div style={styles.listItemIcon}>
                  <UploadOutlinedIcon fontSize="large" />
                </div>
              </IconButton>
            </div>
            <div style={styles.listText}>
              <Typography variant="inherit">Upload your own photos</Typography>
            </div>
          </div>
          <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <IconButton
                aria-label="upload"
                onClick={handleLibrary}
                size="medium"
              >
                <div style={styles.listItemIcon}>
                  <PhotoLibraryOutlinedIcon fontSize="large" />
                </div>
              </IconButton>
            </div>
            <div style={styles.listText}>
              <Typography variant="inherit">
                Add images from our Library
              </Typography>
            </div>
          </div>
          <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <Tooltip
                title="Button on each card"
                placement="left"
                arrow
                aria-label="button on card"
              >
                <div style={styles.listItemIcon}>
                  <CropOutlinedIcon fontSize="large" />
                </div>
              </Tooltip>
            </div>
            <div style={styles.listText}>
              <Typography variant="inherit">Crop them just right</Typography>
            </div>
          </div>
          <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <div style={styles.listItemBackDesign}>
                <img
                  style={styles.backDesignImage}
                  alt="back design"
                  src={assets["B0006_vertical.png"]}
                />
              </div>
            </div>
            <div style={styles.listText}>
              <Typography variant="inherit">
                Get your custom printed cards!
              </Typography>
            </div>
          </div>
          {/* <hr style={styles.dividerFull} /> */}

          {view === "help" && (
            <Alert severity="success">
              <AlertTitle>Pricing: </AlertTitle>
              Standard deck (12 cards): <strong>$16</strong> <br />
              Additional cards: <strong>$1</strong> each <br />
              Shipping: <strong>$3.95</strong> <br />
            </Alert>
          )}

          {view === "welcome" && (
            <div style={styles.signInArea}>
              <div style={styles.signInText}>
                <Typography variant="inherit">
                  Sign in to start creating your cards.
                </Typography>
              </div>

              <div style={styles.buttonBox}>
                <Button
                  variant="outlined"
                  size="large"
                  style={styles.signInButton}
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Create Account
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={styles.signInButton}
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  Sign In
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
