const assets = {
  "B0006_horizontal.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0006_horizontal.png?alt=media&token=24a482a0-9849-4c9f-a502-b535245eb411",
  "B0008_horizontal.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0008_horizontal.png?alt=media&token=340cacc6-531b-4c12-9f10-5e5b4b2369ea",
  "B0009_horizontal.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0009_horizontal.png?alt=media&token=d98fb14e-7590-4354-94c8-ad8eaeb5e88b",
  "B0011_horizontal.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0011_horizontal.png?alt=media&token=2ab8d21d-8ba5-4edd-a6a0-84380f194383",
  "B0006_vertical.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0006_vertical.png?alt=media&token=ca7ca109-ae99-4995-9c39-66acce01d958",
  "B0008_vertical.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0008_vertical.png?alt=media&token=9bd7042b-b4e9-4de6-bd63-98e00e2fb6e2",
  "B0009_vertical.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0009_vertical.png?alt=media&token=85f58744-f9b0-42c1-bc42-be7e6cc7323c",
  "B0011_vertical.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2FB0011_vertical.png?alt=media&token=11307b42-3c88-43ec-9df3-feb52770f55a",
  "selector_horizontal.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Fselector_horizontal.png?alt=media&token=8f7f6354-fe40-490f-8bbd-57366d00fcc2",
  "banner.jpg":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Fbanner.jpg?alt=media&token=ef1eb63a-71e4-4fd5-8e66-e313902c8cd2",
  "f_logo.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Ff_logo.png?alt=media&token=118ee54e-a6d0-45f7-a30a-d44647ef8e3d",
  "g_logo.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Fg_logo.png?alt=media&token=1b035a26-9aae-446c-939b-e4a9b014addc",
  "google-photos-icon.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Fgoogle-photos-icon.png?alt=media&token=bd48c29f-fbe8-4922-b561-920cda7c7161",
  "moments_cards.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Fmoments_cards.png?alt=media&token=98bdf6ee-0a2c-4a72-a40d-bcfa682f4be0",
  "moments_cards_vert.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Fmoments_cards_vert.png?alt=media&token=4e3d9935-c922-4ab4-aac5-2b5d0f7d02ab",
  "logo.png":
    "https://firebasestorage.googleapis.com/v0/b/moments-cards-prod.appspot.com/o/assets%2Flogo.png?alt=media&token=d4d135f8-b119-4ed2-bb64-c143e6b35243",
};

export default assets;
