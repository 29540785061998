import React, { useContext, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { AuthContext } from "./auth-provider";
import AppContext from "./app-context";

import { useNavigate } from "react-router-dom";

import OrderRow from "./order-row";
import theme from "../styles/theme";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import Checkbox from '@mui/material/Checkbox';
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

// Firebase
import firebaseApp, { apiCall } from "../utils/firebase";
import {
  getFirestore,
  query,
  where,
  collectionGroup,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

const classes = {
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  orderBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  },
  shippingBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "80%",
    marginTop: "20px",
  },
  shipCityStateZip: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "80%",
  },
  headingSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  inputs: {
    margin: "5px",
  },
  warningBox: {
    marginBottom: "20px",
  },
  warningTitle: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    color: theme.palette.warning.main,
  },
  warningAlert: {
    fontWeight: "700",
    color: theme.palette.warning.main,
  },
  warningMessage: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1em",
  },
  dateCell: {
    whiteSpace: "nowrap",
  },
};

function Row({ row, openAll }) {
  const { dispatch } = useContext(AppContext);

  const statusList = ["pending", "confirmed", "printed", "hold", "archive"];

  const [open, setOpen] = useState(openAll);
  const [editShipping, setEditShipping] = useState(false);
  const [shipping, setShipping] = useState({
    name: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
    },
    ...row.shipping,
  });
  // const classes = useRowStyles();

  useEffect(() => {
    setOpen(openAll);
  }, [openAll]);

  const handleStatusChange = (event) => {
    const updateData = { status: event.target.value };
    updateDoc(row.ref, updateData).catch((err) =>
      alert("Error updating order status: " + err)
    );
  };

  const handleEditShipping = () => {
    setEditShipping(true);
  };

  const handleSendConfirmationEmail = async () => {
    // Prepare parameters for the call to the sendConfirmationEmail API method
    const data = {
      orderID: row.id,
      userID: row.userID,
    };

    // sendConfimationEmail api call
    const sendConfirmationEmail = apiCall("sendConfirmationEmail");
    await sendConfirmationEmail(data)
      .then((res) => {
        alert(`Email sent successfully to ${res.data.email}.`);
      })
      .catch((err) => {
        console.log(`Error in 'sendConfimationEmail' api call: ${err}`);
      });
  };

  const cancelEditShipping = () => {
    setEditShipping(false);
    setShipping({
      name: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        postal_code: "",
      },
      ...row.shipping,
    });
  };

  const confirmEditShipping = () => {
    updateDoc(row.ref, { shipping }).catch((err) => {
      const error = new Error(`Error updating shipping. Error: ${err}`);
      console.log(error);
      dispatch({
        type: "ADD_ALERT",
        payload: { alert: { type: "error", message: error.toString() } },
      });
    });
    setEditShipping(false);
  };

  const updateShippingName = (evt) => {
    setShipping({
      ...shipping,
      name: evt.target.value,
    });
  };

  const updateShippingAddress = (evt) => {
    setShipping({
      ...shipping,
      address: {
        ...shipping.address,
        [evt.target.id]: evt.target.value,
      },
    });
  };

  return (
    <>
      <TableRow style={{ padding: "30px" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Link
            component={RouterLink}
            to={`/orders/${row.customerOrderCode}`}
            underline="hover"
          >
            {row.customerOrderCode
              ? `${row.customerOrderID} - ${row.customerOrderCode}`
              : "[none]"}
          </Link>
        </TableCell>

        <TableCell align="right">
          {
            <Select value={row.status} onChange={handleStatusChange}>
              {statusList.map((status) => (
                <MenuItem value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          }
        </TableCell>
        <TableCell align="right">{row.email}</TableCell>

        <TableCell align="right">
          {row.items.filter((elt) => elt.type === "print").length}
        </TableCell>
        <TableCell align="right">{`$${(row.amountTotal / 100).toFixed(
          2
        )}`}</TableCell>
        <TableCell style={classes.dateCell}>
          {row.created_at.toDate().toUTCString()}
        </TableCell>
        <TableCell>
          {row.warnings && row.warnings.length > 0 ? (
            <Typography style={classes.warningAlert}>
              {row.warnings.length}
            </Typography>
          ) : (
            0
          )}
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell align="right">{row.userID}</TableCell>
        <TableCell>
          <IconButton
            onClick={handleSendConfirmationEmail}
            color="primary"
            aria-label="send confirmation email"
            size="small"
          >
            <SendIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {row.warnings && row.warnings.length > 0 && (
                <div style={classes.warningBox}>
                  <Typography style={classes.warningTitle}>
                    Warnings:
                  </Typography>
                  {row.warnings.map((item, index) => (
                    <div style={classes.warningMessage} key={index}>
                      {item}
                    </div>
                  ))}
                </div>
              )}

              <div style={classes.orderBox}>
                {row.items
                  .filter((item) => item.type === "print")
                  .map((item) => (
                    <OrderRow key={item.printID} version="order" item={item} />
                  ))}
              </div>

              <div style={classes.shippingBox}>
                <div style={classes.headingSmall}>
                  <Typography variant="inherit">
                    Shipping:
                    {!editShipping && (
                      <IconButton
                        onClick={handleEditShipping}
                        color="primary"
                        aria-label="edit shipping"
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {editShipping && (
                      <>
                        <IconButton
                          onClick={confirmEditShipping}
                          color="primary"
                          aria-label="edit shipping"
                          size="large"
                        >
                          <CheckCircleIcon />
                        </IconButton>
                        <IconButton
                          onClick={cancelEditShipping}
                          color="secondary"
                          aria-label="edit shipping"
                          size="large"
                        >
                          <CancelIcon />
                        </IconButton>
                      </>
                    )}
                  </Typography>
                </div>
                {row.shipping && !editShipping && (
                  <>
                    <div style={classes.shipName}>{row.shipping.name}</div>
                    {row.shipping.address.line1 && (
                      <div style={classes.shipAddr}>
                        {row.shipping.address.line1}
                      </div>
                    )}
                    {row.shipping.address.line2 && (
                      <div style={classes.shipAddr}>
                        {row.shipping.address.line2}
                      </div>
                    )}
                    <div style={classes.shipCityStateZip}>
                      <div style={classes.shipCity}>
                        {row.shipping.address.city},&nbsp;
                      </div>
                      <div style={classes.shipState}>
                        {row.shipping.address.state}&nbsp;
                      </div>
                      <div style={classes.shipZip}>
                        {row.shipping.address.postal_code}
                      </div>
                    </div>
                  </>
                )}

                {editShipping && (
                  <>
                    <div style={classes.shipName}>
                      <TextField
                        style={classes.inputs}
                        onChange={updateShippingName}
                        id="name"
                        label="name"
                        variant="outlined"
                        value={shipping.name}
                      />
                    </div>
                    <div style={classes.shipAddr}>
                      <TextField
                        style={classes.inputs}
                        onChange={updateShippingAddress}
                        id="line1"
                        label="Address"
                        variant="outlined"
                        value={shipping.address.line1}
                      />
                    </div>
                    <div style={classes.shipAddr}>
                      <TextField
                        style={classes.inputs}
                        onChange={updateShippingAddress}
                        id="line2"
                        label="Address 2"
                        variant="outlined"
                        value={shipping.address.line2}
                      />
                    </div>
                    <div style={classes.shipCityStateZip}>
                      <div style={classes.shipCity}>
                        <TextField
                          style={classes.inputs}
                          onChange={updateShippingAddress}
                          id="city"
                          label="City"
                          variant="outlined"
                          value={shipping.address.city}
                        />
                      </div>
                      <div style={classes.shipState}>
                        <TextField
                          style={classes.inputs}
                          onChange={updateShippingAddress}
                          id="state"
                          label="State"
                          variant="outlined"
                          value={shipping.address.state}
                        />
                      </div>
                      <div style={classes.shipZip}>
                        <TextField
                          style={classes.inputs}
                          onChange={updateShippingAddress}
                          id="postal_code"
                          label="Zip"
                          variant="outlined"
                          value={shipping.address.postal_code}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function AdminOrders() {
  // Get an integer out of a string database property, including undefined and null (0)
  const coerceInt = (str) => (str ? +str : 0);

  const navigate = useNavigate();
  const { isAdmin } = useContext(AuthContext);
  const { dispatch } = useContext(AppContext);

  const [openAll, setOpenAll] = useState(false);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!isAdmin) return;

    let unSubscribe = () => {};
    try {
      const ordersQuery = query(
        collectionGroup(db, "orders"),
        where("deleted_at", "==", null)
      );
      unSubscribe = onSnapshot(
        ordersQuery,
        (querySnapshot) => {
          const orderList = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists()) {
              const data = doc.data();
              const order = {
                ref: doc.ref,
                id: doc.ref.id,
                userID: doc.ref.parent.parent.id,
                ...data,
              };
              orderList.push(order);
            }
          });
          setOrders(orderList);
        },
        (error) => {
          Sentry.captureException(error);
          process.env.REACT_APP_ENV === "dev" &&
            console.log(`onSnapshot error: ${error}`);
        }
      );
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error subscribing to updates for order list: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [isAdmin, dispatch]);

  useEffect(() => {
    if (isAdmin === false) {
      navigate("/deck");
    }
  }, [isAdmin, navigate]);

  if (!isAdmin) {
    return <></>;
  }

  return (
    <div style={classes.root}>
      <Link component={RouterLink} to={`/deck`} underline="hover">
        Home
      </Link>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton
                  aria-label="expand all"
                  size="small"
                  onClick={() => setOpenAll(!openAll)}
                >
                  {openAll ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Qty.</TableCell>
              <TableCell align="right">Paid</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Warnings</TableCell>
              <TableCell>DB ID</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Confirmation Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .filter((elt) => elt.status === "confirmed")
              .sort(
                (a, b) =>
                  coerceInt(a.customerOrderID) - coerceInt(b.customerOrderID)
              )
              .map((order) => (
                <Row
                  key={order.customerOrderID || order.created_at}
                  row={order}
                  openAll={openAll}
                />
              ))}
            {orders
              .filter((elt) => elt.status !== "confirmed")
              .sort(
                (a, b) =>
                  coerceInt(a.customerOrderID) - coerceInt(b.customerOrderID)
              )
              .map((order) => (
                <Row
                  key={order.customerOrderID || order.created_at}
                  row={order}
                  openAll={openAll}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminOrders;
