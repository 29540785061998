import React, { useRef, useContext, useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton"; //

import theme from "../styles/theme";
import Cropper from "react-cropper";
import "../styles/cropper.css";
import "../styles/custom-styles.css";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
// import CropRotateIcon from '@mui/icons-material/CropRotate';
import CancelIcon from "@mui/icons-material/Cancel";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModalCropLoading from "./modal-crop-loading";
import Alert from "@mui/material/Alert";
// import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
// import CropPortraitIcon from '@mui/icons-material/CropPortrait';

import AppContext from "./app-context";
import { useInterval } from "../utils/custom-hooks.js";

// Firebase
import { apiCall } from "../utils/firebase";
import { serverTimestamp, updateDoc } from "firebase/firestore";

import { loadCSS } from "fg-loadcss";

import { CARD_ASPECT_RATIO } from "../utils/constants";

const CROP_ASPECT_RATIO = CARD_ASPECT_RATIO;

const styles = {
  root: {
    zIndex: 1200,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "black",
    transform: "scale(1)",
    opacity: 1,
    visibility: "visible",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingSpinner: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1201,
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  cropContainer: {
    width: "100vw",
    height: "65vh",
    maxWidth: "800px",
    maxHeight: "800px",
  },
  buttonRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    marginTop: "10px",
  },
  alertRow: {
    marginTop: "5px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: "24px",
  },
  topRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 5px",
  },
  cancelButton: {
    color: theme.palette.danger.main,
  },
};

function ModalCrop({ card }) {
  const { dispatch } = useContext(AppContext);

  const [src, setSrc] = useState();
  const [cropOrientation, setCropOrientation] = useState("vertical");
  const [cardIsLoading, setCardIsLoading] = useState(true);
  const [cropperIsLoading, setCropperIsLoading] = useState(true);
  const [isCropping, setIsCropping] = useState(false);
  const [cropImageData, setCropImageData] = useState({});
  const [cropperEdges, setCropperEdges] = useState();
  const [highlightLeft, setHighlightLeft] = useState(false);
  const [highlightRight, setHighlightRight] = useState(false);
  const [highlightTop, setHighlightTop] = useState(false);
  const [highlightBottom, setHighlightBottom] = useState(false);

  useEffect(() => {
    setCropOrientation(card.orientation);
  }, [card]);

  // const cropImage = card.originalImage;

  const cropImage = card.displayImage;

  useEffect(() => {
    if (cropImage && cropImage.src) {
      setSrc(cropImage.src);
    }
  }, [cropImage]);

  const cropperRef = useRef(null);

  // Don't crop until we have the src for the original image
  useEffect(() => {
    if (card && !card.processing && cropImage) {
      setCardIsLoading(false);
    }
  }, [card, card.processing, cropImage]);

  // Wait for the cropper to open and then apply the displayCropData to set it to the last known crop
  // At the same time, set cropperIsLoading to false to hide the loading spinner
  useEffect(() => {
    function getEdges(elementArray) {
      const edges = {
        right: elementArray.filter((el) => el.dataset.cropperAction === "e"),
        left: elementArray.filter((el) => el.dataset.cropperAction === "w"),
        top: elementArray.filter((el) => el.dataset.cropperAction === "n"),
        bottom: elementArray.filter((el) => el.dataset.cropperAction === "s"),
      };
      return edges;
    }

    if (cropperRef.current && cropperIsLoading) {
      // const img = cropperRef.current;

      const refStore = cropperRef.current;

      const readyAction = () => {
        setTimeout(() => {
          setCropperIsLoading(false);
          setCropImageData(cropperRef.current.cropper.getImageData());
          cropperRef.current.cropper.setData(card.displayCropData);
          setCropperEdges(
            getEdges([...cropperRef.current.cropper.cropBox.children])
          );
        }, 50);
      };
      cropperRef.current.addEventListener("ready", readyAction);
      return () => refStore.removeEventListener("ready", readyAction);
    }
  });

  // Test to track the panning of the image to reproduce those crops that involve panning
  // useEffect(() => {
  //     if (cropperRef.current) {
  //         const img = cropperRef.current;
  //         const cropmoveListener = (evt) => {
  //             console.log(evt);
  //         }
  //         img.addEventListener('cropmove', cropmoveListener)
  //         return () => img.removeEventListener('cropmove', cropmoveListener)
  //     }
  // })

  const handleClose = (evt) => {
    dispatch({ type: "MODAL", payload: {} });
  };

  const handleCrop = async (evt) => {
    setIsCropping(true);
    evt.preventDefault();
    evt.stopPropagation();
    await serverCrop(evt);
  };

  const serverCrop = async (evt) => {
    const crop = apiCall("crop");
    const displayCropData = cropperRef.current.cropper.getData(true);
    const imageData = cropperRef.current.cropper.getImageData();
    const zoomLevel = imageData.width / imageData.naturalWidth;
    const cardID = card.ref.id;
    displayCropData.zoomLevel = zoomLevel;

    // Scale the crop data using the dimensions of the original and the display image
    const originalImageData = {
      width: card.originalImage.width,
      height: card.originalImage.height,
    };

    // cropImageData is the display image data
    const scaleFactor = originalImageData.width / imageData.naturalWidth;

    // Print crop data is the crop data scaled to the original image
    const originalCropData = {
      ...displayCropData,
      width: Math.round(displayCropData.width * scaleFactor),
      height: Math.round(displayCropData.height * scaleFactor),
      x: Math.round(displayCropData.x * scaleFactor),
      y: Math.round(displayCropData.y * scaleFactor),
      rotate: displayCropData.rotate,
    };

    const cardUpdate = {
      orientation: cropOrientation,
      originalCropData,
      displayCropData,
      updated_at: serverTimestamp(),
      croppedImage: null,
    };

    // If there is a rotation then basic CSS won't work for the crop preview
    // Generate a blob and set it as the preview image
    if (displayCropData.rotate % 360 !== 0) {
      const cropPreviewSrc = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL();
      dispatch({
        type: "SET_PREVIEW_IMAGE",
        payload: { cardID, src: cropPreviewSrc, orientation: cropOrientation },
      });
    }

    await updateDoc(card.ref, cardUpdate).catch(function (error) {
      console.log("Error setting new CropData in database:", error);
      return error;
    });

    if (displayCropData.rotate % 360 === 0) {
      dispatch({ type: "CLEAR_PREVIEW_IMAGE", payload: { cardID } });
    }

    handleClose(evt);

    const deckID = card.ref.parent.parent.id;

    // Prepare parameters for the call to the crop API method
    const data = {
      cropData: originalCropData,
      deckID,
      cardID,
      ext: card.originalImage.ext,
    };

    const cropResult = await crop(data);

    if (!cropResult.data.src) {
      console.error("Error in crop api call: ", cropResult);
    }
  };

  const zoom = (factor) => (evt) => {
    cropperRef.current.cropper.zoom(factor);
    evt.preventDefault();
    evt.stopPropagation();
  };

  const rotateCropBox = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (cropOrientation === "vertical") {
      cropperRef.current.cropper.setAspectRatio(1 / CROP_ASPECT_RATIO);
      setCropOrientation("horizontal");
    } else {
      cropperRef.current.cropper.setAspectRatio(CROP_ASPECT_RATIO);
      setCropOrientation("vertical");
    }
  };

  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  // function highlightAlert() {
  //   if (highlightAlerted) return;

  //   const alertKey = Symbol();

  //   setHighlightAlerted(alertKey);

  //   dispatch({
  //     type: "ADD_ALERT",
  //     payload: {
  //       key: alertKey,
  //       alert: {
  //         type: "info",
  //         message:
  //           "Area outside of the image will be filled with white background",
  //       },
  //     },
  //   });
  // }

  function highlightVertical(elts) {
    elts.forEach((elt) => {
      elt.style.backgroundColor = "yellow";
      elt.style.opacity = 1;
      elt.style.width = "3px";
    });
  }

  function highlightHorizontal(elts) {
    elts.forEach((elt) => {
      elt.style.backgroundColor = "yellow";
      elt.style.opacity = 1;
      elt.style.height = "3px";
    });
  }

  function removeHighlight(elts) {
    elts.forEach((elt) => {
      elt.style.removeProperty("background-color");
      elt.style.removeProperty("opacity");
      elt.style.removeProperty("width");
    });
  }

  function pollCropData() {
    // Only start taking actions once cropper is fuly loaded
    if (cropperIsLoading || !cropperRef.current) {
      return;
    }

    const data = cropperRef.current.cropper.getData(true);

    if (data.x < 0 && !highlightLeft) {
      setHighlightLeft(true);
      highlightVertical(cropperEdges.left);
    } else if (data.x >= 0 && highlightLeft) {
      setHighlightLeft(false);
      removeHighlight(cropperEdges.left);
    }

    if (data.y < 0 && !highlightTop) {
      setHighlightTop(true);
      highlightHorizontal(cropperEdges.top);
    } else if (data.y >= 0 && highlightTop) {
      setHighlightTop(false);
      removeHighlight(cropperEdges.top);
    }

    if (data.x + data.width > cropImageData.naturalWidth && !highlightRight) {
      setHighlightRight(true);
      highlightVertical(cropperEdges.right);
    } else if (
      data.x + data.width <= cropImageData.naturalWidth &&
      highlightRight
    ) {
      setHighlightRight(false);
      removeHighlight(cropperEdges.right);
    }

    if (
      data.y + data.height > cropImageData.naturalHeight &&
      !highlightBottom
    ) {
      setHighlightBottom(true);
      highlightHorizontal(cropperEdges.bottom);
    } else if (
      data.y + data.height <= cropImageData.naturalHeight &&
      highlightBottom
    ) {
      setHighlightBottom(false);
      removeHighlight(cropperEdges.bottom);
    }
  }

  useInterval(() => {
    pollCropData();
  }, 100);

  return (
    <div style={styles.root}>
      <div style={styles.alertRow}>
        {(highlightLeft ||
          highlightRight ||
          highlightTop ||
          highlightBottom) && (
          <Alert
            severity={"warning"}
            // style={styles.alert}
          >
            Area outside image will be white
          </Alert>
        )}
      </div>
      <div
        style={styles.cropContainer}
        onClick={(evt) => evt.stopPropagation()}
      >
        {!cardIsLoading && (
          <Cropper
            src={src}
            style={{
              width: "100vw",
              height: "65vh",
              maxWidth: "800px",
              maxHeight: "800px",
            }}
            key={card.ref.id}
            // Cropper.js options
            aspectRatio={
              card.orientation === "vertical"
                ? CROP_ASPECT_RATIO
                : 1 / CROP_ASPECT_RATIO
            }
            // crop={}
            ref={cropperRef}
            viewMode={0}
            dragMode="move"
            // data={card.displayCropData && card.displayCropData}
            // rotateTo={card.displayCropData && card.displayCropData.rotate}
            zoomTo={card.displayCropData && card.displayCropData.zoomLevel}
          />
        )}
        {cropperIsLoading && (
          <div style={styles.loadingSpinner}>
            {" "}
            <ModalCropLoading type="loading" />{" "}
          </div>
        )}
        {isCropping && (
          <div style={styles.loadingSpinner}>
            {" "}
            <ModalCropLoading type="cropping" />{" "}
          </div>
        )}
      </div>
      <div style={styles.buttonRow}>
        <div style={styles.buttonGroup}>
          <div>
            <Tooltip title="Zoom in" arrow>
              <IconButton onClick={zoom(0.1)} size="large">
                {" "}
                <Icon className="fa fa-search-plus" />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="Zoom out" arrow>
              <IconButton onClick={zoom(-0.1)} size="large">
                {" "}
                <Icon className="fa fa-search-minus" />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="Rotate Crop" arrow>
              <IconButton onClick={rotateCropBox} size="large">
                {" "}
                <RotateRightIcon />{" "}
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Portrait Crop" arrow><IconButton onClick={cropPortrait} > <CropPortraitIcon /> </IconButton></Tooltip>
                        <Tooltip title="Landscape Crop" arrow><IconButton onClick={cropLandscape} > <CropLandscapeIcon /> </IconButton></Tooltip>
                        <Tooltip title="Rotate image 90" arrow><IconButton onClick={rotateImage(90)} > <RotateRightIcon /> </IconButton></Tooltip> */}
          </div>
          <div>
            <Tooltip title="Accept Crop" arrow>
              <IconButton onClick={handleCrop} color="primary" size="large">
                {" "}
                <CheckCircleIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" arrow>
              <IconButton
                onClick={handleClose}
                style={styles.cancelButton}
                size="large"
              >
                {" "}
                <CancelIcon />{" "}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCrop;
