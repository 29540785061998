import React, { useContext } from "react"; //

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AppContext from "./app-context";

const styles = {
  progressDiv: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    width: "100%",
  },
};

function ModalCropLoading({ type }) {
  const { dispatch } = useContext(AppContext);

  const handleCancel = (evt) => {
    dispatch({ type: "MODAL", payload: {} });
  };

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {type === "loading" && "Loading high-resolution image for cropping"}
        {type === "cropping" && "Creating high-resolution cropped image"}
      </DialogTitle>
      <DialogContent style={styles.progressDiv}>
        <CircularProgress />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalCropLoading;
