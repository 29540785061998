import { createTheme } from "@mui/material/styles";
// import { green, grey, red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: "#33b8a9",
      main: "#00a794",
      dark: "#007467",
      contrastText: "#fff",
    },
    // primary: {
    //     main: '#ffea00',
    //     contrastText: '#000',
    // },
    danger: {
      light: "8b3bc1",
      main: "#6F0BB2",
      dark: "#4d077c",
      contrastText: "#fff",
    },

    // primary: {
    //     light: '#ffff5a',
    //     main: '#fffb04',
    //     dark: '#c8c800',
    //     contrastText: '#000',
    // },
    // warning: {
    //     main: '#7908AA',
    //     dark: '#5E0684',
    //     contrastText: '#fff',
    // },
    // TODO: insert palette colors here
    // error: {
    //   light: red[50],
    //   main: red[500],
    //   dark: red[700],
    // },
    // success: {
    //   light: green[50],
    //   main: green[500],
    //   dark: green[700],
    // },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeightRegular: 500, // Roboto
    fontWeightMedium: 700, // Roboto Condensed
  },
});

// const fontHeader = {
//   color: rawTheme.palette.text.primary,
//   fontWeight: rawTheme.typography.fontWeightMedium,
//   fontFamily: "'Roboto Condensed', sans-serif",
//   textTransform: 'uppercase',
// };

// const theme = {
//   ...rawTheme,
//   /* override browser default */
//   palette: {
//     ...rawTheme.palette,
//     background: {
//       ...rawTheme.palette.background,
//       default: rawTheme.palette.common.white,
//       placeholder: grey[200],
//     },
//   },
//   typography: {
//     ...rawTheme.typography,
//     fontHeader,
//     h1: {
//       ...rawTheme.typography.h1,
//       ...fontHeader,
//       letterSpacing: 0,
//       fontSize: 60,
//     },
//     h2: {
//       ...rawTheme.typography.h2,
//       ...fontHeader,
//       fontSize: 48,
//     },
//     h3: {
//       ...rawTheme.typography.h3,
//       ...fontHeader,
//       fontSize: 42,
//     },
//     h4: {
//       ...rawTheme.typography.h4,
//       ...fontHeader,
//       fontSize: 36,
//     },
//     h5: {
//       ...rawTheme.typography.h5,
//       fontSize: 20,
//       fontWeight: rawTheme.typography.fontWeightLight,
//     },
//     h6: {
//       ...rawTheme.typography.h6,
//       ...fontHeader,
//       fontSize: 18,
//     },
//     subtitle1: {
//       ...rawTheme.typography.subtitle1,
//       fontSize: 18,
//     },
//     body1: {
//       ...rawTheme.typography.body2,
//       fontWeight: rawTheme.typography.fontWeightRegular,
//       fontSize: 16,
//     },
//     body2: {
//       ...rawTheme.typography.body1,
//       fontSize: 14,
//     },
//   },
// };

export default theme;
