import React, { useContext, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { AuthContext } from "./auth-provider";
import Button from "@mui/material/Button";

import theme from "../styles/theme";
import { useNavigate } from "react-router-dom";
import AppContext from "./app-context";
import OrderRow from "./order-row";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// import Link from "@mui/material/Link";

// Firebase
import firebaseApp, { apiCall } from "../utils/firebase";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  serverTimestamp,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

const SHIPPING_ITEM = {
  type: "shipping",
  priceCents: 395,
  quantity: 1,
  name: "USPS First Class Shipping",
};

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRoot: {
    width: "100vw",
    height: "150vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2vh",
  },
  headerLogo: {
    height: "auto",
    width: "180px",
    // margin: 'auto'
  },
  headerBox: {
    // flexBasis: '10 10 content',
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    marginBottom: "20px",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: "40px",
  },
  headingSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  mainBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "6px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    minHeight: "400px",
    maxWidth: "350px",
    // height: '600px',
    position: "relative",
    padding: "10px",
  },
  cartBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  button: {
    width: "95%",
    marginTop: "15px",
    height: "42px",
  },
  dividerFull: {
    width: "100%",
    height: "1px",
    color: "#A9A9A9",
    margin: "0px",
  },
  dividerContainer: {
    width: "80%",
    margin: "10px 10px",
  },
  bottomPrompt: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: "#A9A9A9",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "center",
    // height: '100%',
    // padding: '10px',
  },
  Link: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: theme.palette.primary.main,
    textTransform: "none",
    marginLeft: "4px",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  bottomSection: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  checkoutSpinner: {
    color: "#FFFFFF",
  },
  giftBox: {
    width: "80%",
    marginTop: "10px",
  },
  giftCheckboxRow: {},
  giftMessageRow: {
    width: "100%",
  },
  giftMessageTextArea: {
    width: "100%",
  },
};

export default function Cart() {
  const navigate = useNavigate();
  const { dispatch, state, stripePromise } = useContext(AppContext);
  const { currentUser, isAdmin } = useContext(AuthContext);

  const rootClass = state.app.isMobile ? styles.mobileRoot : styles.root;
  const userRef = state.userData ? state.userData.ref : null;

  const [order, setOrder] = useState("loading");
  const [pendingCheckout, setPendingCheckout] = useState(false);
  const [pendingAdminOrder, setPendingAdminOrder] = useState(false);
  const [ownerEmail, setOwnerEmail] = useState("");

  // Wake up the createCheckoutSession for faster response when the user checks out
  useEffect(() => {
    const createCheckoutSession = apiCall("createCheckoutSession");
    const options = {
      ping: true,
    };

    createCheckoutSession(options);
  }, []);

  useEffect(() => {
    const createOrderFromCart = (cart) => {
      const items = Object.values(cart.items).sort((a, b) => {
        if (a.printID < b.printID) {
          return -1;
        }
        if (a.printID > b.printID) {
          return 1;
        }
        return 0;
      });
      items.push(SHIPPING_ITEM);

      const newPrice = items.reduce((price, item) => {
        return price + item.priceCents * item.quantity;
      }, 0);

      const newOrder = {
        email: currentUser.email,
        name: currentUser.displayName || "",
        isGift: cart.isGift || false,
        giftMessage: cart.giftMessage || "",
        items,
        status: "pending",
        amountSubtotal: newPrice,
      };
      // process.env.REACT_APP_ENV !== "prod " && console.log({ newOrder });
      setOrder(newOrder);
    };

    if (!userRef) return;

    let unSubscribe = () => {};
    try {
      unSubscribe = onSnapshot(
        userRef,
        (doc) => {
          if (doc.exists()) {
            const user = doc.data();
            const newCart = user.cart;
            // Check if cart has any contents
            if (newCart.items && Object.keys(newCart.items).length > 0) {
              createOrderFromCart(newCart);
            } else {
              // If cart is empty, set the order to null and clear out the gift messages
              setOrder(null);
              if (newCart.isGift || !!newCart.giftMessage) {
                updateDoc(doc.ref, {
                  [`cart.isGift`]: false,
                  [`cart.giftMessage`]: "",
                });
              }
            }
          }
        },
        (error) => {
          Sentry.captureException(error);
          process.env.REACT_APP_ENV === "dev" &&
            console.log(`onSnapshot error: ${error}`);
        }
      );
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error querying data for user cart: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [dispatch, userRef, currentUser.email, currentUser.displayName]);

  const handleClose = () => {
    // TODO: are you sure?
    navigate("/deck");
  };

  const createStripeSession = async () => {
    const createCheckoutSession = apiCall("createCheckoutSession");

    const checkoutOptions = {
      items: order.items,
      email: currentUser.email,
    };

    return createCheckoutSession(checkoutOptions);
  };

  const handleCheckout = async () => {
    // TODO: Remove this to turn ordering back on
    dispatch({
      type: "ADD_ALERT",
      payload: {
        alert: {
          type: "warning",
          message:
            "Sales are paused while we migrate our systems. Please contact will@moments.cards to order.",
        },
      },
    });
    return;

    // END TODO

    setPendingCheckout(true);

    const session = await createStripeSession();
    const stripe = await stripePromise;

    const newOrder = {
      ...order,
      status: "pending",
      type: "customer",
      stripeSessionID: session.data.sessionID,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
      deleted_at: null,
    };

    await addDoc(collection(userRef, "orders"), newOrder).catch((err) => {
      console.error("Error saving order at checkout: ", err);
      dispatch({
        type: "ADD_ALERT",
        payload: {
          alert: {
            type: "error",
            message:
              "Soemthing went wrong. Please refresh the page and try again or contact support.",
          },
        },
      });
      return;
    });

    const { error } = await stripe.redirectToCheckout({
      sessionId: session.data.sessionID,
    });

    if (error) {
      console.log("Error redirecting to checkout.");
      dispatch({
        type: "ADD_ALERT",
        payload: {
          alert: {
            type: "error",
            message:
              "Soemthing went wrong connecting to checkout. Please try again or contact support.",
          },
        },
      });
    }
  };

  const handleAdminOrder = async () => {
    try {
      setPendingAdminOrder(true);

      // Get user from email
      const getUserIDFromEmail = apiCall("getUserIDFromEmail");

      const ownerRef = await getUserIDFromEmail({ email: ownerEmail })
        .then((res) => {
          return doc(db, "users", res.data.userID);
        })
        .catch((err) => {
          const error = new Error(
            `Error getting userRef from email. Code: ${err.code} Error: ${err.message}`
          );
          throw error;
        });

      const newOrder = {
        ...order,
        status: "pending",
        type: "admin",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        deleted_at: null,
      };

      const newOrderRef = doc(collection(ownerRef, "orders"));

      await setDoc(newOrderRef, newOrder).catch((err) => {
        const error = new Error(`Error saving new order. Error: ${err}`);
        throw error;
      });

      // As an admin, confirm order without payment
      const adminConfirmOrder = apiCall("adminConfirmOrder");

      await adminConfirmOrder({
        userID: ownerRef.id,
        orderID: newOrderRef.id,
        discount: order.amountSubtotal,
      })
        .then((res) => {
          dispatch({
            type: "ADD_ALERT",
            payload: {
              alert: {
                type: "success",
                message: `Order confrimed with userID [${ownerRef.id}] orderID [${newOrderRef.id}] and `,
              },
            },
          });
          setPendingAdminOrder(false);
        })
        .catch((err) => {
          const error = new Error(
            `Error confirming order. Code: ${err.code} Error: ${err.message}`
          );
          throw error;
        });
    } catch (err) {
      const error = new Error(`Error in admin order. Error: ${err}`);
      console.log(error);
      dispatch({
        type: "ADD_ALERT",
        payload: { alert: { type: "error", message: error.toString() } },
      });
      setPendingAdminOrder(false);
    }
  };

  const handleChangeGift = () => {
    const newIsGift = !order.isGift;
    updateDoc(state.userData.ref, {
      [`cart.isGift`]: newIsGift,
      [`cart.giftMessage`]: "",
    });
  };

  const handleChangeGiftMessage = (evt) => {
    updateDoc(state.userData.ref, {
      [`cart.giftMessage`]: evt.target.value,
    });
  };

  const updateOwnerEmail = (evt) => {
    const newEmail = evt.target.value;
    setOwnerEmail(newEmail);
  };

  // MUSE CHANGE
  // const checkoutButton = (
  //   <Button
  //     style={styles.button}
  //     href="https://www.musedecks.com"
  //     variant="contained"
  //     size="large"
  //   >
  //     Order at www.musedecks.com
  //   </Button>
  // );

  const checkoutButton = pendingCheckout ? (
    <Button
      style={styles.button}
      variant="contained"
      color="primary"
      size="large"
    >
      <CircularProgress size={25} style={styles.checkoutSpinner} />
    </Button>
  ) : (
    <Button
      style={styles.button}
      onClick={handleCheckout}
      variant="contained"
      color="primary"
      size="large"
      endIcon={<KeyboardArrowRightIcon />}
    >
      Payment Details
    </Button>
  );

  // MUSE CHANGE

  const adminOrderButton = pendingAdminOrder ? (
    <Button
      style={styles.button}
      variant="contained"
      color="primary"
      size="large"
    >
      <CircularProgress size={25} style={styles.checkoutSpinner} />
    </Button>
  ) : (
    <Button
      style={styles.button}
      onClick={handleAdminOrder}
      variant="contained"
      color="primary"
      size="large"
      endIcon={<KeyboardArrowRightIcon />}
    >
      Submit Admin Order
    </Button>
  );

  return (
    <div style={rootClass}>
      <div style={styles.mainBox}>
        <div>
          {/* <IconButton aria-label="close" style={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton> */}
          {/* <div style={classes.headerBox}>
                        <img style={classes.headerLogo} alt="logo" src={assets["moments_cards_vert.png"]} />
                    </div> */}
        </div>

        {!order && (
          <div style={styles.headingBig}>
            <Typography variant="inherit">No items in your cart</Typography>
            <Button
              style={styles.button}
              onClick={handleClose}
              variant="contained"
              size="large"
            >
              Back to Decks
            </Button>
          </div>
        )}

        {order && (
          <div style={styles.headingBig}>
            <Typography variant="inherit">Shopping Cart</Typography>
          </div>
        )}

        <div style={styles.cartBox}>
          {order && order !== "loading" && (
            <>
              <OrderRow version="labels" />
              {order.items
                .filter((item) => item.type === "print")
                .map((item) => (
                  <OrderRow key={item.printID} item={item} version="cart" />
                ))}
              {order.items
                .filter((item) => item.type === "shipping")
                .map((item) => (
                  <OrderRow key={item.type} item={item} />
                ))}
              <OrderRow totalPrice={order.amountSubtotal} />
            </>
          )}
        </div>

        {order && (
          <div style={styles.giftBox}>
            <div style={styles.giftCheckboxRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!(order && order.isGift)}
                    onChange={handleChangeGift}
                    name="isGift"
                    color="primary"
                  />
                }
                label="This is a gift"
              />
            </div>

            {!!(order && order.isGift) && (
              <div style={styles.giftMessageRow}>
                <TextareaAutosize
                  style={styles.giftMessageTextArea}
                  onChange={handleChangeGiftMessage}
                  aria-label="gift messagex"
                  minRows={3}
                  maxRows={6}
                  placeholder="Add a gift message"
                  value={!!order && order.giftMessage}
                />
              </div>
            )}
          </div>
        )}

        {order && (
          <div style={styles.bottomSection}>
            <Button
              style={styles.button}
              onClick={handleClose}
              variant="contained"
              size="large"
            >
              Back to Decks
            </Button>
            {checkoutButton}
            <div style={styles.dividerContainer}>
              <hr style={styles.dividerFull} />
            </div>
            {isAdmin && (
              <TextField
                style={styles.inputs}
                onChange={updateOwnerEmail}
                id="email"
                label="Email"
                variant="outlined"
                value={ownerEmail}
              />
            )}
            {isAdmin && adminOrderButton}
            {/* <div style={classes.bottomPrompt}>
                        {"Enter promo code on the payment page"}
                    </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
