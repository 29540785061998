// CARDS
export const CARD_LONG_DIM_PX = 1050; // US Poker is 1050
export const CARD_SHORT_DIM_PX = 750; // US Poker is 750
export const CARD_ASPECT_RATIO = CARD_SHORT_DIM_PX / CARD_LONG_DIM_PX;
export const MIN_CARD_LONG_DIM_PX = 250;
export const PROOFS_CARD_LONG_DIM_PX = 450;
export const BORDER_RADIUS_RATIO = 0.04;
// Percent of the total width of the mobile screen that will
// be used to display the cards (vs. leave a margin)
export const MOBILE_CARD_DISPLAY_RATIO = 0.9;

export const MAX_OVERLAY_BUTTON_SIZE_PX = 40;
export const MIN_OVERLAY_BUTTON_SIZE_PX = 30;

export const MOBILE_CARDS_PER_ROW = 3;
export const MOBILE_CARDS_PER_ROW_ZOOM = 1;
export const CARDS_PER_ROW = 6;
export const CARD_DISPLAY_MARGIN_PX = 70;

// DECKS
export const BASE_DECK_SIZE = 12;
export const MINIMUM_DECK_SIZE = 6;

// APP LAYOUT
export const DRAWER_WIDTH_PX = 240;
export const HEADER_HEIGHT_PX = 50;
export const NOTIFY_HEIGHT_PX = 35;
