import React, { useContext, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import AppContext from "./app-context";
import { apiCall } from "../utils/firebase";
import processImages from "../utils/process-images";
import CircularProgress from "@mui/material/CircularProgress";

export default function GenerateImageDialog({ deck, handleClose }) {
  const { state, dispatch } = useContext(AppContext);

  // const [confirmDelete, setConfirmDelete] = useState(false);

  const [pending, setPending] = useState(false);

  async function generate({ prompt }) {
    // call the api and get an image url
    const replicateFunctionApi = apiCall("replicateFunction");
    const data = {
      prompt,
    };
    const res = await replicateFunctionApi(data);
    const imageURL = res.data.imageURL;
    // console.log(imageURL);

    // dummy data for dev
    // const imageURL =
    //   "https://replicate.delivery/pbxt/jLdlCskwbO5VK9ROeiqFmX0HLMpTz3lc68auak4A1xxEIDcIA/out-0.png";

    // fetch the image and put it in a file
    const response = await fetch(imageURL);
    const file = await response.blob();
    file.name = "generated.png";

    // call processImage with the file
    await processImages([file], state.decks.activeDeckRef, dispatch);

    handleClose();
    setPending(false);
  }

  const handleGenerate = (evt) => {
    evt.preventDefault();
    setPending(true);
    const prompt = evt.target.elements.prompt.value;

    generate({ prompt });
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Generate Image</DialogTitle>
      <form onSubmit={handleGenerate}>
        <DialogContent>
          <TextareaAutosize
            autoFocus
            margin="dense"
            id="prompt"
            label="Prompt"
            type="text"
            defaultValue={"a giant sequoia tree in front of a dramatic sunset"}
          />
        </DialogContent>
        <DialogActions>
          {pending && <CircularProgress />}
          {!pending && (
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          )}
          {!pending && (
            <Button type="submit" color="primary">
              Submit
            </Button>
          )}
        </DialogActions>
      </form>

      <hr />
    </Dialog>
  );
}
