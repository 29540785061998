import React, { useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AppContext from "./app-context";
import Create from "./create";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function CreateLibrary() {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  // Get the albumID and the deckID out of the url if it exists
  const { albumID } = useParams();
  const urlQueryParams = useQuery();
  const deckID = urlQueryParams.get("active_deck");

  // Check the URL and set the correct parameters for the given app state
  useEffect(() => {
    // Skip this if we don't currently have an active album AND an active deck in the app state
    if (!state.library.activeAlbumRef || !state.decks.activeDeckRef) return;

    // If there is no albumID in the URL
    // OR if there is no deckID in the query string to indiate the active deck
    // OR if the deckID in the query string doesn't match the current active deck (different user?)
    // Rebuild the correct URL and replace the existing one
    if (!albumID || !deckID || deckID !== state.decks.activeDeckRef.id) {
      let newURL = `/library/${state.library.activeAlbumRef.id}?active_deck=${state.decks.activeDeckRef.id}`;
      navigate(newURL, { replace: true });
    }
  }, [
    albumID,
    deckID,
    state.decks.activeDeckRef,
    state.library.activeAlbumRef,
    navigate,
  ]);

  return <Create view="library" />;
}
