import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";

const styles = {
  root: {
    width: "100vw",
    height: "150vh",
    background: "rgba(0, 0, 0, 0)",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2vh",
  },
  box: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    maxWidth: "350px",
    // paddingTop: "20px",
    // maxHeight: '600px',
  },
  submitButton: {
    width: "250px",
    margin: "10px 0",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: "gray",
    padding: "50px",
  },
};

export default function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();

  const goHome = () => {
    navigate("/deck");
  };

  useEffect(() => {
    const msg = `404`;
    Sentry.captureMessage(msg, "info");
    return;
  }, [location]);

  return (
    <div style={styles.root}>
      <div style={styles.headingBig}>
        <Typography variant="inherit">Can't find that page...</Typography>
      </div>
      <Button variant="contained" color="primary" onClick={goHome}>
        Go Home
      </Button>
    </div>
  );
}
