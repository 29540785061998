import React, { useContext, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import theme from "../styles/theme";
import AppContext from "./app-context";
import { AuthContext } from "./auth-provider";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import price from "../utils/pricing";

// Firebase
import firebaseApp from "../utils/firebase";
import {
  getFirestore,
  doc,
  collection,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

const styles = {
  root: {
    zIndex: 1000,
    position: "fixed",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    // height: '400px',
    maxWidth: "350px",
    padding: "10px",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  loading: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
};

export default function ModalOrder({ handleClose }) {
  const navigate = useNavigate();

  const { state } = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const generatePrintImages = async () => {
      process.env.REACT_APP_ENV !== "prod" &&
        console.log("generatePrintImages");

      const deckID = state.decks.activeDeck.ref.id;
      const deckUserID = state.decks.activeDeck.ref.parent.parent.id;
      const userID = state.userData.ref.id;
      const deckName = state.decks.activeDeck.name;
      const cardCount = state.decks.activeCardList
        ? state.decks.activeCardList.length
        : 0;

      const userRef = doc(db, "users", userID);

      const newPrintRef = doc(collection(userRef, "prints"));
      const printID = newPrintRef.id;

      const newPrint = {
        printID,
        userID,
        deckUserID,
        deckID,
        cardCount,
        deckName,
        quantity: 1,
        backImage: state.decks.activeDeck.backImage,
        email: currentUser.email,
        name: currentUser.displayName,
        status: "initiated",
        imageCount: 0,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        deleted_at: null,
      };

      setDoc(newPrintRef, newPrint);

      updateDoc(userRef, {
        [`cart.items.${printID}`]: {
          type: "print",
          printID,
          deckUserID,
          deckID,
          priceCents: price(cardCount),
          image: state.decks.activeDeck.backImage,
          quantity: 1,
          cardCount,
          name: deckName,
        },
      });

      handleClose();
      navigate(`/cart`);
    };

    generatePrintImages();
  }, [
    handleClose,
    navigate,
    state.decks.activeDeck.ref.id,
    state.userData.ref.id,
    state.decks.activeCardList,
    state.decks.activeDeck.backImage,
    state.decks.activeDeck.name,
    currentUser.email,
    currentUser.displayName,
    state.decks.activeDeck.ref.parent.parent.id,
  ]);

  return (
    <div style={styles.root}>
      {handleClose && (
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
      <div style={styles.headingBig}>
        <Typography variant="inherit">Finalizing print order</Typography>
      </div>

      <div style={styles.loading}>
        <CircularProgress />
      </div>
    </div>
  );
}
