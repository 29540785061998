import React, { useContext, useCallback, useEffect, useState } from "react";

import { useDropzone } from "react-dropzone";
import { AuthContext } from "./auth-provider";
import { useParams } from "react-router-dom";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CropIcon from "@mui/icons-material/Crop";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
// import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
// import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import UploadIcon from "@mui/icons-material/Upload";

import Card from "./card";
import AddFab from "./addfab";
import BackSelect from "./back-select";
import AppModals from "./app-modals";
import ModalCrop from "./modal-crop";
import AppContext from "./app-context";
import Loading from "./loading";
import ModalHelp from "./modal-help";
import OverlayButton from "./overlay-button";
import theme from "../styles/theme";
import processImages, { handlePaste } from "../utils/process-images";
import debounce from "../utils/debounce";

// Firebase
import firebaseApp, { apiCall, getStorageDir } from "../utils/firebase";
import {
  getFirestore,
  serverTimestamp,
  getDoc,
  doc,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  collectionGroup,
  query,
  where,
  limit,
} from "firebase/firestore";
import { getStorage, ref, listAll, deleteObject } from "firebase/storage";

import {
  CARD_ASPECT_RATIO,
  DRAWER_WIDTH_PX,
  MIN_CARD_LONG_DIM_PX,
  // BASE_DECK_SIZE,
  BORDER_RADIUS_RATIO,
  CARDS_PER_ROW,
  CARD_DISPLAY_MARGIN_PX,
  // MOBILE_CARD_DISPLAY_RATIO,
  MOBILE_CARDS_PER_ROW,
  MOBILE_CARDS_PER_ROW_ZOOM,
  MAX_OVERLAY_BUTTON_SIZE_PX,
  MIN_OVERLAY_BUTTON_SIZE_PX,
} from "../utils/constants";

const db = getFirestore(firebaseApp);

// Delay to use between initiating card flips to stagger the flip sequence
// TODO: adjust this for when there are a lot of cards
const flipDelayms = 50;

// NEW_CARD is the dummy card with buttons for creating new cards
// By uploading or adding from the library
// const NEW_CARD = {
//   newcard: true,
//   ref: "newcard",
//   cardID: "newcard",
// };

const styles = {
  root: {
    // height: "100%",
    width: "100%",
    position: "relative",
    flexGrow: 1,
    marginLeft: `-${DRAWER_WIDTH_PX}px`,
    transition: "margin-left 0.25s",
  },
  rootShift: {
    height: "100%",
    // width: "100%",
    position: "relative",
    transition: "margin-left 0.25s",
  },
  gallery: {
    // background: "#A9A9A9", // Original gray
    // background: "#F8F8FF", // ghost white
    background: "#CDCDCD", //
    padding: "5px 5px 80px 5px",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    alignContent: "flex-start",
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    width: "100%",
  },
  emptyGallery: {
    background: "#A9A9A9",
    padding: "5px 5px 80px 5px",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    width: "100%",
  },
  dragActive: {
    zIndex: "2000",
    background: theme.palette.primary.dark,
    opacity: "90%",
    margin: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    color: "white",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dropIcon: {
    fontSize: "15rem",
    marginTop: "50px",
  },
  dropText: {
    textAlign: "center",
    fontSize: "5rem",
  },
  addFAB: {
    position: "fixed",
    bottom: "2vw",
    right: "2vw",
  },
};

// Define the Card overlay buttons for the Library and Deck views
// Define the click action, styling and icon

// Delete the given card
function DeleteButton({ cardRef, size }) {
  const deleteCard = () => {
    // Delete firestore document for card and update deck document
    const p1 = deleteDoc(cardRef).catch((err) =>
      console.error("error deleting card:", err)
    );

    // Delete all image files for the card
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage);
    // Create ref to the folder
    const deckID = cardRef.parent.parent.id;
    const storageDir = getStorageDir({ cardID: cardRef.id, deckID });
    const cardImageFolderRef = ref(storageRef, storageDir);

    // Find all the files in the folder and delete them
    const p2 = listAll(cardImageFolderRef)
      .then(function (res) {
        res.items.forEach(function (itemRef) {
          deleteObject(itemRef);
        });
      })
      .catch(function (error) {
        console.log("Error deleting card image files:", error);
      });

    return Promise.all([p1, p2]);
  };

  const addStyle = {
    right: 5,
    top: 5,
    background: theme.palette.danger.main,
    color: theme.palette.danger.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.danger.dark,
    },
  };

  const buttonSize = Math.min(MAX_OVERLAY_BUTTON_SIZE_PX, size);

  return (
    <OverlayButton
      icon={<DeleteForeverIcon />}
      addStyle={addStyle}
      clickAction={deleteCard}
      buttonSize={buttonSize}
      tooltip="Delete card. Cannot be undone."
      buttonVariant="contained"
    />
  );
}

// Crop this card
function CropButton({ cardRef, size }) {
  const { dispatch } = useContext(AppContext);

  const cropCard = () =>
    dispatch({ type: "MODAL", payload: { type: "crop", data: { cardRef } } });

  const addStyle = {
    right: 5,
    bottom: 5,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  const buttonSize = Math.min(MAX_OVERLAY_BUTTON_SIZE_PX, size);

  return (
    <OverlayButton
      icon={<CropIcon />}
      addStyle={addStyle}
      clickAction={cropCard}
      buttonSize={buttonSize}
      tooltip="Crop image"
      buttonVariant="contained"
    />
  );
}

// Duplicate the given card with a new id
const AddToDeckButton = ({ sourceCard, size }) => {
  const { state, dispatch } = useContext(AppContext);

  const duplicateCard = async () => {
    const sourceCardID = sourceCard.ref.id;
    const newDeckID = state.decks.activeDeck.ref.id;
    const sourceDeckID = state.library.activeAlbum.ref.id;
    const userID = state.userData.ref.id;

    // Try to set the reference as if the deck is owned by this user
    let newDeckRef = doc(db, "users", userID, "decks", newDeckID);

    // Check if the deck is active and owned by this user
    // If it does not exist (not owned by the user) or if it has been deleted, return null to set the ref to null
    newDeckRef = await getDoc(newDeckRef)
      .then((doc) => {
        if (doc.exists() && !doc.data().deleted_at) {
          return doc.ref;
        } else {
          return null;
        }
      })
      .catch((err) => {
        console.error(
          `Error retrieving deck when adding card from library gallery. deckID [${newDeckID}] Error: ${err}`
        );
      });

    // New Deck doesn't belong to the user, so search all decks shared with this user
    if (!newDeckRef) {
      const newDeckQuery = query(
        collectionGroup(db, "decks"),
        where("sharedWith", "array-contains", userID),
        where("deckID", "==", newDeckID),
        where("deleted_at", "==", null)
      );
      newDeckRef = await getDocs(newDeckQuery)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            let deckRef = null;
            querySnapshot.forEach((doc) => {
              if (doc.ref.id === newDeckID) deckRef = doc.ref;
            });
            if (!!deckRef) {
              return deckRef;
            }
            console.error(
              `Error adding card. Deck not found in shared decks. deckID: ${newDeckID} userID: ${userID}`
            );
            return null;
          }
        })
        .catch((err) => {
          console.error(
            `Error searching shared decks to add card. deckID: ${newDeckID} userID: ${userID} error: ${err}`
          );
        });
    }

    // Could not find this deck in the user's decks or the decks shared with the user
    // So we log an error and exit from the function
    if (!newDeckRef) {
      const error = new Error(
        `Error: could not retrieve deck when adding card for deckID: ${newDeckID}`
      );
      console.error(error);
      // TODO: Alert User
      return;
    }

    const newCardRef = doc(collection(newDeckRef, "cards"));

    const newCardID = newCardRef.id;

    // First create the card in the database as a copy linking to the same images
    // Make sure there is no way for the user to edit the album version
    // Add the source_image_id property
    // Then have the server update with the new links

    const newCard = {
      ...sourceCard,
      cardID: newCardID,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
      deleted_at: null,
      display: true,
      processing: true,
      image_source_id: sourceCardID,
    };

    // Create a new card document in the collection with the basic data
    await setDoc(newCardRef, newCard).catch(function (error) {
      dispatch({
        type: "ADD_ALERT",
        payload: {
          alert: {
            type: "error",
            message: `Error adding card to your deck. Please reload the page and try again. If this error persists, contact support.`,
          },
        },
      });
      console.log("Error adding card to deck:", error);
      return error;
    });

    // Prepare parameters for the call to the addcard API method
    const data = {
      newDeckID,
      sourceDeckID,
      sourceCardID,
      userID,
      newCardID,
    };

    // addCard api call
    const addCard = apiCall("addCard");
    await addCard(data).catch((error) => {
      console.log(`Error in 'addcard' api call: ${error}`);
    });
  };

  const addStyle = {
    left: 5,
    bottom: 5,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  const buttonSize = Math.min(MAX_OVERLAY_BUTTON_SIZE_PX, size);

  return (
    <OverlayButton
      icon={<AddToPhotosIcon />}
      addStyle={addStyle}
      clickAction={duplicateCard}
      buttonSize={buttonSize}
      tooltip="Add this image to active deck"
      buttonVariant="contained"
    />
  );
};

// Add card(s) by uploading image(s)
// function UploadButton({ size, clickAction }) {
//   const addStyle = {
//     left: "50%",
//     top: "28%",
//     transform: "translate(-50%, -50%)",
//     "&.MuiButton-contained": {
//       color: "white",
//       backgroundColor: "rgb(255,255,255,0.1)",
//       boxShadow: "3px 3px 2px",
//     },
//     "&.MuiButton-root": {
//       border: "2px white solid",
//       fontWeight: 500,
//     },
//     borderRadius: "10%",
//     "&:hover": {
//       backgroundColor: "rgb(255,255,255,0.3)",
//     },
//   };

//   return (
//     <OverlayButton
//       icon={<FileUploadOutlinedIcon />}
//       addStyle={addStyle}
//       clickAction={clickAction}
//       buttonSize={size}
//       tooltip="Upload image(s)"
//       buttonVariant="contained"
//     />
//   );
// }

// Open Library to add cards
// function LibraryButton({ size }) {
//   const { state, dispatch } = useContext(AppContext);
//   const navigate = useNavigate();

//   const openLibrary = () => {
//     dispatch({
//       type: "CONTROL",
//       payload: { name: "drawerIsOpen", state: true },
//     });
//     dispatch({
//       type: "CONTROL",
//       payload: { name: "libraryOpen", state: true },
//     });
//     dispatch({ type: "CONTROL", payload: { name: "decksOpen", state: false } });
//     dispatch({
//       type: "CONTROL",
//       payload: { name: "sharedDecksOpen", state: false },
//     });
//     navigate(`/library/${state.library.activeAlbumRef.id}`);
//   };

//   const addStyle = {
//     left: "50%",
//     top: "72%",
//     transform: "translate(-50%, -50%)",
//     "&.MuiButton-contained": {
//       color: "white",
//       backgroundColor: "rgb(255,255,255,0.1)",
//       boxShadow: "3px 3px 2px",
//     },
//     "&.MuiButton-root": {
//       border: "2px white solid",
//     },
//     borderRadius: "10%",
//     "&:hover": {
//       backgroundColor: "rgb(255,255,255,0.3)",
//     },
//   };

//   return (
//     <OverlayButton
//       icon={<PhotoLibraryOutlinedIcon />}
//       addStyle={addStyle}
//       clickAction={openLibrary}
//       buttonSize={size}
//       tooltip="Add image(s) from Library"
//       buttonVariant="contained"
//     />
//   );
// }

function Gallery({ view }) {
  const { state, dispatch } = useContext(AppContext);
  const { isAdmin } = useContext(AuthContext);
  const { deckID } = useParams();

  // Add controls for this to give the user the choice of card size
  const [cardScaleFactor, setCardScaleFactor] = useState(1);

  const [cartWarning, setCartWarning] = useState();

  const [galleryDimensions, setGalleryDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [cardDimensions, setCardDimensions] = useState({
    long: 0,
    short: 0,
  });

  useEffect(() => {
    if (state.app.isMobile) return;
    if (view === "library") {
      setCardScaleFactor(1.3);
    } else {
      setCardScaleFactor(1);
    }
  }, [state.app.isMobile, view]);

  // Load album from query parameter
  useEffect(() => {
    // Only allow admin to load deck from params
    if (!isAdmin) return;

    // Only try to load the deck if the decKID is present
    if (!deckID) return;

    const setDeckRef = async () => {
      const deckQuery = query(
        collectionGroup(db, "decks"),
        where("deckID", "==", deckID),
        limit(1)
      );

      const deckRef = await getDocs(deckQuery)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].ref;
          } else {
            dispatch({
              type: "ADD_ALERT",
              payload: {
                alert: {
                  type: "error",
                  message: `There is no deck with the id [${deckID}]`,
                },
              },
            });
            console.error(
              `Error displaying gallery. There is no deck with the id [${deckID}]`
            );
            return null;
          }
        })
        .catch((err) => {
          dispatch({
            type: "ADD_ALERT",
            payload: {
              alert: {
                type: "error",
                message: `Error displaying gallery. Please try again or contact support.`,
              },
            },
          });
          console.error(`Error displaying gallery for id [${deckID}]`, err);
          return null;
        });

      if (!!deckRef)
        dispatch({
          type: "UPDATE_ACTIVE_DECK_REF",
          payload: { activeDeckRef: deckRef },
        });
    };

    setDeckRef();
  }, [isAdmin, deckID, dispatch]);

  // Add event listener to catch window resize and trigger card resize
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      if (!state.app.isMobile && state.app.controls.drawerIsOpen)
        width = width - DRAWER_WIDTH_PX;
      setGalleryDimensions({
        height,
        width,
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [state.app.controls.drawerIsOpen, state.app.isMobile]);

  useEffect(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    if (!state.app.isMobile && state.app.controls.drawerIsOpen)
      width = width - DRAWER_WIDTH_PX;
    setGalleryDimensions({
      height,
      width,
    });
  }, [state.app.controls.drawerIsOpen, state.app.isMobile]);

  useEffect(() => {
    // The long dimension of the cards in pixels
    let cardLongPX;

    // Set card size based on the platform
    if (state.app.isMobile) {
      const cardsPerRow = state.userData.zoomOnMobile
        ? MOBILE_CARDS_PER_ROW_ZOOM
        : MOBILE_CARDS_PER_ROW;

      // On mobile, fit the card to the width in landscape orientation
      cardLongPX =
        (galleryDimensions.width - CARD_DISPLAY_MARGIN_PX) /
        cardsPerRow /
        CARD_ASPECT_RATIO;

      // const shortDimension =
      //   (galleryDimensions.width * MOBILE_CARD_DISPLAY_RATIO) / 2.5;
      // cardLongPX = Math.round(shortDimension / CARD_ASPECT_RATIO);
    } else {
      // In desktop, fit six portrait cards along one row
      // Width of the screen divided by 6 plus a factor for the margins, converted to short dimension with aspect ratio
      cardLongPX =
        (galleryDimensions.width - CARD_DISPLAY_MARGIN_PX) /
        CARDS_PER_ROW /
        CARD_ASPECT_RATIO;
      // But don't let it get too small on small resolutions or strangely sized screens
      cardLongPX = Math.max(cardLongPX, MIN_CARD_LONG_DIM_PX);
    }

    cardLongPX *= cardScaleFactor;

    const cardShortPX = Math.round(cardLongPX * CARD_ASPECT_RATIO);
    cardLongPX = Math.round(cardLongPX);

    const borderRadiusPX = cardLongPX * BORDER_RADIUS_RATIO;

    setCardDimensions({
      longPX: cardLongPX,
      shortPX: cardShortPX,
      borderRadiusPX,
    });
  }, [
    galleryDimensions,
    state.app.isMobile,
    cardScaleFactor,
    state.userData.zoomOnMobile,
  ]);

  const [cropCard, setCropCard] = useState();
  const [activeCardSet, setActiveCardSet] = useState(
    view === "deck" ? state.decks.activeDeck : state.library.activeAlbum
  );
  const [cardList, setCardList] = useState(undefined);

  // Save and update the state for the active card set and card list
  // When we change to a new deck that is empty, open the Help modal
  // In all other cases, close the Help modal
  useEffect(() => {
    if (!state.decks.activeDeck.ref) return;

    const newActiveCardSet =
      view === "deck" ? state.decks.activeDeck : state.library.activeAlbum;

    // Check if this is a change in the current deck
    // If so, we may need to open the Help modal
    if (
      (!activeCardSet.ref && newActiveCardSet.ref) ||
      (newActiveCardSet.ref &&
        activeCardSet.ref &&
        newActiveCardSet.ref.id !== activeCardSet.ref.id)
    ) {
      if (
        view !== "library" &&
        state.decks.deckList.length === 1 &&
        state.decks.activeDeck &&
        state.decks.activeDeck.isEmpty
      ) {
        dispatch({ type: "MODAL", payload: { type: "start" } });
        // Skip opening the nav drawer if we are on a mobile
      }
    }

    // Be sure the Help modal is closed in any other cases
    if (
      view === "library" ||
      !state.decks.activeDeck.ref ||
      (state.decks.activeDeck && !state.decks.activeDeck.isEmpty)
    ) {
      dispatch({ type: "MODAL", payload: {} });
    }

    // Update the activeCardSet and cardList state
    setActiveCardSet(newActiveCardSet);
    setCardList(
      view === "deck"
        ? state.decks.activeCardList
        : state.library.activeAlbumCardList
    );
  }, [
    view,
    state.decks.activeDeck,
    state.decks.deckList.length,
    state.library.activeAlbum,
    state.decks.activeCardList,
    state.library.activeAlbumCardList,
    activeCardSet,
    dispatch,
  ]);

  // DEPRECATED - no longer using placeholder cards to show a full 12-card deck
  // useEffect(() => {
  //   if (view === "library") {
  //     setPlaceholderCards([]);
  //     return;
  //   }
  //   let orientation = "vertical";
  //   // THere is only 1 placeholder card
  //   let placeholderCount = 1;
  //   // let placeholderCount = BASE_DECK_SIZE;
  //   // if (cardList) {
  //   //   placeholderCount = BASE_DECK_SIZE - cardList.length;
  //   // }
  //   // if (cardList) {
  //   //   cardList.forEach((card) => {
  //   //     if (card.orientation === "horizontal") orientation = "horizontal";
  //   //   });
  //   // }
  //   const arr = [];
  //   for (let i = 0; i < placeholderCount; i++) {
  //     arr.push(
  //       <PlaceholderCard
  //         key={i}
  //         cardDimensions={cardDimensions}
  //         orientation={orientation}
  //       />
  //     );
  //   }
  //   setPlaceholderCards(arr);
  // }, [cardList, cardDimensions, view]);

  // Warn the user if they have a version of this deck in their cart
  useEffect(() => {
    // Skip if userData or activeDeck are not yet loaded
    if (!state.userData.cart) return;
    if (!state.userData.cart.items) return;
    if (!state.decks.activeDeckRef) return;
    if (state.app.controls.orderIsProcessing) return;
    // Skip if we have already warned about this deck
    if (cartWarning === state.decks.activeDeckRef.id) return;
    // If the cart warning is from another deck, remove it
    if (cartWarning) setCartWarning(null);

    // Build an array of the decks in the cart and check against the active deck
    const decksInCart = Object.keys(state.userData.cart.items).map(
      (key) => state.userData.cart.items[key].deckID
    );
    if (
      cartWarning !== state.decks.activeDeckRef.id &&
      decksInCart.includes(state.decks.activeDeckRef.id)
    ) {
      setCartWarning(state.decks.activeDeckRef.id);
      dispatch({
        type: "ADD_ALERT",
        payload: {
          alert: {
            type: "warning",
            message:
              "Changes you make to this deck will not update the item in your shopping cart.",
          },
        },
      });
    }
  }, [
    state.userData.cart,
    state.decks.activeDeckRef,
    state.app.controls.orderIsProcessing,
    cartWarning,
    dispatch,
  ]);

  const onDropRejected = useCallback(
    (rejectedFiles) => {
      console.log(rejectedFiles);
      const fileNames = rejectedFiles.map((obj) => obj.file.name);
      console.log(fileNames);
      if (rejectedFiles.length === 1) {
        dispatch({
          type: "ADD_ALERT",
          payload: {
            alert: {
              type: "error",
              message: `Error: File [${fileNames[0]}] is not a recognized image.`,
            },
          },
        });
      } else {
        dispatch({
          type: "ADD_ALERT",
          payload: {
            alert: {
              type: "error",
              message: `Error: Files [${fileNames.join(
                ", "
              )}] are not recognized images.`,
            },
          },
        });
      }
    },
    [dispatch]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      processImages(acceptedFiles, activeCardSet.ref, dispatch);
    },
    [dispatch, activeCardSet.ref]
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: true,
    noClick: true,
    accept: { "image/*": [] },
  });

  // Handle the card flipping when back design changes
  // keep track of the timeout used to stagger flips so we can clear it to stop the process
  const [flipTimeout, setFlipTimeout] = useState(null);
  // track the index of the cards to stagger the flipping
  const [flipIndex, setFlipIndex] = useState(0);
  // const [showHelpModal, setShowHelpModal] = useState(false);

  // Manage the file upload component being opened from elsewhere
  // Open it when
  // - state.app.controls.triggerUpload is true and we are in a deck
  // Close any other open modals
  useEffect(() => {
    if (view === "deck" && state.app.controls.triggerUpload) {
      // Close any open modals
      dispatch({ type: "MODAL", payload: {} });

      // Reset the trigger to false
      dispatch({
        type: "CONTROL",
        payload: { name: "triggerUpload", state: false },
      });

      // Open the file upload component
      open();
    }
  });

  // Initiate card flip sequence
  useEffect(() => {
    // Stagger the card flips by incrementing the flipIndex every flipDelayms
    // Hold the timeout in local state so we can clear it
    const staggerFlip = (index) => {
      setFlipIndex(index);
      index++;
      if (index <= cardList.length) {
        setFlipTimeout(setTimeout(() => staggerFlip(index), flipDelayms));
        return;
      }
      setFlipTimeout(setTimeout(() => setFlipIndex(0), flipDelayms + 1700));
    };

    // Initiate card flip sequence
    const startFlip = () => {
      // clear the flip timeout in case there is a flip sequence currently happening
      flipTimeout && clearTimeout(flipTimeout);
      setFlipTimeout(null);
      staggerFlip(1); // start the staggered flip sequence with 0 to be sure all cards get reset
    };

    // Once the back design is first chosen and any time it changes, start the flip sequence
    if (
      activeCardSet &&
      activeCardSet.backImage &&
      state.app.controls.flip &&
      flipIndex === 0
    ) {
      dispatch({ type: "RESET_FLIP" });
      startFlip();
    }
  }, [
    cardList,
    flipIndex,
    dispatch,
    activeCardSet,
    flipTimeout,
    state.app.controls.flip,
  ]);

  const cropCardRef = state.app.modal.data
    ? state.app.modal.data.cardRef
    : null;

  useEffect(() => {
    if (state.app.modal.type === "crop" && cropCardRef) {
      const newCropCard = cardList.filter(
        (elt) => elt.ref.id === cropCardRef.id
      )[0];
      setCropCard(newCropCard);
    } else {
      setCropCard(null);
    }
  }, [state.app.modal.type, cardList, cropCardRef]);

  if (view !== "welcome" && (state.app.isLoading || !activeCardSet)) {
    return (
      <div
        style={{
          width: `${galleryDimensions.width}px`,
          ...(state.app.controls.drawerIsOpen || state.app.isMobile
            ? styles.rootShift
            : styles.root),
        }}
      >
        <Loading />
      </div>
    );
  }

  const overlayButtonSize = Math.max(
    Math.round(cardDimensions.longPX * 0.12),
    MIN_OVERLAY_BUTTON_SIZE_PX
  );
  // const newcardOverlayButtonSize = Math.round(cardDimensions.longPX * 0.35);

  const overlayButtons = (card) => {
    if (view === "deck") {
      return [
        <DeleteButton
          key={"delete"}
          cardRef={card.ref}
          size={overlayButtonSize}
        />,
        <CropButton key={"crop"} cardRef={card.ref} size={overlayButtonSize} />,
      ];
    } else if (view === "library" && isAdmin) {
      return [
        <DeleteButton
          key={"delete"}
          cardRef={card.ref}
          size={overlayButtonSize}
        />,
        <CropButton key={"crop"} cardRef={card.ref} size={overlayButtonSize} />,
        <AddToDeckButton
          key={"add-to-deck"}
          sourceCard={card}
          size={overlayButtonSize}
        />,
      ];
    } else {
      return [
        <AddToDeckButton
          key={"add-to-deck"}
          sourceCard={card}
          size={overlayButtonSize}
        />,
      ];
    }
  };

  // Action buttons that go on top of the card for the add cards UI
  // const newcardOverlayButtons = () => {
  //   return [
  //     <UploadButton
  //       key={"upload"}
  //       size={newcardOverlayButtonSize}
  //       clickAction={open}
  //     />,
  //     <LibraryButton key={"library"} size={newcardOverlayButtonSize} />,
  //   ];
  // };

  return (
    <div
      style={{
        width: `${galleryDimensions.width}px`,
        ...(state.app.controls.drawerIsOpen || state.app.isMobile
          ? styles.rootShift
          : styles.root),
      }}
    >
      {state.app.modal.type !== null && <AppModals />}
      <div
        style={
          (cardList && cardList.length === 0) || view === "welcome"
            ? styles.emptyGallery
            : styles.gallery
        }
        onPaste={
          view === "deck" || isAdmin
            ? handlePaste(dispatch, activeCardSet.ref)
            : undefined
        }
        {...((view === "deck" || isAdmin) && getRootProps())}
      >
        <input {...getInputProps()} />
        {(state.app.modal.type === "help" ||
          state.app.modal.type === "start") && (
          <ModalHelp
            view={state.app.modal.type}
            handleClose={() => dispatch({ type: "MODAL", payload: {} })}
          />
        )}
        {state.app.modal.type === "crop" && cropCard && (
          <ModalCrop card={cropCard} />
        )}
        {/* Add special card at the start as an interaction point for adding to deck */}
        {/* Pass in overlay buttons as children */}
        {/* {(view === "welcome" || view === "deck") && (
          <Card
            key={"newcard"}
            cardDimensions={cardDimensions}
            card={NEW_CARD}
            view={view}
            flip={false}
            selected={false}
          >
            {newcardOverlayButtons(NEW_CARD)}
          </Card>
        )} */}
        {activeCardSet &&
          cardList &&
          cardList.map((card, index) => (
            <Card
              key={card.cardID}
              cardDimensions={cardDimensions}
              backImage={activeCardSet.backImage}
              card={card}
              view={view}
              flip={index < flipIndex}
              selected={
                view === "library" &&
                state.decks.albumSourceSet.has(card.cardID)
              }
            >
              {/* overlay buttons passed in as children */}
              {overlayButtons(card)}
            </Card>
          ))}
        {(view === "welcome" || view === "deck" || isAdmin) && (
          <AddFab openFileUpload={open} style={styles.addFAB} />
        )}
        {/* Focused action button for adding a new card */}
        {(view === "welcome" || view === "deck") && <BackSelect />}{" "}
        {/* button for seleting the back design */}
        {isDragActive && (
          <div style={styles.dragActive}>
            <div>
              <UploadIcon style={styles.dropIcon} />
            </div>
            <div style={styles.dropText}>
              Drop images here
              <br />
              to upload
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Gallery;
