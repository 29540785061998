import React, { useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppContext from "./app-context";
import Create from "./create";

export default function CreateDeck() {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  // Get the deckID out of the url if it exists
  const { deckID } = useParams();

  useEffect(() => {
    // if there is no current active deck in the app state, do not continue
    if (!state.decks.activeDeckRef) return;

    // if there is no deckID in the URL and there is an activeDeck in the app state
    // Set the deckID in the url to the id of the active deck
    if (!deckID || deckID !== state.decks.activeDeckRef.id) {
      navigate(`/deck/${state.decks.activeDeckRef.id}`, { replace: true });
    }
  }, [deckID, state.decks.activeDeckRef, navigate]);

  return <Create view="deck" />;
}
