import React, { useContext, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import firebaseApp from "../utils/firebase";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  sendPasswordResetEmail,
  getRedirectResult,
  signInWithPopup,
  signInWithRedirect,
  linkWithCredential,
  linkWithRedirect,
  linkWithPopup,
  sendEmailVerification,
  unlink,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { AuthContext } from "./auth-provider";
import Button from "@mui/material/Button";

import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import theme from "../styles/theme";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AppContext from "./app-context";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import assets from "../utils/assets";

const DEFAULT_SIGN_IN_REDIRECT = "/deck";

const classes = {
  root: {
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0)",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRoot: {
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0)",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2vh",
  },
  headerLogo: {
    height: "auto",
    width: "50px",
    // margin: 'auto'
  },
  headerBox: {
    flexBasis: "10 10 content",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px",
  },
  // welcomeBox: {
  //   display: "flex",
  //   flexFlow: "column nowrap",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   background: "#f5f5f5",
  //   borderRadius: "3px",
  //   boxShadow: "0 0.5rem 1rem #808080",
  //   width: "95vw",
  //   maxWidth: "350px",
  //   paddingTop: "20px",
  //   // maxHeight: '600px',
  // },
  signInBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    height: "575px",
    maxWidth: "350px",
    // maxHeight: '600px',
  },
  linkBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    maxWidth: "350px",
    paddingTop: "25px",
    // maxHeight: '600px',
  },
  forgotPasswordBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    height: "350px",
    maxWidth: "350px",
  },
  resetPasswordBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95%",
    height: "250px",
    maxWidth: "350px",
  },
  socialButtons: {
    textTransform: "none",
    color: "black",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "16px",
    width: "250px",
    height: "42px",
    margin: "5px",
    border: "2px solid #A9A9A9",
  },
  form: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "16px",
  },
  inputs: {
    width: "250px",
    margin: "5px",
  },
  submitButton: {
    width: "250px",
    margin: "10px 0",
  },
  accountButton: {
    width: "250px",
    margin: "0px",
    color: "#606060",
  },
  dividerFull: {
    width: "100%",
    height: "1px",
    color: "#A9A9A9",
    margin: "0px",
  },
  dividerText: {
    width: "20%",
    height: "1px",
    color: "#A9A9A9",
  },
  dividerContainer: {
    width: "30%",
    margin: "0px 10px",
  },
  or: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "12px",
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0px",
  },
  signInPrompt: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "16px",
    color: "#707070",
    textAlign: "center",
    padding: "20px 70px 0px 70px",
  },
  accountPrompt: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: "#A9A9A9",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "center",
    // padding: '10px',
  },
  keepMeSignedIn: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
  },
  privacyPrompt: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: "#A9A9A9",
    textAlign: "center",
    padding: "5px",
  },
  Link: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: theme.palette.primary.main,
    textTransform: "none",
    marginLeft: "4px",
  },
  ppLink: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: "#808080",
    textTransform: "none",
    marginLeft: "4px",
  },
  footer: {
    width: "100%",
    marginTop: "10px",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  headingSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "left",
    color: "gray",
    padding: "0px 50px 0px 50px",
    marginTop: "15px",
  },
};

const getProviders = (user) => {
  const providers = new Set();
  if (!user) {
    return providers;
  }
  user.providerData.forEach((provider) => {
    providers.add(provider.providerId);
  });
  return providers;
};

export default function AuthBox({ version, inApp }) {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const location = useLocation();

  const [pageType, setPageType] = useState(version);
  const [keepSignedIn, setKeepSignedIn] = useState(true);
  // const [showIntro, setShowIntro] = useState(true);

  const auth = getAuth(firebaseApp);

  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const emailProvider = new EmailAuthProvider();

  const [linkedProviders, setLinkedProviders] = useState(
    getProviders(currentUser)
  );

  // Deal with unsuccessful sign in, such as sign in provider collision
  useEffect(() => {
    auth &&
      getRedirectResult(auth).catch((error) => {
        alert(error.message);
        Sentry.captureMessage(error, "info");
      });
  }, [auth]);

  // When keepSignedIn is false, set auth state persistence to session instead of local
  useEffect(() => {
    if (!keepSignedIn) {
      setPersistence(auth, browserSessionPersistence);
    } else {
      setPersistence(auth, browserLocalPersistence);
    }
  }, [auth, keepSignedIn]);

  // If redirected to sign in, set the path you came from as the redirect path after sign in completes
  if (location.state && location.state.from) {
    const redirectTo = `${location.state.from.pathname}${location.state.from.search}`;
    sessionStorage.setItem("afterSignInRedirect", redirectTo);
  }

  const gotoSignUp = (evt) => {
    evt.preventDefault();
    setPageType("signUp");
    navigate("/signup");
  };

  const gotoSignIn = (evt) => {
    evt.preventDefault();
    setPageType("signIn");
    navigate("/signin");
  };

  const gotoForgotPassword = (evt) => {
    evt.preventDefault();
    setPageType("forgotPassword");
  };

  const resetPassword = (evt) => {
    evt.preventDefault();
    const { email } = evt.target.elements;
    sendPasswordResetEmail(auth, email.value)
      .then(function () {
        setPageType("passwordReset");
      })
      .catch(function (error) {
        alert(error.message);
      });
  };

  const signInIdp = (provider) => () => {
    signInWithPopup(auth, provider).catch((error) => {
      Sentry.captureMessage(error, "info");
      process.env.REACT_APP_ENV === "dev" &&
        console.log(`signInWithPopup error: ${error}`);
    });
  };

  const linkGooglePhotos = (provider) => () => {
    provider.addScope("https://www.googleapis.com/auth/photoslibrary.readonly");
    provider.setCustomParameters({
      login_hint: currentUser.email,
    });
    state.app.isMobile && signInWithRedirect(auth, provider);
    signInWithPopup(auth, provider).then((result) => {
      // what to do with the access token?
      // console.log(result.credential.accessToken)
    });
  };

  const linkFacebookPhotos = (provider) => () => {
    provider.addScope("user_photos");
    state.app.isMobile && signInWithRedirect(auth, provider);
    signInWithPopup(auth, provider).then((result) => {
      // what to do with the access token?
      // console.log(result.credential.accessToken)
    });
  };

  const linkIdp = (provider) => () => {
    state.app.isMobile && linkWithRedirect(currentUser, provider);
    linkWithPopup(currentUser, provider).then(() => {
      let newLinkedProviders = getProviders(currentUser);
      setLinkedProviders(newLinkedProviders);
    });
  };

  const unlinkIdp = (provider) => () => {
    unlink(currentUser, provider.providerId).then(() => {
      let newLinkedProviders = getProviders(currentUser);
      setLinkedProviders(newLinkedProviders);
    });
  };

  const signInEmail = async (evt) => {
    evt.preventDefault();
    const { email, password } = evt.target.elements;
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
      // Redirect will automatically load when currentUser updates
    } catch (error) {
      alert(error);
    }
  };

  const signUpEmail = async (evt) => {
    evt.preventDefault();
    const { email, password } = evt.target.elements;
    try {
      await createUserWithEmailAndPassword(auth, email.value, password.value);
      sendEmailVerification(currentUser)
        .then(function () {
          // Email sent.
        })
        .catch(function (error) {
          console.log(error);
        });
      // Redirect will automatically load when currentUser updates
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        signInEmail(evt);
      } else {
        alert(error);
      }
    }
  };

  const linkEmail = async (evt) => {
    evt.preventDefault();
    const { email, password } = evt.target.elements;
    const credential = EmailAuthProvider.credential(
      email.value,
      password.value
    );
    try {
      await linkWithCredential(currentUser, credential);
      sendEmailVerification(currentUser)
        .then(function () {
          // Email sent.
        })
        .catch(function (error) {
          console.log(error);
        });
      let newLinkedProviders = getProviders(currentUser);
      setLinkedProviders(newLinkedProviders);
    } catch (error) {
      alert(error);
    }
  };

  const handleKeepSignInChange = () => {
    setKeepSignedIn(!keepSignedIn);
  };

  const privacyPolicy = () => {
    window.open("https://moments.cards/privacy-policy");
  };

  // const welcomeSignUp = () => {
  //   setShowIntro(false);
  //   setPageType("signUp");
  // };

  // const welcomeSignIn = () => {
  //   setShowIntro(false);
  //   setPageType("signIn");
  // };

  const rootClass = state.app.isMobile ? classes.mobileRoot : classes.root;

  if (pageType === "linkPhotos") {
    return (
      <div style={rootClass}>
        <div style={classes.linkBox}>
          <div style={classes.headerBox}>
            <img
              style={classes.headerLogo}
              alt="logo"
              src={assets["logo.png"]}
            />
          </div>

          <Button
            style={classes.socialButtons}
            onClick={linkGooglePhotos(googleProvider)}
            startIcon={
              <img
                src={assets["g_logo.png"]}
                height="20px"
                width="20px"
                alt="f logo"
              />
            }
          >
            Connect Google Photos
          </Button>
          <Button
            style={classes.socialButtons}
            onClick={linkFacebookPhotos(facebookProvider)}
            startIcon={
              <img
                src={assets["f_logo.png"]}
                height="20px"
                width="20px"
                alt="g logo"
              />
            }
          >
            Connect Facebook Photos
          </Button>

          <div style={classes.footer}>
            <hr style={classes.dividerFull} />

            <div style={classes.accountPrompt}>
              Don't want to connect to your photos?
              <Link
                style={classes.Link}
                onClick={gotoSignIn}
                href="#"
                underline="hover"
              >
                Go back
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (pageType === "linkAccounts") {
    return (
      <div style={rootClass}>
        <div style={classes.linkBox}>
          <div style={classes.headerBox}>
            <img
              style={classes.headerLogo}
              alt="logo"
              src={assets["logo.png"]}
            />
          </div>
          {linkedProviders.has(googleProvider.providerId) && (
            <Button
              style={classes.socialButtons}
              onClick={unlinkIdp(googleProvider)}
              startIcon={
                <img
                  src={assets["g_logo.png"]}
                  height="20px"
                  width="20px"
                  alt="f logo"
                />
              }
            >
              Unlink Google
            </Button>
          )}
          {!linkedProviders.has(googleProvider.providerId) && (
            <Button
              style={classes.socialButtons}
              onClick={linkIdp(googleProvider)}
              startIcon={
                <img
                  src={assets["g_logo.png"]}
                  height="20px"
                  width="20px"
                  alt="f logo"
                />
              }
            >
              Link Google
            </Button>
          )}

          {linkedProviders.has(facebookProvider.providerId) && (
            <Button
              style={classes.socialButtons}
              onClick={unlinkIdp(facebookProvider)}
              startIcon={
                <img
                  src={assets["f_logo.png"]}
                  height="20px"
                  width="20px"
                  alt="g logo"
                />
              }
            >
              Unlink Facebook
            </Button>
          )}
          {!linkedProviders.has(facebookProvider.providerId) && (
            <Button
              style={classes.socialButtons}
              onClick={linkIdp(facebookProvider)}
              startIcon={
                <img
                  src={assets["f_logo.png"]}
                  height="20px"
                  width="20px"
                  alt="g logo"
                />
              }
            >
              Link Facebook
            </Button>
          )}

          <div style={classes.or}>
            <div style={classes.dividerContainer}>
              <hr style={classes.dividerFull} />
            </div>
            OR
            <div style={classes.dividerContainer}>
              <hr style={classes.dividerFull} />
            </div>
          </div>

          {linkedProviders.has(emailProvider.providerId) && (
            <Button
              style={classes.socialButtons}
              onClick={unlinkIdp(emailProvider)}
              startIcon={<EmailIcon />}
            >
              Unlink Email/Password
            </Button>
          )}

          {!linkedProviders.has(emailProvider.providerId) && (
            <form style={classes.form} onSubmit={linkEmail}>
              Add email and password sign in
              <TextField
                style={classes.inputs}
                required
                id="email"
                type="email"
                label="Email"
                variant="outlined"
              />
              <TextField
                style={classes.inputs}
                id="password"
                label="Password"
                type="password"
                variant="outlined"
              />
              <Button
                style={classes.submitButton}
                type="submit"
                variant="contained"
                color="primary"
              >
                Continue
              </Button>
            </form>
          )}

          <div style={classes.footer}>
            <hr style={classes.dividerFull} />

            <div style={classes.accountPrompt}>
              Don't want to link an account?
              <Link
                style={classes.Link}
                onClick={gotoSignIn}
                href="#"
                underline="hover"
              >
                Go back
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If you came to the sign in page while logged in either
  // 1. You were redirected after logging in with an identity provider
  // 2. you navigated here accidentally
  // 3. you were redirected here when logging out and the logout hasn't completed yet
  if (currentUser && !inApp) {
    if (location.state && location.state.logout) {
      // If we came from logging out, reset the redirect path
      sessionStorage.removeItem("afterSignInRedirect");
    } else {
      // Otherwise, get the redirect path and redirect there or redirect to the default path
      const redirectTo =
        sessionStorage.getItem("afterSignInRedirect") ||
        DEFAULT_SIGN_IN_REDIRECT;
      sessionStorage.removeItem("afterSignInRedirect");
      return <Navigate to={redirectTo} />;
    }
  }

  if (pageType === "forgotPassword") {
    return (
      <div style={rootClass}>
        <div style={classes.forgotPasswordBox}>
          <div style={classes.headerBox}>
            <img
              style={classes.headerLogo}
              alt="logo"
              src={assets["logo.png"]}
            />
          </div>

          <form style={classes.form} onSubmit={resetPassword}>
            Enter your email
            <TextField
              style={classes.inputs}
              required
              id="email"
              type="email"
              label="Email"
              variant="outlined"
            />
            <Button
              style={classes.submitButton}
              type="submit"
              variant="contained"
              color="primary"
            >
              Reset Password
            </Button>
          </form>

          <div style={classes.footer}>
            <hr style={classes.dividerFull} />

            <div style={classes.accountPrompt}>
              Remembered your password?
              <Link
                style={classes.Link}
                onClick={gotoSignIn}
                href="#"
                underline="hover"
              >
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (pageType === "passwordReset") {
    return (
      <div style={rootClass}>
        <div style={classes.resetPasswordBox}>
          <div style={classes.headerBox}>
            <img
              style={classes.headerLogo}
              alt="logo"
              src={assets["logo.png"]}
            />
          </div>

          <form style={classes.form} onSubmit={gotoSignIn}>
            Email sent. Check your inbox.
            <Button
              style={classes.submitButton}
              type="submit"
              variant="contained"
              color="primary"
            >
              Go to Sign In
            </Button>
          </form>
        </div>
      </div>
    );
  }

  // if (showIntro) {
  //   return (
  //     <div style={rootClass}>
  //       <div style={classes.welcomeBox}>
  //         <div style={classes.headerBox}>
  //           <div style={classes.headingBig}>
  //             <Typography variant="inherit">The boring stuff...</Typography>
  //           </div>
  //           <div style={classes.headingSmall}>
  //             <Typography variant="inherit">
  //               Collecting meaningful images is an uplifting part of this
  //               process. We know you want to get going on that.
  //             </Typography>
  //           </div>

  //           <div style={classes.headingSmall}>
  //             <Typography variant="inherit">
  //               In order to keep your images safe and save your work, we have to
  //               ask you to sign in or create an account.
  //             </Typography>
  //           </div>
  //         </div>

  //         <Button
  //           style={classes.submitButton}
  //           onClick={welcomeSignUp}
  //           variant="contained"
  //           color="primary"
  //         >
  //           Sign up
  //         </Button>
  //         <Button
  //           style={classes.submitButton}
  //           onClick={welcomeSignIn}
  //           variant="contained"
  //           color="primary"
  //         >
  //           I have an account
  //         </Button>

  //         <div style={classes.footer}>
  //           <hr style={classes.dividerFull} />

  //           <div style={classes.privacyPrompt}>
  //             To learn more, check out our
  //             <Link
  //               style={classes.ppLink}
  //               onClick={privacyPolicy}
  //               href="#"
  //               underline="hover"
  //             >
  //               Privacy Policy
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div style={rootClass}>
      <div style={classes.signInBox}>
        <div style={classes.headerBox}>
          <img style={classes.headerLogo} alt="logo" src={assets["logo.png"]} />
          {/* <div style={classes.signInPrompt}>
            {pageType === "signIn" &&
              "Please sign in so we can keep your images safe."}
            {pageType === "signUp" &&
              "Please sign up so we can keep your images safe."}
          </div> */}
        </div>
        <Button
          style={classes.socialButtons}
          onClick={signInIdp(googleProvider)}
          startIcon={
            <img
              src={assets["g_logo.png"]}
              height="20px"
              width="20px"
              alt="f logo"
            />
          }
        >
          Continue with Google
        </Button>
        {/* <Button style={classes.socialButtons} onClick={signInIdp(facebookProvider)} startIcon={<img src={assets["f_logo.png"]} height="20px" width="20px" alt="g logo" />}>Continue with Facebook</Button> */}

        <div style={classes.or}>
          <div style={classes.dividerContainer}>
            <hr style={classes.dividerFull} />
          </div>
          OR
          <div style={classes.dividerContainer}>
            <hr style={classes.dividerFull} />
          </div>
        </div>

        <form
          style={classes.form}
          onSubmit={pageType === "signIn" ? signInEmail : signUpEmail}
        >
          {pageType === "signIn" && "Sign in to create your cards"}
          {pageType === "signUp" && "Sign up to create your cards"}
          <TextField
            style={classes.inputs}
            id="email"
            label="Email"
            variant="outlined"
          />
          <TextField
            style={classes.inputs}
            id="password"
            label="Password"
            type="password"
            variant="outlined"
          />
          {pageType === "signIn" && (
            <Link
              style={classes.Link}
              onClick={gotoForgotPassword}
              href="#"
              underline="hover"
            >
              Forgot password?
            </Link>
          )}
          <Button
            style={classes.submitButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            {pageType === "signIn" && "Sign In"}
            {pageType === "signUp" && "Sign Up"}
          </Button>
        </form>

        <div style={classes.accountPrompt}>
          {pageType === "signIn" && (
            <Button
              style={classes.accountButton}
              onClick={gotoSignUp}
              variant="outlined"
            >
              Create Account
            </Button>
          )}
          {pageType === "signUp" && (
            <Button
              style={classes.accountButton}
              onClick={gotoSignIn}
              variant="outlined"
            >
              Have an Account?
            </Button>
          )}
        </div>

        <div style={classes.keepMeSignedIn}>
          <FormControlLabel
            control={
              <Checkbox
                checked={keepSignedIn}
                onChange={handleKeepSignInChange}
              />
            }
            label="Keep me signed in."
          />
          <Tooltip
            title="By choosing 'Keep me signed in', you'll be asked to sign in less frequently on this device.
For security, uncheck this option if using a public device."
          >
            <IconButton aria-label="info" variant="outlined" size="large">
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>

        {/* <div style={classes.accountPrompt}>
          {pageType === "signIn" && "Don't have an account?"}
          {pageType === "signIn" && (
            <Link
              style={classes.Link}
              onClick={gotoSignUp}
              href="#"
              underline="hover"
            >
              Sign Up
            </Link>
          )}
          {pageType === "signUp" && "Already have an account?"}
          {pageType === "signUp" && (
            <Link
              style={classes.Link}
              onClick={gotoSignIn}
              href="#"
              underline="hover"
            >
              Sign in
            </Link>
          )}
        </div> */}

        <div style={classes.footer}>
          <hr style={classes.dividerFull} />

          <div style={classes.privacyPrompt}>
            By continuing you confirm that you have read the Moments Cards
            <Link
              style={classes.ppLink}
              onClick={privacyPolicy}
              href="#"
              underline="hover"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
