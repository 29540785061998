import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import firebaseApp from "../utils/firebase";
import { getAuth, onAuthStateChanged, getIdTokenResult } from "firebase/auth";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [isAdmin, setIsAdmin] = useState(undefined);

  useEffect(() => {
    let unSubscribe = () => {};

    const auth = getAuth(firebaseApp);
    // Callback is async so we can await the check for admin before setting pending to false
    unSubscribe = onAuthStateChanged(auth, (user) => {
      try {
        setCurrentUser(user);
        setPending(false);
        // process.env.REACT_APP_ENV && console.log({ user });
      } catch (err) {
        process.env.REACT_APP_ENV !== "prod" &&
          console.error("Error updating user state from onAuthStateChanged.");
        process.env.REACT_APP_ENV !== "prod" && console.error(err);
        Sentry.captureMessage(err);
      }
    });

    return unSubscribe;
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    const checkAdmin = (user) => {
      // Be sure we have a user before checking for admin
      if (user) {
        // await this admin check and setting isAdmin before moving on
        // so that everythign is done before we set pending to false
        getIdTokenResult(user)
          .then((idTokenResult) => {
            setIsAdmin(!!idTokenResult.claims.admin);
          })
          .then(() => {
            setPending(false);
          })
          .catch((err) => {
            process.env.REACT_APP_ENV !== "prod" &&
              console.error("Error checking admin role.");
            process.env.REACT_APP_ENV !== "prod" && console.error(err);
            Sentry.captureMessage(err);
          });
      }
    };

    checkAdmin(currentUser);

    return;
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAdmin,
        pending,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
