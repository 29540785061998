import React, { useContext } from "react";
import { Alert, AlertTitle } from "@mui/material";

import AppContext from "./app-context";

const styles = {
  alert: {
    height: "35px",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    pointerEvents: "none",
    padding: 0,
    "& .MuiAlert-message": { overflow: "hidden" },
  },
};

export default function LibraryNotify() {
  const { state } = useContext(AppContext);

  const albumName = state.library.activeAlbum.name;

  return (
    <Alert
      key={"alert"}
      icon={false}
      severity="warning"
      variant="filled"
      sx={styles.alert}
    >
      <AlertTitle>Album: {albumName && albumName.toUpperCase()}</AlertTitle>
    </Alert>
  );
}
