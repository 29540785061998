import React, { useContext, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { AuthContext } from "./auth-provider";
import Button from "@mui/material/Button";

import theme from "../styles/theme";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AppContext from "./app-context";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OrderRow from "./order-row";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

// Firebase
import firebaseApp from "../utils/firebase";
import {
  collectionGroup,
  serverTimestamp,
  getFirestore,
  getDocs,
  updateDoc,
  query,
  onSnapshot,
  collection,
  where,
  limit,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRoot: {
    width: "100vw",
    height: "150vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2vh",
  },
  headerLogo: {
    height: "auto",
    width: "180px",
    // margin: 'auto'
  },
  headerBox: {
    // flexBasis: '10 10 content',
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    marginBottom: "20px",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: "10px",
  },
  headingSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  orderCode: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "center",
    color: theme.palette.primary.main,
    paddingBottom: "20px",
  },
  mainBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "6px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    minHeight: "400px",
    maxWidth: "350px",
    // height: '600px',
    position: "relative",
    padding: "10px",
  },
  orderSuccessBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  orderBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  shippingBox: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
  },
  shipCityStateZip: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
  },
  button: {
    width: "95%",
    marginTop: "15px",
    height: "42px",
  },
  dividerFull: {
    width: "100%",
    height: "1px",
    color: "#A9A9A9",
    margin: "0px",
  },
  dividerContainer: {
    width: "100%",
    margin: "10px 10px",
  },
  bottomPrompt: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: "#A9A9A9",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "center",
    // height: '100%',
    // padding: '10px',
  },
  Link: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: theme.palette.primary.main,
    textTransform: "none",
    marginLeft: "4px",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  bottomSection: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  checkoutSpinner: {
    color: "#FFFFFF",
  },
  giftBox: {
    width: "80%",
    marginTop: "10px",
  },
  giftTitleRow: {
    color: theme.palette.primary.main,
    display: "flex",
    flowFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "5px",
    fontWeight: 700,
    fontSize: "1.2em",
  },
  giftTitleIcon: {
    marginRight: "3px",
  },
  giftMessageRow: {
    width: "100%",
    whiteSpace: "pre-line",
    border: `solid 1px ${theme.palette.primary.main}`,
    padding: "5px",
    maxHeight: "105px",
    overflow: "scroll",
  },
  giftMessageText: {
    width: "100%",
  },
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function OrderDetail() {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(AppContext);
  const { isAdmin } = useContext(AuthContext);

  const urlQueryParams = useQuery();
  const sessionID = urlQueryParams.get("session_id");

  const { code } = useParams();

  const rootClass = state.app.isMobile ? styles.mobileRoot : styles.root;
  const userRef = state.userData ? state.userData.ref : null;

  const [orderRef, setOrderRef] = useState();
  const [order, setOrder] = useState("loading");

  // Get the order from the database using the id in the URL
  useEffect(() => {
    if (!userRef) return;

    const getOrderRef = async () => {
      let thisOrderRef = null;

      if (code) {
        const thisOrderQuery = query(
          collection(userRef, "orders"),
          where("customerOrderCode", "==", code),
          limit(1)
        );
        thisOrderRef = await getDocs(thisOrderQuery)
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              return querySnapshot.docs[0].ref;
            } else {
              return null;
            }
          })
          .catch((err) => {
            dispatch({
              type: "ADD_ALERT",
              payload: {
                alert: {
                  type: "error",
                  message: `Error displaying order details. Please try again or contact support.`,
                },
              },
            });
            console.error(
              `Error displaying order detail for code [${code}]`,
              err
            );
            navigate("/deck");
            return;
          });
      }

      if (!thisOrderRef && code && isAdmin) {
        const thisOrderQuery = query(
          collectionGroup(db, "orders"),
          where("customerOrderCode", "==", code),
          limit(1)
        );
        thisOrderRef = await getDocs(thisOrderQuery)
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              return querySnapshot.docs[0].ref;
            } else {
              return null;
            }
          })
          .catch((err) => {
            dispatch({
              type: "ADD_ALERT",
              payload: {
                alert: {
                  type: "error",
                  message: `Error displaying order details. Please try again or contact support.`,
                },
              },
            });
            console.error(
              `Error displaying order detail for code [${code}].`,
              err
            );
            navigate("/deck");
            return;
          });
      }

      if (!thisOrderRef && sessionID) {
        const thisOrderQuery = query(
          collection(userRef, "orders"),
          where("stripeSessionID", "==", sessionID),
          limit(1)
        );
        thisOrderRef = await getDocs(thisOrderQuery)
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              // Clear the shopping cart
              updateDoc(userRef, {
                cart: null,
              });
              updateDoc(querySnapshot.docs[0].ref, {
                webSuccess: true,
                updated_at: serverTimestamp(),
              });
              return querySnapshot.docs[0].ref;
            } else {
              return null;
            }
          })
          .catch((err) => {
            dispatch({
              type: "ADD_ALERT",
              payload: {
                alert: {
                  type: "error",
                  message: `Error displaying order details. Please try again or contact support.`,
                },
              },
            });
            console.error(
              `Error displaying order detail for sessionID [${sessionID}]`,
              err
            );
            navigate("/deck");
            return;
          });
      }

      if (!thisOrderRef) {
        dispatch({
          type: "ADD_ALERT",
          payload: {
            alert: {
              type: "error",
              message: `There is no order with the code [${code}]`,
            },
          },
        });
        console.error(
          `Error displaying gallery. There is no order with the code [${code}]`
        );
        navigate("/deck");
        return;
      }

      // Only let the owner see this
      if (!isAdmin) {
        const ownerID = thisOrderRef.parent.parent.id;
        if (userRef.id !== ownerID) {
          dispatch({
            type: "ADD_ALERT",
            payload: {
              alert: {
                type: "error",
                message: `You are not authorized to view this order.`,
              },
            },
          });
          console.error(
            `Error displaying order detail. User is not authorized to access it.`
          );
          navigate("/deck");
          return;
        }
      }

      setOrderRef(thisOrderRef);
    };

    getOrderRef();
  }, [dispatch, navigate, sessionID, code, userRef, isAdmin]);

  useEffect(() => {
    if (!orderRef) return;

    let unSubscribe = () => {};
    try {
      unSubscribe = onSnapshot(
        orderRef,
        (doc) => {
          if (doc.exists()) {
            const newOrder = doc.data();
            setOrder(newOrder);
          }
        },
        (error) => {
          Sentry.captureException(error);
          process.env.REACT_APP_ENV === "dev" &&
            console.log(`onSnapshot error: ${error}`);
        }
      );
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      // Save the unSubscribe function to global state so we can unSubscribe all listeners
      // before logging the user out to avoid throwing permission_denied errors
      dispatch({
        type: "LISTENER_UNSUBSCRIBE",
        payload: { func: unSubscribe },
      });
    } catch (error) {
      console.log("Error querying data for order: ", error);
    } finally {
      return () => unSubscribe();
    }
  }, [dispatch, orderRef, order]);

  const handleClose = () => {
    // TODO: are you sure?
    navigate("/deck");
  };

  return (
    <div style={rootClass}>
      <div style={styles.mainBox}>
        <div>
          <IconButton
            aria-label="close"
            style={styles.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>

        {sessionID && (
          <div style={styles.orderSuccessBox}>
            <div style={styles.headingBig}>
              <Typography variant="inherit">Success!</Typography>
            </div>
            <div style={styles.headingSmall}>
              Thanks for your order. The Moments Elves will get to work right
              away making your custom deck.
            </div>
            <div style={styles.dividerContainer}>
              <hr style={styles.dividerFull} />
            </div>
          </div>
        )}

        {order && code && (
          <div style={styles.headingBig}>
            <Typography variant="inherit">Order Details</Typography>
          </div>
        )}

        {order && order.customerOrderCode && (
          <div style={styles.orderCode}>
            Order code:&nbsp;
            <Link
              component={RouterLink}
              to={`/orders/${order.customerOrderCode}`}
              underline="hover"
            >
              {order.customerOrderCode}
            </Link>
          </div>
        )}

        <div style={styles.orderBox}>
          {order === "loading" && <CircularProgress />}

          {order && order !== "loading" && (
            <>
              <OrderRow version="labels" />
              {order.items
                .filter((item) => item.type === "print")
                .map((item) => (
                  <OrderRow key={item.printID} version="order" item={item} />
                ))}
              {order.items
                .filter((item) => item.type === "shipping")
                .map((item) => (
                  <OrderRow key={item.type} item={item} />
                ))}
              {order.amountDiscount > 0 && (
                <OrderRow discount={order.amountDiscount} />
              )}
              {order.amountTax > 0 && <OrderRow tax={order.amountTax} />}
              <OrderRow totalPrice={order.amountTotal} />
            </>
          )}
        </div>

        {order && order.isGift && (
          <>
            <div style={styles.dividerContainer}>
              <hr style={styles.dividerFull} />
            </div>
            <div style={styles.giftBox}>
              <div style={styles.giftTitleRow}>
                <CardGiftcardIcon style={styles.giftTitleIcon} /> GIFT MESSAGE
              </div>

              <div style={styles.giftMessageRow}>{order.giftMessage}</div>
            </div>
          </>
        )}

        <div style={styles.dividerContainer}>
          <hr style={styles.dividerFull} />
        </div>

        {order.shipping && (
          <div style={styles.shippingBox}>
            <div style={styles.headingSmall}>
              <Typography variant="inherit">Shipping:</Typography>
            </div>
            <div style={styles.shipName}>{order.shipping.name}</div>
            {order.shipping.address.line1 && (
              <div style={styles.shipAddr}>{order.shipping.address.line1}</div>
            )}
            {order.shipping.address.line2 && (
              <div style={styles.shipAddr}>{order.shipping.address.line2}</div>
            )}
            <div style={styles.shipCityStateZip}>
              <div style={styles.shipCity}>
                {order.shipping.address.city},&nbsp;
              </div>
              <div style={styles.shipState}>
                {order.shipping.address.state}&nbsp;
              </div>
              <div style={styles.shipZip}>
                {order.shipping.address.postal_code}
              </div>
            </div>
            <div style={styles.divider}>
              <hr />
            </div>
          </div>
        )}

        <div style={styles.bottomSection}>
          <Button
            style={styles.button}
            onClick={handleClose}
            variant="contained"
            color="primary"
            size="large"
          >
            Back to Decks
          </Button>
        </div>
      </div>
    </div>
  );
}
