import React, { useEffect, useContext } from "react";
import Create from "./create";
import { AuthContext } from "./auth-provider";
import { useNavigate } from "react-router-dom";

export default function CreateWelcome() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    navigate("/deck");
  }, [currentUser, navigate]);

  return <Create view="welcome" />;
}
