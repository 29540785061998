import { initializeApp } from "firebase/app";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";

// DEPRECATED from firebase v.8
// import 'firebase/compat/firestore';
// import 'firebase/compat/functions';
// import 'firebase/compat/storage';
// import 'firebase/compat/analytics';

// Set API Version that React Client should use based on .env config
const API_VERSION = process.env.REACT_APP_API_VERSION || "1.0";
process.env.REACT_APP_ENV !== "prod" &&
  console.log(`Using API Version ${API_VERSION}`);

const COMMIT_HASH = process.env.REACT_APP_VERSION;
process.env.REACT_APP_ENV !== "prod" &&
  console.log(`Git Commit [${COMMIT_HASH.substring(0, 7)}]`);

const devFirebaseConfig = {
  apiKey: "AIzaSyBCFc47pwxZTyYIcU7yN8lU3RNfYo7AL50",
  authDomain: "moments-cards-dev.firebaseapp.com",
  databaseURL: "https://moments-cards-dev.firebaseio.com",
  projectId: "moments-cards-dev",
  storageBucket: "moments-cards-dev.appspot.com",
  messagingSenderId: "992632422180",
  appId: "1:992632422180:web:785c36b222fb992557fdb1",
  measurementId: "G-8B8HJSJRRD",
};

const stagingFirebaseConfig = {
  apiKey: "AIzaSyCJWFNrs7NxTAF-W7JSbgABMN-rWHp-dRA",
  authDomain: "auth-staging.moments.cards",
  databaseURL: "https://moments-cards-staging.firebaseio.com",
  projectId: "moments-cards-staging",
  storageBucket: "moments-cards-staging.appspot.com",
  messagingSenderId: "56903029791",
  appId: "1:56903029791:web:53800cd1a56262aca5712a",
  measurementId: "G-62J7LRVDGX",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyA1C0GuT8t3BQxmZWHXhPrJWYIwwwRLs7Y",
  authDomain: "auth.moments.cards",
  databaseURL: "https://moments-cards-prod.firebaseio.com",
  projectId: "moments-cards-prod",
  storageBucket: "moments-cards-prod.appspot.com",
  messagingSenderId: "584731437808",
  appId: "1:584731437808:web:303262a0b04f1bb30ef035",
  measurementId: "G-CKZZ1WP0VT",
};

let firebaseConfig = devFirebaseConfig;

if (process.env.REACT_APP_ENV === "staging") {
  firebaseConfig = stagingFirebaseConfig;
  // console.log('Running on staging cloud environment')
}

if (process.env.REACT_APP_ENV === "prod") {
  firebaseConfig = prodFirebaseConfig;
  // console.log('Running on production cloud environment')
}

export const config = firebaseConfig;

// DEPRECATED from firebase v.8
// firebase.initializeApp(config);
// export const db = firebase.firestore();
// export const fn = firebase.functions();
// export const fa = firebase.analytics();

// Initialize and export firebase app, firestore database, firebase functions and firebase analytics
const firebaseApp = initializeApp(config);
export default firebaseApp;
export const fn = getFunctions(firebaseApp);

// Initialize local firebase functions emulator
// only when on localhost and .env config is set to use local emulator
if (
  process.env.REACT_APP_ENV === "dev" &&
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_CLOUD_FUNCTIONS === "local"
) {
  connectFunctionsEmulator(fn, "localhost", 5003);
  console.log("Using local cloud functions emulator");
}

// Wrapper to pass API Version in the data payload for all cloud functions API calls
export const apiCall = (name) => {
  const callable = httpsCallable(fn, name);
  const wrapper = (data = {}) => {
    data.apiVersion = API_VERSION;
    return callable(data);
  };
  return wrapper;
};

export const getStorageDir = ({ deckID, cardID }) => {
  return `cards/${deckID.charAt(0)}/${deckID.charAt(1)}/${deckID}/${cardID}`;
};
