import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { AuthProvider } from "./components/auth-provider";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { AppProvider } from "./components/app-context";
import theme from "./styles/theme";

const container = document.getElementById("app");
const root = createRoot(container);

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

Sentry.init({
  dsn: "https://6a23575f98254206a07011db3d6a01f9@o481278.ingest.sentry.io/5529599",
  environment: process.env.REACT_APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   process.env.REACT_APP_ENV === "dev" && console.log({ event });
  //   if (event.exception) {
  //     Sentry.showReportDialog({
  //       eventId: event.event_id,
  //       labelSubmit: "Submit issue report",
  //     });
  //   }
  //   return event;
  // },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: process.env.REACT_APP_ENV === "dev" ? 1.0 : 0.1,
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* MasterialUI baseline CSS */}
        <Router>
          <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
            <AuthProvider>
              <AppProvider>
                {" "}
                {/* Top level app context provider */}
                <App />
              </AppProvider>
            </AuthProvider>
          </Sentry.ErrorBoundary>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// With strict mode (MaterualUI fab throws error with this!)
//   <React.StrictMode>
//      { children }
//   </React.StrictMode>,
//   document.getElementById('root')
// );
