const PACKAGE_PRICE = 1600;
const PRICE_PER_CARD = 100;
const PACKAGE_SIZE = 12;

const price = (numCards) => {
  const extraCards = Math.max(0, numCards - PACKAGE_SIZE);
  const totalPrice = PACKAGE_PRICE + extraCards * PRICE_PER_CARD;
  return totalPrice;
};

export default price;
