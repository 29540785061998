/** @jsxImportSource @emotion/react */
import React, { useContext, useState, useEffect } from "react";
import { DRAWER_WIDTH_PX } from "../utils/constants";
import assets from "../utils/assets";
import { css, keyframes } from "@emotion/react";

import AppContext from "./app-context";

// Firebase
import { updateDoc } from "firebase/firestore";

import { cardBacks } from "../utils/data.js";

const backOptionDelayms = 50;

// Pre-cache the images for this control to avoid UI glitch when menu opens without images loaded
// const imgSrcToCache = [
//     "selector_horizontal.png",
//     "B0006_horizontal.png",
//     "B0006_vertical.png",
//     "B0008_horizontal.png",
//     "B0008_vertical.png",
//     "B0009_horizontal.png",
//     "B0009_vertical.png",
//     "B0011_horizontal.png",
//     "B0011_vertical.png",
// ];

const cardShowKeyframes = keyframes({
  "0%": {
    transform: "scale(0)",
  },
  "100%": {
    transform: "scale(1)",
  },
});

const cardHideKeyframes = keyframes({
  "0%": {
    transform: "scale(1)",
  },
  "100%": {
    transform: "scale(0)",
  },
});

const cardShowCSS = css({
  animation: `${cardShowKeyframes} 0.2s forwards linear`,
  transformOrigin: "left bottom",
});

const cardHideCSS = css({
  animation: `${cardHideKeyframes} 0.2s forwards linear`,
  transformOrigin: "left bottom",
});

const cardInitCSS = css({
  transform: "scale(0)",
  transformOrigin: "left center",
});

const styles = {
  cardBackImg: {
    width: "100%",
  },
  backSelectButton: {
    pointerEvents: "all",
    position: "relative",
    bottom: 0,
    left: 0,
    width: "90px",
    height: "58px",
    borderRadius: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    overflow: "hidden",
    cursor: "pointer",
  },
  backSelectBox: {
    position: "fixed",
    bottom: "20px",
    left: "20px",
    zIndex: 50,
    transition: "left 0.25s",
    cursor: "pointer",
  },
  backSelectBoxShift: {
    position: "fixed",
    bottom: "20px",
    left: `${20 + DRAWER_WIDTH_PX}px`,
    zIndex: 50,
    transition: "left 0.25s",
  },
  backSelectList: {
    display: "flex",
    flexFlow: "column-reverse nowrap",
    cursor: "pointer",
  },
  hide: {
    pointerEvents: "none",
  },
  backSelectOption: {
    // width: "18vh",
    // height: "12vh",
    width: "120px",
    height: "77px",
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0 0.5rem 1rem #808080",
    overflow: "hidden",
    cursor: "pointer",
  },
  cardInit: {
    transform: "scale(0)",
    transformOrigin: "left center",
  },
  cardHide: {
    animation: "$cardHide 0.2s forwards linear",
    transformOrigin: "left bottom",
  },
  cardShow: {
    animation: "$cardShow 0.2s forwards linear",
    transformOrigin: "left bottom",
  },
  "@keyframes cardHide": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0)",
    },
  },
  "@keyframes cardShow": {
    "0%": {
      transform: "scale(0)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
};

function BackSelect({ activeDeck }) {
  const { state, dispatch } = useContext(AppContext);

  // Keep track of whether or not the images have loaded yet
  // Don't render the control until the images have loaded
  // const [imagesLoading, setImagesLoading] = useState(true);

  const [backSelectPrompt, setBackSelectPrompt] = useState(false);
  const [open, setOpen] = useState(false);
  // const [activeDeckID, setActiveDeckID] = useState();

  useEffect(() => {
    if (!!state.app.uploadStatus) return;
    if (!state.decks.activeDeck.ref) {
      setBackSelectPrompt(false);
    }
    if (
      !backSelectPrompt &&
      state.decks.activeDeck.ref &&
      !state.decks.activeDeck.backImage &&
      state.decks.activeCardList &&
      state.decks.activeCardList.length >= 6
    ) {
      setBackSelectPrompt(true);
      dispatch({
        type: "ADD_ALERT",
        payload: {
          alert: {
            type: "success",
            message:
              "Lookin' good! How about choosing a design for the backs? (bottom left)",
          },
        },
      });
      dispatch({
        type: "CONTROL",
        payload: { name: "backSelectOpen", state: true },
      });
      return;
    }
  }, [
    state.app.uploadStatus,
    state.decks.activeDeck,
    state.decks.activeDeck.backImage,
    state.decks.activeCardList,
    backSelectPrompt,
    dispatch,
  ]);

  useEffect(() => {
    // Incrementally add the card back selector options from the array of card_backs
    const addBackOption = (num) => {
      if (num < cardBacks.length) {
        let backOptions = [];
        for (let i = 0; i <= num; i++) {
          backOptions.push(cardBacks[i]);
        }
        setBackSelectors(backOptions);
        setTimeout(() => addBackOption(num + 1), backOptionDelayms);
      }
    };

    const startOpen = () => {
      setBackSelectors([cardBacks[0]]);
      setTimeout(() => addBackOption(1), backOptionDelayms);
    };

    if (!open && state.app.controls.backSelectOpen) {
      setOpen(true);
      startOpen();
      return;
    }
    if (!state.app.controls.backSelectOpen) {
      setOpen(false);
    }
  }, [open, state.app.controls.backSelectOpen]);

  const [animationCSS, setAnimationCSS] = useState(cardInitCSS);
  useEffect(() => {
    if (open === true) setAnimationCSS(cardShowCSS);
    if (open === false) setAnimationCSS(cardHideCSS);
  }, [open]);

  const [backSelectors, setBackSelectors] = useState([]);

  //  To open the menu, dispatch the action and start stagggering the addition of the back selector options
  const handleOpen = () => {
    dispatch({
      type: "CONTROL",
      payload: { name: "backSelectOpen", state: true },
    });
  };

  // To close, dispatch the false state to the CONTROLS action
  const handleClose = () => {
    dispatch({
      type: "CONTROL",
      payload: { name: "backSelectOpen", state: false },
    });
  };

  // When any back design option is clicked, set that back design but don't close the menu
  // The menu closes automatically when the user mouses away
  const handleClick = (backImageID, dispatch) => () => {
    handleClose();
    // Update card back
    const backImage = {
      type: "standard",
      id: backImageID,
      src: {
        horizontal: assets[`${backImageID}_horizontal.png`],
        vertical: assets[`${backImageID}_vertical.png`],
      },
    };

    updateDoc(state.decks.activeDeckRef, {
      backImage: backImage,
    })
      .then(function () {
        // Initiate flip sequence
        dispatch({ type: "FLIP_CARDS", payload: {} });
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error selecting back design: ", error);
      });
  };

  // Only use the mouseover to open if there is no touch support
  const mouseOver = state.app.isMobile ? undefined : () => handleOpen();

  // Set the src for the button image to match the current back design or the selector image
  const [buttonSrc, setButtonSrc] = useState(assets["selector_horizontal.png"]);
  useEffect(() => {
    if (
      state.decks.activeDeck.backImage &&
      state.decks.activeDeck.backImage.src
    ) {
      setButtonSrc(state.decks.activeDeck.backImage.src.horizontal);
    } else {
      if (state.decks.activeDeck.ref) {
        setButtonSrc(assets["selector_horizontal.png"]);
      }
    }
  }, [state.decks.activeDeck.backImage, state.decks.activeDeck.ref]);

  const onClick = () => {
    if (state.app.isMobile) {
      open ? handleClose() : handleOpen();
    }
  };

  // Pre-load the images and resolve promises when the images are all loaded or fail
  // const cacheImages = (srcArray) => {
  //     const promises = [];
  //     srcArray.forEach((src) => {
  //         const img = new Image();
  //         // create a promise for every image and resolve it when it loads or errors
  //         const p = new Promise((resolve, reject) => {
  //             img.onload = resolve();
  //             img.onerror = reject();
  //             img.src = assets[src]; // important to set the src AFTER the onload event is linked up
  //         })
  //         // add new promise to an array of promises
  //         promises.push(p);
  //     })
  //     // combine the promises into one and when it resolves, set the loading state to false
  //     const cachePromise = Promise.all(promises);
  //     cachePromise.then(() => {
  //         setImagesLoading(false);
  //     })
  // }

  // useEffect(() => { cacheImages(imgSrcToCache) }, []);

  // While the images are loading, don't render the control
  // if (imagesLoading) {
  //     return (<></>);
  // }

  return (
    <div
      onMouseLeave={open ? () => handleClose() : undefined}
      style={{
        ...(state.app.controls.drawerIsOpen && !state.app.isMobile
          ? styles.backSelectBoxShift
          : styles.backSelectBox),

        ...(!open && styles.hide),
      }}
    >
      <div style={{ ...styles.backSelectList, ...(!open && styles.hide) }}>
        {backSelectors.map((cardBack) => (
          <div
            key={cardBack}
            style={styles.backSelectOption}
            css={animationCSS}
            onClick={handleClick(cardBack, dispatch)}
          >
            <img
              style={styles.cardBackImg}
              src={assets[`${cardBack}_horizontal.png`]}
              alt={`${cardBack}`}
            />
          </div>
        ))}
      </div>
      <div
        style={styles.backSelectButton}
        onMouseOver={mouseOver}
        onClick={onClick}
      >
        <img style={styles.cardBackImg} src={buttonSrc} alt="Back Design" />
      </div>
    </div>
  );
}

export default BackSelect;
