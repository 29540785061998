import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// Generic overlay button for Card
// style: pass-through styling properties, should include position, background color and text color
// icon: pass through icon for the button
// handleClick: any function that the parent (card) sends to be executed on click (e.g. adjust hover state)
// clickAction: the action the button should trigger
function OverlayButton({
  addStyle,
  icon,
  clickAction,
  buttonSize,
  buttonVariant,
  tooltip,
}) {
  const fontSize = Math.floor(buttonSize * 0.7);

  // Small round button positioned absolute on the card
  // style should include position, background-color and text color
  const style = {
    position: "absolute",
    borderRadius: "50%",
    width: `${buttonSize}px`,
    height: `${buttonSize}px`,
    minWidth: `${buttonSize}px`,
    minHeight: `${buttonSize}px`,
    padding: 0,
    fontSize: `${fontSize}px`,
    ...addStyle,
  };

  // execute the handleClick from the parent (card)
  // execute the clickAction from the button declaration
  function onClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // handleClick && handleClick();
    clickAction && clickAction();
  }

  return (
    <Tooltip title={tooltip} arrow>
      <Button variant={buttonVariant} onClick={onClick} sx={style}>
        {icon && React.cloneElement(icon, { fontSize: "inherit" })}
      </Button>
    </Tooltip>
  );
}

export default OverlayButton;
