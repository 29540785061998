import loadImage from "blueimp-load-image";

// Firebase
import firebaseApp, { getStorageDir } from "../utils/firebase";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import {
  serverTimestamp,
  doc,
  setDoc,
  updateDoc,
  collection,
  deleteDoc,
} from "firebase/firestore";

const PREVIEW_IMAGE_MAX_PX = 300;
const IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png|webp|bmp)$/i;

export const handlePaste = (dispatch, deckID) => (evt) => {
  evt.preventDefault();
  const items = evt.clipboardData.items;
  const files = [];
  Object.values(items).forEach((item) => {
    if (IMAGE_MIME_REGEX.test(item.type)) {
      const file = item.getAsFile();
      files.push(file);
    }
  });
  processImages(files, deckID, dispatch);
};

const storeImage = async (file, cardID, deckID, dispatch) => {
  const storage = getStorage(firebaseApp);
  const storageRef = ref(storage);

  const ext = file.name.split(".").pop();

  let uploadResolve, uploadReject;

  const uploadPromise = new Promise((resolve, reject) => {
    uploadResolve = resolve;
    uploadReject = reject;
  });

  const storageDir = getStorageDir({ cardID, deckID });

  const imageRef = ref(storageRef, `${storageDir}/new_image.${ext}`);

  const uploadNext = (snapshot) => {
    // const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
    const bytes = snapshot.bytesTransferred;
    dispatch({ type: "FILE_UPLOAD_UPDATE", payload: { cardID, bytes } });
  };

  const uploadError = (error) => {
    console.log(`Error uploading image file for card [${cardID}]:`, error);
    uploadReject(error);
  };

  const uploadComplete = () => {
    dispatch({ type: "FILE_UPLOAD_SUCCESS", payload: {} });
    uploadResolve("upload completed");
  };

  // const imageUploadTask = imageRef.put(file);

  const imageUploadTask = uploadBytesResumable(imageRef, file);

  imageUploadTask.on("state_changed", {
    next: uploadNext,
    error: uploadError,
    complete: uploadComplete,
  });

  return uploadPromise;
};

const createCard = async (file, cardID, deckRef, dispatch) => {
  const imageOptions = {
    canvas: true,
    maxWidth: PREVIEW_IMAGE_MAX_PX,
    maxHeight: PREVIEW_IMAGE_MAX_PX,
  };

  // TODO: Try just doing this by drawing on canvas, maybe in smaller size?
  const imageData = await loadImage(file, imageOptions);

  const width = imageData.image.width;
  const height = imageData.image.height;
  const orientation = width > height ? "horizontal" : "vertical";

  const previewSrc = imageData.image.toDataURL();

  dispatch({
    type: "SET_PREVIEW_IMAGE",
    payload: { cardID, src: previewSrc, orientation },
  });

  const card = {
    orientation,
    display: true,
    updated_at: serverTimestamp(),
  };

  const cardRef = doc(collection(deckRef, "cards"), cardID);
  updateDoc(cardRef, card).catch(function (error) {
    console.log("Error adding card from file:", error);
    return error;
  });
  return "card creation succeeded";
};

const processImage = async (file, deckRef, dispatch) => {
  const deckID = deckRef.id;
  const cardRef = doc(collection(deckRef, "cards"));
  const cardID = cardRef.id;

  const card = {
    cardID,
    imageName: file.name || "unnamed",
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    deleted_at: null,
  };

  // Create a new card document in the collection with the basic data
  const outcome = await setDoc(cardRef, card)
    .then(function () {
      const p1 = createCard(file, cardID, deckRef, dispatch);
      const p2 = storeImage(file, cardID, deckID, dispatch);
      return Promise.all([p1, p2]);
    })
    .catch(async (error) => {
      dispatch({
        type: "FILE_UPLOAD_FAILURE",
        payload: { fileName: file.name },
      });
      // TODO: clean up if there is a card that was created
      await deleteDoc(cardRef);
      console.log("Error creating new card:", error);
      return error;
    });

  // console.log('Promises resolved with outcome: ', outcome)

  return outcome;
};

export default async function processImages(files, deckRef, dispatch) {
  const imageFiles = files.filter((file) => IMAGE_MIME_REGEX.test(file.type));

  // TODO: notify the user that some files were rejected
  if (imageFiles.length === 0) return;

  const reducer = (acc, curr) => {
    return acc + curr.size;
  };

  const totalBytes = imageFiles.reduce(reducer, 0);

  dispatch({
    type: "START_UPLOAD",
    payload: { totalBytes, totalFiles: imageFiles.length },
  });

  for (const file of imageFiles) {
    processImage(file, deckRef, dispatch).catch((error) => {
      console.error(`Failed to process file ($file.name): `, error);
    });
  }
}
