import React, { useContext } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import UploadIcon from "@mui/icons-material/Upload";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useNavigate } from "react-router-dom";
// import assets from '../utils/assets';

import AppContext from "./app-context";
import { AuthContext } from "./auth-provider";

// Set the SpeedDial position to be fixed in the bottom right
const AddSpeedDial = styled(SpeedDial)({
  position: "fixed",
  bottom: "20px",
  right: "20px",
});

export default function AddFab({ openFileUpload }) {
  const { state, dispatch } = useContext(AppContext);
  const { isAdmin } = useContext(AuthContext);

  const navigate = useNavigate();

  // Get control state from app context
  const open = state.app.controls.addFabOpen;

  // To open, set the control value to true
  const handleOpen = () => {
    dispatch({ type: "CONTROL", payload: { name: "addFabOpen", state: true } });
  };

  // Toggle open/closed on click by inverting the control value
  const toggleOpen = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({
      type: "CONTROL",
      payload: { name: "addFabOpen", state: !open },
    });
  };

  // Close by setting the control value to false in the app context
  const handleClose = () => {
    dispatch({
      type: "CONTROL",
      payload: { name: "addFabOpen", state: false },
    });
  };

  // When an action is clicked, close the menu and then dispatch that action to open the correct modal
  // const handleActionClick = (evt, name) => {
  //     evt.preventDefault();
  //     evt.stopPropagation();
  //     dispatch({ type: "CONTROL", payload: { name: 'addFabOpen', state: false } });
  //     dispatch({ type: "MODAL", payload: { type: name } });
  // }

  const handleGenerate = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({
      type: "CONTROL",
      payload: { name: "addFabOpen", state: false },
    });

    dispatch({ type: "MODAL", payload: { type: "generateImage" } });
  };

  const handleOpenFileUpload = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({
      type: "CONTROL",
      payload: { name: "addFabOpen", state: false },
    });
    openFileUpload();
  };

  const handleOpenLibrary = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({
      type: "CONTROL",
      payload: { name: "addFabOpen", state: false },
    });
    dispatch({
      type: "CONTROL",
      payload: { name: "drawerIsOpen", state: true },
    });
    dispatch({
      type: "CONTROL",
      payload: { name: "libraryOpen", state: true },
    });
    dispatch({ type: "CONTROL", payload: { name: "decksOpen", state: false } });
    dispatch({
      type: "CONTROL",
      payload: { name: "sharedDecksOpen", state: false },
    });
    navigate(`/library/${state.library.activeAlbumRef.id}`);
  };

  // The actions list for the Add FAB
  const actions = [
    {
      icon: <UploadIcon />,
      name: "Upload",
      onClick: handleOpenFileUpload,
    },
    {
      icon: <PhotoLibraryIcon />,
      name: "Library",
      onClick: handleOpenLibrary,
    },
    // { icon: <img width='25px' src={assets["facebook_icon.png"]} alt='Facebook'/>, name: 'Facebook', onClick: (evt) => handleActionClick(evt, 'facebook') },
    // { icon: <img width='25px' src={assets["google-photos-icon.png"]} alt='Google' />, name: 'Google', onClick: (evt) => handleActionClick(evt, 'google') },
  ];

  // if the user is an admin or this is the DEV environment, allow them to generate images via AI
  if (process.env.REACT_APP_ENV === "dev" || isAdmin)
    actions.push({
      icon: <AutoFixHighIcon />,
      name: "Generate",
      onClick: handleGenerate,
    });

  const mouseEnter = state.app.isMobile ? undefined : handleOpen;

  const onClick = (evt) => {
    if (state.app.isMobile) {
      toggleOpen(evt);
    } else {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch({
        type: "CONTROL",
        payload: { name: "addFabOpen", state: true },
      });
    }
  };

  return (
    <AddSpeedDial
      ariaLabel="SpeedDial tooltip example"
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onMouseEnter={mouseEnter}
      onClick={onClick}
      open={open}
      FabProps={{ color: "primary" }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={action.onClick}
          FabProps={{ size: "large" }}
        />
      ))}
    </AddSpeedDial>
  );
}
