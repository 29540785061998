import React from "react";
import AuthBox from "./auth-box";
import { Navigate } from "react-router-dom";

export default function SignUp() {
  // Don't allow new signups in staging
  // Redirect to signin page
  if (process.env.REACT_APP_ENV === "staging") {
    return <Navigate to="/signin" />;
  }

  return <AuthBox version="signUp" />;
}
