import React, { useContext } from "react";
import ModalOrder from "./modal-order";
import ModalProfile from "./modal-profile";

import AppContext from "./app-context";

export default function AppModals() {
  const { state, dispatch } = useContext(AppContext);

  // Close the modal by setting the modal attribute to null in the app config
  const handleClose = (evt) => {
    dispatch({ type: "MODAL", payload: {} });
  };

  // Switch on the modal attribute to display the right modal
  switch (state.app.modal.type) {
    // Move this to display in the Gallery in order to align with gallery position
    // case "help":
    //   return <ModalHelp view="deck" handleClose={handleClose} />;
    case "order": {
      return <ModalOrder handleClose={handleClose} />;
    }
    case "profile":
      return <ModalProfile handleClose={handleClose} />;

    // TODO: Add facebook modal
    // case 'facebook':
    //
    //     return <ModalUpload handleClose={handleClose} />;
    //
    default:
      return null;
  }
}
