import React, { useContext } from "react"; //

import TextField from "@mui/material/TextField";
import AppContext from "./app-context";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import theme from "../styles/theme";

// Firebase
import {
  doc,
  updateDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";

const styles = {
  orderRow: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    // paddingBottom: '10px',
    color: "#606060",
  },
  picture: {
    flex: "0 0 10%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  pictureFrame: {
    borderRadius: "3px",
    overflow: "hidden",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  spacer: {
    flex: "0 0 15%",
  },
  filler: {
    height: "7px",
  },
  quantity: {
    flex: "0 0 20%",
    display: "flex",
    justifyContent: "center",
  },
  name: {
    flex: "0 0 45%",
    paddingLeft: "5px",
  },
  price: {
    flex: "0 0 20%",
    textAlign: "right",
  },
  shipping: {
    flex: "0 0 65%",
  },
  total: {
    flex: "0 0 65%",
    fontWeight: 700,
    fontSize: "1rem",
  },
  totalPrice: {
    flex: "0 0 20%",
    textAlign: "right",
    fontWeight: 700,
    fontSize: "1rem",
  },
  tax: {
    flex: "0 0 65%",
  },
  taxPrice: {
    flex: "0 0 20%",
    textAlign: "right",
  },
  discount: {
    flex: "0 0 65%",
  },
  discountPrice: {
    flex: "0 0 20%",
    textAlign: "right",
    color: theme.palette.primary.main,
  },
  divider: {
    flex: "0 0 88%",
  },
  deleteIcon: {
    fontSize: "20px",
    color: "red",
  },
};

export default function OrderRow({ item, totalPrice, tax, discount, version }) {
  const { state } = useContext(AppContext);

  const updateQuantity = (evt) => {
    let val = +evt.target.value;
    if (evt.target.value === "") {
      console.log("setting val to 0");
      val = 0;
    }
    if (val < 0) {
      val = 0;
    }
    if (typeof val === "number") {
      updateDoc(state.userData.ref, {
        [`cart.items.${item.printID}.quantity`]: val,
      });
    }
  };

  const handleDelete = () => {
    updateDoc(state.userData.ref, {
      [`cart.items.${item.printID}`]: deleteField(),
    }).catch((err) => {
      console.error(
        `Error removing print [${item.printID}] from user order: ${err}`
      );
    });

    updateDoc(doc(state.userData.ref, "prints", item.printID), {
      deleted_at: serverTimestamp(),
    }).catch((err) => {
      console.error(
        `Error deleting print [${item.printID}] when removed form order: ${err}`
      );
    });
  };

  return (
    <>
      {version === "labels" && (
        <div style={styles.orderRow}>
          <div style={styles.picture} />
          <div style={styles.quantity}>
            <strong>Qty.</strong>
          </div>
          <div style={styles.name}>
            <strong>Deck name</strong>
          </div>
          <div style={styles.price}>
            <strong>Price</strong>
          </div>
        </div>
      )}

      {item && item.type === "print" && (
        <div style={styles.orderRow}>
          {item.quantity > 0 && (
            <div style={styles.picture}>
              <div style={styles.pictureFrame}>
                {item.image.src && (
                  <img
                    src={item.image.src.horizontal}
                    height="30px"
                    alt="back design"
                  />
                )}
              </div>
            </div>
          )}

          {item.quantity === 0 && (
            <div style={styles.picture}>
              <CancelIcon style={styles.deleteIcon} onClick={handleDelete} />
            </div>
          )}
          <div style={styles.quantity}>
            {version === "cart" && (
              <TextField
                id="printQuantity"
                variant="outlined"
                size="small"
                type="number"
                value={item.quantity}
                onChange={updateQuantity}
              />
            )}
            {version === "order" && item.quantity}
          </div>

          {version === "order" && (
            <div style={styles.name}>
              <Link
                target="_blank"
                component={RouterLink}
                to={`/digitaldeck/${item.printID}`}
                underline="hover"
              >
                {item.name} ({item.cardCount})
              </Link>
            </div>
          )}
          {version === "cart" && (
            <div style={styles.name}>
              {item.name} ({item.cardCount})
            </div>
          )}

          <div style={styles.price}>{`$${(
            (item.quantity * item.priceCents) /
            100
          ).toFixed(2)}`}</div>
        </div>
      )}

      <div style={styles.orderRow}>
        <div style={styles.filler} />
      </div>

      {discount > 0 && (
        <>
          <div style={styles.orderRow}>
            <div style={styles.spacer}></div>
            <div style={styles.discount}>Discount</div>
            <div style={styles.discountPrice}>{`-$${(discount / 100).toFixed(
              2
            )}`}</div>
          </div>
        </>
      )}

      {item && item.type === "shipping" && (
        <>
          <div style={styles.orderRow}>
            <div style={styles.spacer}></div>
            <div style={styles.shipping}>{item.name}</div>
            {item.priceCents > 0 && (
              <div style={styles.price}>{`$${(item.priceCents / 100).toFixed(
                2
              )}`}</div>
            )}
            {item.priceCents === 0 && <div style={styles.price}>FREE</div>}
          </div>
        </>
      )}

      {tax > 0 && (
        <>
          <div style={styles.orderRow}>
            <div style={styles.spacer}></div>
            <div style={styles.tax}>Tax</div>
            <div style={styles.taxPrice}>{`$${(tax / 100).toFixed(2)}`}</div>
          </div>
        </>
      )}

      {(totalPrice || totalPrice === 0) && (
        <>
          <div style={styles.orderRow}>
            <div style={styles.spacer} />
            <div style={styles.divider}>
              <hr />
            </div>
          </div>
          <div style={styles.orderRow}>
            <div style={styles.spacer}></div>
            <div style={styles.total}>Total</div>
            <div style={styles.totalPrice}>{`$${(totalPrice / 100).toFixed(
              2
            )}`}</div>
          </div>
        </>
      )}
    </>
  );
}
