import React, { useContext, useState, useEffect, useRef } from "react";
import { serverTimestamp, collection } from "firebase/firestore";
import * as Sentry from "@sentry/react";
import { AuthContext } from "./auth-provider";
import AppBar from "@mui/material/AppBar";
import Alert from "@mui/material/Alert";
// import useScrollTrigger from "@mui/material/useScrollTrigger";
// import Slide from "@mui/material/Slide";

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { Typography } from "@mui/material";
import { deckConstructor, albumConstructor } from "../utils/data";
import Button from "@mui/material/Button";
import theme from "../styles/theme";
import AlertUploadStatus from "./alert-upload-status";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import ShareIcon from "@mui/icons-material/Share";

import Gallery from "./gallery";
import AppContext from "./app-context";
import LibraryNotify from "./library-notify";
import GenerateImageDialog from "./generate-image-dialog";
import EditDeckDialog from "./edit-deck-dialog";
import ShareDeckDialog from "./share-deck-dialog";
import ModalHelp from "./modal-help";
import UserNav from "./user-nav";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DRAWER_WIDTH_PX,
  HEADER_HEIGHT_PX,
  NOTIFY_HEIGHT_PX,
  MINIMUM_DECK_SIZE,
} from "../utils/constants";
import price from "../utils/pricing";

// Firebase
import firebaseApp, { apiCall } from "../utils/firebase";
import {
  getFirestore,
  doc,
  query,
  where,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

const headerHeight = `${HEADER_HEIGHT_PX}px`;
const notifyHeight = `${NOTIFY_HEIGHT_PX}px`;
const drawerZIndex = 1;

const styles = {
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100vh",
    width: "100vw",
    overflow: "none",
  },
  appBar: {
    zIndex: drawerZIndex + 1,
  },
  headerToolbar: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    height: headerHeight,
    backgroundColor: "#FFFFFF",
    // backgroundColor: theme.palette.primary.main,
  },
  drawer: {
    width: `${DRAWER_WIDTH_PX}px`,
    flexShrink: 0,
    "& .MuiDrawer-paper": { width: `${DRAWER_WIDTH_PX}px` },
  },
  main: {
    display: "flex",
    height: "100%",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
  },
  drawerPaper: {
    width: `${DRAWER_WIDTH_PX}px`,
  },
  drawerHeaderSpacer: {
    height: headerHeight,
  },
  drawerNotifySpacer: {
    height: notifyHeight,
  },
  headerLogo: {
    height: "40px",
    cursor: "pointer",
  },
  leftSection: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rightSection: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  orderButton: {
    // marginRight: "5px",
  },
  disabledOrderButton: {
    marginRight: "10px",
    boxShadow: "none !important",
    color: "#A0A0A0 !important",
    backgroundColor: "#DCDCDC !important",
  },
  orderButtonSpinner: {
    color: "#FFFFFF",
  },
  iconTabWrapper: {
    flexDirection: "row-reverse",
    alignItems: "center",
    maxWidth: "50vw",
  },
  tabIcon: {
    marginBottom: "0 !important",
    fontSize: "1.5rem",
  },
  settingsIcon: {
    // marginBottom: "0 !important",
    fontSize: "2rem",
  },
  createDeckListItem: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    fontWeight: 500,
    color: "gray",
    fontFamily: "'Roboto', sans- serif",
    textTransform: "uppercase",
    paddingLeft: "30px",
    cursor: "default",
  },
  deckListItem: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    fontWeight: 500,
    color: "gray",
    fontFamily: "'Roboto', sans- serif",
    textTransform: "uppercase",
    paddingLeft: "30px",
  },
  albumListItem: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    fontWeight: 500,
    color: "gray",
    fontFamily: "'Roboto', sans- serif",
    textTransform: "uppercase",
    paddingLeft: "30px",
  },
  createDeckText: {
    fontWeight: 500,
    cursor: "default",
  },
  activeDeck: {
    fontWeight: 500,
    backgroundColor: "#E8E8E8",
  },
  activeAlbum: {
    fontWeight: 500,
    backgroundColor: "#E8E8E8",
  },
  deckListIcon: {
    paddingLeft: "10px",
    fontSize: "2rem",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  cancelShareIcon: {
    paddingLeft: "10px",
    fontSize: "2rem",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  deckDeleteIcon: {
    paddingLeft: "5px",
    fontSize: "2rem",
  },
  alertBox: {
    zIndex: 1000,
    position: "absolute",
    top: "50px",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "600px",
    maxWidth: "95vw",
    boxShadow: "0 0.5rem 1rem #808080",
  },
  deckSelect: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
  deckSelectMobile: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  deckSelectLabel: {
    color: theme.palette.primary.main,
    fontSize: "1.3em",
    fontFamily: "'Roboto', sans- serif",
    fontWeight: 500,
    cursor: "pointer",
  },
  deckSelectText: {
    color: "gray",
    fontSize: "1.3em",
    fontFamily: "'Roboto', sans- serif",
    fontWeight: 700,
    cursor: "pointer",
  },
  deckSelectIcon: {
    fontSize: "2.5em",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  menuIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "40px",
  },
  deckListText: {
    cursor: "pointer",
  },
  deckMenuText: {
    cursor: "pointer",
  },
  listHeading: {
    cursor: "pointer",
  },
  shoppingCart: {
    // color: theme.palette.primary.main,
    color: "gray",
    fontSize: "30px",
    cursor: "pointer",
    marginLeft: "5px",
  },
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Create({ view }) {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const urlQueryParams = useQuery();

  const { currentUser, isAdmin } = useContext(AuthContext);

  // // Set and store the anchor element for the UserNav menu
  // const [anchorEl, setAnchorEl] = useState(null);

  const deckSelectRef = useRef(null);

  // The label for the Deck tab with name and card count
  const [deckLabel, setDeckLabel] = useState("loading...");
  const [editDeck, setEditDeck] = useState();
  const [shareDeck, setShareDeck] = useState();
  const [orderButtonStyle, setOrderButtonStyle] = useState(styles.orderButton);
  const [orderButtonTooltip, setOrderButtonTooltip] = useState("");
  const [orderButtonContents, setOrderButtonContents] = useState("ORDER");

  // const drawerIsOpen = state.app.controls.drawerIsOpen;
  const decksOpen = state.app.controls.decksOpen;
  const sharedDecksOpen = state.app.controls.sharedDecksOpen;
  const libraryOpen = state.app.controls.libraryOpen;

  const activeDeck = state.decks.activeDeck;
  const activeAlbum = state.library.activeAlbum;
  const userRef = state.userData ? state.userData.ref : null;

  const cartCount =
    state.userData.cart && state.userData.cart.items
      ? Object.keys(state.userData.cart.items).length
      : 0;

  const createDeck = async (options) => {
    const userRef = state.userData.ref;
    const deckRef = doc(collection(userRef, "decks"));
    options.deckID = deckRef.id;
    const newDeck = deckConstructor(options);
    return setDoc(deckRef, newDeck)
      .then(() => deckRef)
      .catch((error) => {
        console.log("Error creating new deck for user:", userRef.id);
        return error;
      });
  };

  const createAlbum = async (options) => {
    const albumRef = doc(collection(db, "albums"));
    const newAlbum = albumConstructor(options);
    return setDoc(albumRef, newAlbum)
      .then(() => albumRef)
      .catch((error) => {
        console.log("Error creating new album");
        return error;
      });
  };

  const toggleDrawer = (open) => () => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //     return;
    // }
    dispatch({
      type: "CONTROL",
      payload: { name: "drawerIsOpen", state: open },
    });
    dispatch({
      type: "CONTROL",
      payload: { name: "libraryOpen", state: false },
    });
    dispatch({ type: "CONTROL", payload: { name: "decksOpen", state: false } });
    dispatch({
      type: "CONTROL",
      payload: { name: "sharedDecksOpen", state: false },
    });
  };

  // Welcome view
  // Set up dummy data for the welcome experience and open the drawer in desktop
  useEffect(() => {
    if (view !== "welcome") return;
    setDeckLabel("My Deck");
    if (!state.app.isMobile) {
      dispatch({
        type: "CONTROL",
        payload: { name: "drawerIsOpen", state: true },
      });
    }
  }, [view, dispatch, state.app.isMobile]);

  const createPrint = async () => {
    // process.env.REACT_APP_ENV !== "prod" && console.log("createPrint");

    const deckID = state.decks.activeDeck.ref.id;
    const deckUserID = state.decks.activeDeck.ref.parent.parent.id;
    const userID = state.userData.ref.id;
    const deckName = state.decks.activeDeck.name;
    const cardCount = state.decks.activeCardList
      ? state.decks.activeCardList.length
      : 0;

    const userRef = doc(db, "users", userID);

    const newPrintRef = doc(collection(userRef, "prints"));
    const printID = newPrintRef.id;

    const newPrint = {
      printID,
      userID,
      deckUserID,
      deckID,
      cardCount,
      deckName,
      quantity: 1,
      backImage: state.decks.activeDeck.backImage,
      email: currentUser.email,
      name: currentUser.displayName,
      status: "initiated",
      imageCount: 0,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
      deleted_at: null,
    };

    const promises = [];

    promises.push(setDoc(newPrintRef, newPrint));

    promises.push(
      updateDoc(userRef, {
        [`cart.items.${printID}`]: {
          type: "print",
          printID,
          deckUserID,
          deckID,
          priceCents: price(cardCount),
          image: state.decks.activeDeck.backImage,
          quantity: 1,
          cardCount,
          name: deckName,
        },
      })
    );

    await Promise.all(promises).catch((err) => {
      const error = new Error(`Error creating print and cart item ${err}`);
      Sentry.captureException(error);
      process.env.REACT_APP_ENV === "dev" && console.error(error);
      throw error;
    });

    // Trigger generating the prints
    // Don't wait on this to go to the cart, keep the momentum going
    const generatePrintsAPI = apiCall("generatePrints");
    generatePrintsAPI({
      deckUserID,
      userID,
      deckID,
      printID,
      cardCount,
    }).then((res) => {
      if (res.data.outcome !== "success") {
        const error = new Error(
          `Call to generatePrints failed. Returned: ${res.data}`
        );
        Sentry.captureException(error);
        process.env.REACT_APP_ENV === "dev" &&
          console.log(`generatePrints api call error: ${error}`);
      }
    });
  };

  // Order the active deck when the order button is clicked
  const handleOrder = async () => {
    // Disable order button and fill it with a spinner
    dispatch({
      type: "CONTROL",
      payload: { name: "orderIsProcessing", state: true },
    });

    // Create a new print record, which triggers generating the images
    try {
      await createPrint();
    } catch (err) {
      Sentry.captureException(err);
      process.env.REACT_APP_ENV !== "prod" &&
        console.log(`Error creating print: ${err}`);
      dispatch({
        type: "ADD_ALERT",
        payload: {
          alert: {
            type: "error",
            message:
              "There was an error processing this order. If this issue persists, please contact support.",
          },
        },
      });
      dispatch({
        type: "CONTROL",
        payload: { name: "orderIsProcessing", state: false },
      });
      return;
    }

    dispatch({
      type: "CONTROL",
      payload: { name: "orderIsProcessing", state: false },
    });
    // navigate to the cart
    navigate(`/cart`);
  };

  // TODO: Remove this to turn ordering back on and uncomment actual function below
  function handleOrderButtonClick() {
    dispatch({
      type: "ADD_ALERT",
      payload: {
        alert: {
          type: "warning",
          message:
            "Sales are paused while we migrate our systems. Please contact will@moments.cards to order.",
        },
      },
    });
  }

  // END TODO

  // ORDERING PAUSED
  // function handleOrderButtonClick() {
  //   if (state.app.controls.orderIsProcessing) {
  //     return;
  //   }

  //   if (
  //     state.decks.activeCardList &&
  //     state.decks.activeCardList.length < MINIMUM_DECK_SIZE
  //   ) {
  //     dispatch({
  //       type: "ADD_ALERT",
  //       payload: {
  //         alert: { type: "info", message: "Minimum order: 6 cards" },
  //       },
  //     });
  //     return;
  //   }

  //   // No back design image chosen
  //   if (!state.decks.activeDeck.backImage) {
  //     dispatch({
  //       type: "ADD_ALERT",
  //       payload: {
  //         alert: {
  //           type: "info",
  //           message: "First choose a back design (bottom left)",
  //         },
  //       },
  //     });
  //     dispatch({
  //       type: "CONTROL",
  //       payload: { name: "backSelectOpen", state: true },
  //     });
  //     return;
  //   }

  //   handleOrder();
  // }

  // Order button config
  // Make active and set tooltip based on deck state
  // If the deck size is less than the minimum order, disable it
  // if the back design hasn't been seleced, disable it
  // When the order is processing, disable it
  // <Tooltip title={orderButtonTooltip}>
  //   <Button
  //     variant="contained"
  //     onClick={orderButtonClick}
  //     style={orderButtonStyle}
  //     disabled={orderButtonDisabled}
  //   >
  //     {orderButtonContents}
  //   </Button>
  // </Tooltip>;
  useEffect(() => {
    const cardCount = state.decks.activeCardList
      ? state.decks.activeCardList.length
      : 0;

    const deckPrice = (price(cardCount) / 100).toFixed(2);

    // Deck is smaller than miminum size
    if (
      state.decks.activeCardList &&
      state.decks.activeCardList.length < MINIMUM_DECK_SIZE
    ) {
      setOrderButtonTooltip("Minimum order: 6 cards");
      setOrderButtonStyle(styles.disabledOrderButton);
      setOrderButtonContents(`BUY $${deckPrice}`);
      return;
    }

    // No back design image chosen
    if (!state.decks.activeDeck.backImage) {
      setOrderButtonTooltip("First choose a back design (bottom left)");
      setOrderButtonStyle(styles.disabledOrderButton);
      setOrderButtonContents(`BUY $${deckPrice}`);
      return;
    }

    // Order processing
    if (state.app.controls.orderIsProcessing) {
      setOrderButtonTooltip("Processing Order");
      setOrderButtonStyle(styles.disabledOrderButton);
      setOrderButtonContents(
        <CircularProgress size={25} style={styles.orderButtonSpinner} />
      );
      return;
    }

    // Normal Order Button
    setOrderButtonTooltip("Order this deck.");
    setOrderButtonStyle(styles.orderButton);
    setOrderButtonContents(`BUY $${deckPrice}`);
  }, [
    dispatch,
    state.decks.activeCardList,
    state.decks.activeDeck.backImage,
    state.app.controls.orderIsProcessing,
    navigate,
  ]);

  // Handle user canceling checkout at payment provider
  // Receive callback from payment provider
  useEffect(() => {
    const cancelOrder = async () => {
      const sessionID = urlQueryParams.get("session_id");

      const orderQuery = query(
        collection(userRef, "orders"),
        where("stripeSessionID", "==", sessionID)
      );

      await getDocs(orderQuery)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            updateDoc(doc.ref, {
              web_success: false,
              deleted_at: serverTimestamp(),
            });
          } else {
            console.error(
              "Unable to cancel order. No order found for Session ID: ",
              sessionID
            );
          }
        })
        .catch((error) => {
          console.error("Session ID for unknown order: ", sessionID);
        });

      dispatch({
        type: "ADD_ALERT",
        payload: {
          alert: {
            type: "warning",
            message:
              "Checkout canceled. If there was a problem with your order please contact support.",
          },
        },
      });
      navigate("/deck");
    };

    if (!userRef) return;

    const isOrderCanceled = urlQueryParams.get("order_canceled");
    if (isOrderCanceled) {
      cancelOrder();
    }
  }, [urlQueryParams, userRef, dispatch, navigate]);

  // Update the label based on changes to activeDeck
  useEffect(() => {
    if (activeDeck && activeDeck.name && state.decks.activeCardList) {
      const cardCount = state.decks.activeCardList.length;
      let deckName = activeDeck.name;
      if (state.app.isMobile & (deckName.length > 10)) {
        deckName = deckName.substring(0, 8) + "...";
      }
      const label = `${deckName} (${cardCount})`;
      setDeckLabel(label);
    }
  }, [
    activeDeck,
    activeDeck.name,
    state.decks.activeCardList,
    state.app.isMobile,
  ]);

  const handleClick = (evt) => {
    evt.stopPropagation();
    navigate("/deck");
    // setAnchorEl(deckSelectRef.current);
  };

  // const handleClose = (evt) => {
  //     setAnchorEl(null);
  // };

  // const switchActiveDeckDropdown = (newActiveDeckRef) => (evt) => {
  //     dispatch({ type: "UPDATE_ACTIVE_DECK_REF", payload: { activeDeckRef: newActiveDeckRef } });
  //     setAnchorEl(null);
  //     evt.preventDefault();
  //     evt.stopPropagation();
  //     if (view !== 'library') navigate(`/deck`);
  // }

  const switchActiveDeckDrawer = (newActiveDeckRef) => (evt) => {
    dispatch({
      type: "UPDATE_ACTIVE_DECK_REF",
      payload: { activeDeckRef: newActiveDeckRef },
    });
    if (state.app.isMobile)
      dispatch({
        type: "CONTROL",
        payload: { name: "drawerIsOpen", state: false },
      });
    // setAnchorEl(null);
    evt.preventDefault();
    evt.stopPropagation();
    navigate(`/deck`);
  };

  const switchActiveAlbum = (newActiveAlbumRef) => (evt) => {
    dispatch({
      type: "UPDATE_ACTIVE_ALBUM_REF",
      payload: { activeAlbumRef: newActiveAlbumRef },
    });
    if (state.app.isMobile)
      dispatch({
        type: "CONTROL",
        payload: { name: "drawerIsOpen", state: false },
      });
    evt.preventDefault();
    evt.stopPropagation();
    navigate(`/library/${state.library.activeAlbumRef.id}`);
  };

  const newDeck = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const name = "New deck";
    const newDeckRef = await createDeck({ name });
    // setAnchorEl(null);
    dispatch({
      type: "UPDATE_ACTIVE_DECK_REF",
      payload: { activeDeckRef: newDeckRef },
    });
    setEditDeck({ ref: newDeckRef, name: name, type: "deck" });
  };

  const newAlbum = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const name = "New album";
    const newAlbumRef = await createAlbum({ name });
    dispatch({
      type: "UPDATE_ACTIVE_ALBUM_REF",
      payload: { activeAlbumRef: newAlbumRef },
    });
    setEditDeck({ ref: newAlbumRef, name: name, type: "album" });
  };

  const handleEditDeck = (elt) => (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({
      type: "UPDATE_ACTIVE_DECK_REF",
      payload: { activeDeckRef: elt.ref },
    });
    // setAnchorEl(null);
    setEditDeck({ ...elt, type: "deck" });
  };

  const handleShareDeck = (elt) => (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({
      type: "UPDATE_ACTIVE_DECK_REF",
      payload: { activeDeckRef: elt.ref },
    });
    // setAnchorEl(null);
    setShareDeck({ ...elt, type: "deck" });
  };

  const handleEditAlbum = (elt) => (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({
      type: "UPDATE_ACTIVE_ALBUM_REF",
      payload: { activeAlbumRef: elt.ref },
    });
    // setAnchorEl(null);
    setEditDeck({ ...elt, type: "album" });
  };

  const handleCancelShare = (elt) => async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const removeShareEmailApi = apiCall("removeShareEmail");
    const args = {
      email: currentUser.email,
      deckID: elt.deckID,
    };

    await removeShareEmailApi(args);
  };

  const closeGenerateImage = () => {
    dispatch({ type: "MODAL", payload: {} });
  };

  const closeEditDeck = () => {
    const destination = editDeck.type === "deck" ? "deck" : "library";
    setEditDeck(null);
    // setAnchorEl(null);
    navigate(`/${destination}`);
  };

  const closeShareDeck = () => {
    setShareDeck(null);
  };

  const clearAlert = (key) => () => {
    dispatch({ type: "CLEAR_ALERT", payload: { key } });
  };

  const handleShoppingCart = () => {
    navigate(`/cart`);
  };

  const drawerInternals = (
    <div style={styles.drawerContainer}>
      <List>
        <ListItem
          key={"My Decks"}
          style={styles.listHeading}
          onClick={() =>
            dispatch({
              type: "CONTROL",
              payload: { name: "decksOpen", state: !decksOpen },
            })
          }
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"My Decks"} />
          {decksOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {decksOpen && (
          <ListItem key={"New Deck"} style={styles.createDeckListItem}>
            <Typography variant="inherit" style={styles.createDeckText}>
              Create new deck
            </Typography>
            <AddCircleIcon style={styles.deckListIcon} onClick={newDeck} />
          </ListItem>
        )}
        {decksOpen &&
          state.decks.deckList &&
          state.decks.deckList.map((elt) => (
            <ListItem
              key={elt.ref.id}
              onClick={switchActiveDeckDrawer(elt.ref)}
              style={{
                ...styles.deckListItem,
                ...(activeDeck.ref && elt.ref.id === activeDeck.ref.id
                  ? styles.activeDeck
                  : null),
              }}
            >
              <Typography
                variant="inherit"
                style={styles.deckListText}
              >{`${elt.name}`}</Typography>
              <div>
                <ShareIcon
                  style={styles.deckListIcon}
                  onClick={handleShareDeck(elt)}
                />
                <EditIcon
                  style={styles.deckListIcon}
                  onClick={handleEditDeck(elt)}
                />
              </div>
            </ListItem>
          ))}
        {state.decks.sharedDeckList &&
          state.decks.sharedDeckList.length > 0 && (
            <ListItem
              key={"Shared"}
              style={styles.listHeading}
              onClick={() =>
                dispatch({
                  type: "CONTROL",
                  payload: { name: "sharedDecksOpen", state: !sharedDecksOpen },
                })
              }
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"Shared"} />
              {sharedDecksOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
        {sharedDecksOpen &&
          state.decks.sharedDeckList &&
          state.decks.sharedDeckList.map((elt) => (
            <ListItem
              key={elt.ref.id}
              onClick={switchActiveDeckDrawer(elt.ref)}
              style={styles.deckListItem}
            >
              <Typography
                variant="inherit"
                style={styles.deckListText}
              >{`${elt.name}`}</Typography>
              <CancelIcon
                style={styles.cancelShareIcon}
                onClick={handleCancelShare(elt)}
              />
            </ListItem>
          ))}
      </List>
      <Divider />
      <List>
        <ListItem
          key={"Library"}
          style={styles.listHeading}
          onClick={() =>
            dispatch({
              type: "CONTROL",
              payload: { name: "libraryOpen", state: !libraryOpen },
            })
          }
        >
          <ListItemIcon>
            <PhotoLibraryIcon />
          </ListItemIcon>
          <ListItemText primary={"Library"} />
          {libraryOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {libraryOpen && isAdmin && (
          <ListItem key={"New Album"} style={styles.createDeckListItem}>
            <Typography variant="inherit" style={styles.createDeckText}>
              Create new album
            </Typography>
            <AddCircleIcon style={styles.deckListIcon} onClick={newAlbum} />
          </ListItem>
        )}
        {libraryOpen &&
          state.library.albumList &&
          state.library.albumList.map((elt) => (
            <ListItem
              key={elt.ref.id}
              onClick={switchActiveAlbum(elt.ref)}
              style={{
                ...styles.albumListItem,
                ...(view === "library" &&
                activeAlbum.ref &&
                elt.ref.id === activeAlbum.ref.id
                  ? styles.activeAlbum
                  : null),
              }}
            >
              <Typography
                variant="inherit"
                style={styles.deckListText}
              >{`${elt.name}`}</Typography>
              {isAdmin && (
                <EditIcon
                  style={styles.deckListIcon}
                  onClick={handleEditAlbum(elt)}
                />
              )}
            </ListItem>
          ))}
      </List>
    </div>
  );

  return (
    <div style={styles.root}>
      {view === "welcome" && <ModalHelp view={view} />}
      <AppBar position="sticky" color="default" style={styles.appBar}>
        <div style={styles.headerToolbar}>
          {!state.app.controls.drawerIsOpen && (
            <MenuIcon style={styles.menuIcon} onClick={toggleDrawer(true)} />
          )}
          {state.app.controls.drawerIsOpen && (
            <MenuOpenIcon
              style={styles.menuIcon}
              onClick={toggleDrawer(false)}
            />
          )}
          <div
            style={
              state.app.isMobile ? styles.deckSelectMobile : styles.deckSelect
            }
          >
            <Typography
              ref={deckSelectRef}
              style={styles.deckSelectText}
              onClick={handleClick}
            >
              {deckLabel}
            </Typography>
          </div>

          <div style={styles.rightSection}>
            <Tooltip title={orderButtonTooltip}>
              {/* MUSE CHANGE */}
              <Button
                variant="contained"
                // href="https://www.musedecks.com"
                onClick={handleOrderButtonClick}
                style={orderButtonStyle}
              >
                {/* www.musedecks.com */}
                {orderButtonContents}
              </Button>
              {/* MUSE CHANGE */}
            </Tooltip>
            {cartCount > 0 && (
              <Badge
                badgeContent={cartCount}
                style={{ cursor: "pointer" }}
                onClick={handleShoppingCart}
                color="error"
                overlap="circular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <ShoppingCartIcon
                  onClick={handleShoppingCart}
                  style={styles.shoppingCart}
                />
              </Badge>
            )}
            <UserNav />
          </div>
        </div>
        {view === "library" && <LibraryNotify />}
        <div style={styles.alertBox}>
          {state.app.uploadStatus && <AlertUploadStatus />}
          {Object.keys(state.app.alerts)
            .sort((a, b) => b - a)
            .map((key) => (
              <Alert
                key={key}
                onClose={clearAlert(key)}
                severity={state.app.alerts[key].type}
                style={styles.alert}
              >
                {state.app.alerts[key].message}
              </Alert>
            ))}
        </div>
      </AppBar>
      <div style={{ ...styles.main, zIndex: drawerZIndex }}>
        {!state.app.isMobile && (
          <Drawer
            anchor="left"
            variant="persistent"
            open={state.app.controls.drawerIsOpen}
            transitionDuration={250}
            sx={styles.drawer}
          >
            <div style={styles.drawerHeaderSpacer}> </div>
            {view === "library" && (
              <div style={styles.drawerNotifySpacer}> </div>
            )}
            {drawerInternals}
          </Drawer>
        )}

        {state.app.isMobile && (
          <SwipeableDrawer
            anchor="left"
            open={state.app.controls.drawerIsOpen}
            transitionDuration={250}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={styles.drawer}
          >
            {drawerInternals}
          </SwipeableDrawer>
        )}

        <Gallery view={view} />
      </div>

      {state.app.modal.type === "generateImage" && (
        <GenerateImageDialog handleClose={closeGenerateImage} />
      )}
      {editDeck && (
        <EditDeckDialog deck={editDeck} handleClose={closeEditDeck} />
      )}
      {shareDeck && (
        <ShareDeckDialog deck={shareDeck} handleClose={closeShareDeck} />
      )}
    </div>
  );
}

export default Create;
