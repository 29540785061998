import React, { useContext, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppContext from "./app-context";

// Firebase
import { serverTimestamp, updateDoc } from "firebase/firestore";

const styles = {
  deleteButton: {
    color: "white",
    backgroundColor: "red",
  },
  dialogContentText: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
  },
};

export default function EditDeckDialog({ deck, handleClose }) {
  const { dispatch } = useContext(AppContext);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleRename = (evt) => {
    evt.preventDefault();
    const { name } = evt.target.elements;
    try {
      updateDoc(deck.ref, { name: name.value }).catch((error) => {
        console.log("Error renaming deck for deckID:", deck.ref.id, error);
        return new Error(
          "Unable to rename deck. Please try again or contact support."
        );
      });
    } catch (error) {
      alert(error);
    } finally {
      handleClose();
    }
  };

  const handleConfirmDelete = (evt) => {
    setConfirmDelete(true);
  };

  const handleDelete = (evt) => {
    try {
      deck.type === "deck" && dispatch({ type: "CLEAR_ACTIVE_DECK_REF" });
      deck.type === "album" && dispatch({ type: "CLEAR_ACTIVE_ALBUM_REF" });
      // Set deleted_at on the deck
      updateDoc(deck.ref, { deleted_at: serverTimestamp() }).catch((error) => {
        console.log(`Error deleting ${deck.type} for id:`, deck.ref.id, error);
        return new Error(
          `Unable to delete ${deck.type}. Please try again or contact support.`
        );
      });
    } catch (error) {
      alert(error);
    } finally {
      handleClose();
    }
  };

  if (confirmDelete) {
    return (
      <Dialog
        disableEscapeKeyDown
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Are you sure you want to delete [{deck.name}]?{" "}
        </DialogTitle>
        <form onSubmit={handleRename}>
          {/* <DialogContent>
                        <DialogContentText>
                            THIS CANNOT BE UNDONE.
                        </DialogContentText>
                    </DialogContent> */}
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              style={styles.deleteButton}
              variant="contained"
            >
              <DeleteForeverIcon /> DELETE
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {/* <DialogTitle id="form-dialog-title">Rename deck</DialogTitle> */}
      <form onSubmit={handleRename}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Deck Name"
            type="text"
            defaultValue={deck.name}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>

      <hr />

      <Button
        style={styles.deleteButton}
        onClick={handleConfirmDelete}
        variant="contained"
      >
        <DeleteForeverIcon /> DELETE {deck.type.toUpperCase()}
      </Button>
    </Dialog>
  );
}
