import React, { useContext, useEffect } from "react";
// import * as Sentry from "@sentry/react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { AuthContext } from "./auth-provider";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../utils/firebase";

const COMMIT_HASH = process.env.REACT_APP_VERSION;

const AdminTest = () => {
  const { isAdmin } = useContext(AuthContext);
  const navigate = useNavigate();

  const testEmail = async () => {
    const testEmailApi = apiCall("testEmail");

    const res = await testEmailApi();
    // alert(res);
    console.log(res);
  };

  const testFunction = async () => {
    const testFunctionApi = apiCall("testFunction");

    const res = await testFunctionApi();
    alert(res.data.imageURL);
    console.log(res);
  };

  const replicateFunction = async () => {
    const replicateFunctionApi = apiCall("replicateFunction");

    const res = await replicateFunctionApi();
    alert(res.data.imageURL);
    console.log(res);
  };

  const breakTheWeb = async () => {
    const trap = {};
    console.log(trap.set.spring);
  };

  const home = () => {
    navigate("/deck");
  };

  useEffect(() => {
    if (isAdmin === false) {
      navigate("/deck");
    }
  }, [isAdmin, navigate]);

  if (!isAdmin) {
    return <></>;
  }

  return (
    <div>
      <Button onClick={home}>Home</Button>

      <Button onClick={breakTheWeb}>Trigger Exception</Button>

      <Button onClick={testEmail}>Test Email</Button>

      <Button onClick={testFunction}>Test Function</Button>

      <Button onClick={replicateFunction}>Replicate!</Button>

      <Chip
        label={`git commit: ${COMMIT_HASH.substring(0, 7)}`}
        variant="outlined"
      />
    </div>
  );
};

export default AdminTest;
