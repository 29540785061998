import React, { useContext } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import theme from "../styles/theme";
import Link from "@mui/material/Link";
// import { AuthContext } from "./auth-provider";
import AppContext from "./app-context";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

// Firebase
import { updateDoc } from "firebase/firestore";

const styles = {
  scrim: {
    zIndex: 999,
    height: "110vh",
    width: "110vw",
    background: "rgba(0, 0, 0, 0.6)",
    position: "fixed",
  },
  box: {
    zIndex: 1000,
    position: "fixed",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "5px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: "95vw",
    // height: '400px',
    maxWidth: "350px",
    padding: "10px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  headingBig: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    fontSize: "2em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  headingSmall: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  stepsHeading: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1.3em",
    textAlign: "left",
  },
  backDesignImage: {
    height: "40px",
    borderRadius: "5px",
    // boxShadow: "0 0.25rem 0.5rem #808080",
  },
  listRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "15px",
  },
  listHeading: {
    width: "65px",
    padding: "5px",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 auto",
    height: "100%",
  },
  listItemAddIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // boxShadow: "0 0.25rem 0.5rem #808080",
    marginBottom: "5px",
  },
  listItemCropIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // boxShadow: "0 0.25rem 0.5rem #808080",
    marginBottom: "5px",
  },
  listItemBackDesign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
  },
  listText: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    marginLeft: "10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "1em",
    color: "#707070",
  },
  dividerFull: {
    width: "100%",
    height: "1px",
    color: "#A9A9A9",
    marginTop: "10px",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  ppLink: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: "#808080",
    textTransform: "none",
    marginLeft: "4px",
  },
  buttonRow: {
    width: "100%",
    paddingTop: "10px",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function ModalProfile({ handleClose }) {
  // const { currentUser } = useContext(AuthContext);
  const { state } = useContext(AppContext);

  const scrimClick = () => {
    handleClose();
  };

  const privacyPolicy = () => {
    window.open("https://moments.cards/privacy-policy");
  };

  // const updateName = (evt) => {
  //     updateDoc(state.userData.ref, {
  //         name: evt.target.value
  //     })
  // }

  // const updateEmail = (evt) => {
  //    updateDoc(state.userData.ref, {
  //         contactEmail: evt.target.value
  //     })
  // }

  const handleChangeOptOut = () => {
    const newOptOut = !state.userData.emailOptOut;
    updateDoc(state.userData.ref, {
      emailOptOut: newOptOut,
    });
  };

  const handleChangeCropWarning = () => {
    const newCropWarning = !state.userData.showCropWarning;
    updateDoc(state.userData.ref, {
      showCropWarning: newCropWarning,
    });
  };

  const handleZoomOnMobile = () => {
    const newZoomOnMobile = !state.userData.zoomOnMobile;
    updateDoc(state.userData.ref, {
      zoomOnMobile: newZoomOnMobile,
    });
  };

  return (
    <>
      <div style={styles.scrim} onClick={scrimClick}></div>
      <div style={styles.box}>
        {handleClose && (
          <IconButton
            aria-label="close"
            style={styles.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
        {/* <div style={classes.headingBig}>
                  <Typography variant="inherit">
                      Welcome!
              </Typography>
              </div> */}
        <div style={styles.headingSmall}>
          <Typography variant="inherit">Account Profile</Typography>
        </div>
        <hr style={styles.dividerFull} />
        <div>
          {/* <div style={classes.listRow}>
                      <div style={classes.listHeading}>
                          Profile Name:
                      </div>
                      <div style={classes.listText}>
                          {currentUser.displayName}
                      </div>
                  </div>

                  <div style={classes.listRow}>
                      <div style={classes.listHeading}>
                          Preferred Name:
                      </div>
                      <div style={classes.listText}>
                          <TextField style={classes.inputs} onChange={updateName} id="name" label="" variant="outlined" value={state.userData.name} />
                      </div>
                  </div> */}

          {/* <div style={classes.listRow}>
                      <div style={classes.listHeading}>
                          Sign In Email:
                      </div>
                      <div style={classes.listText}>
                          {currentUser.email}
                      </div>
                  </div>

                  <div style={classes.listRow}>
                      <div style={classes.listHeading}>
                          Preferred Email:
                      </div>
                      <div style={classes.listText}>
                          <TextField style={classes.inputs} onChange={updateEmail} id="name" label="" variant="outlined" value={state.userData.contactEmail} />
                      </div>
                  </div> */}

          <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <Checkbox
                checked={!!state.userData.emailOptOut}
                onChange={handleChangeOptOut}
                name="optOut"
                color="primary"
              />
            </div>
            <div style={styles.listText}>Opt out of promotional emails</div>
          </div>

          <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <Checkbox
                checked={!!state.userData.showCropWarning}
                onChange={handleChangeCropWarning}
                name="cropWarning"
                color="primary"
              />
            </div>
            <div style={styles.listText}>Show crop warning in gallery</div>
          </div>

          <div style={styles.listRow}>
            <div style={styles.listHeading}>
              <Checkbox
                checked={!!state.userData.zoomOnMobile}
                onChange={handleZoomOnMobile}
                name="zoomOnMobile"
                color="primary"
              />
            </div>
            <div style={styles.listText}>Zoom in on mobile</div>
          </div>

          <div style={styles.buttonRow}>
            <Button
              style={styles.doneButton}
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </div>

          <hr style={styles.dividerFull} />

          <Link
            style={styles.ppLink}
            onClick={privacyPolicy}
            href="#"
            underline="hover"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </>
  );
}
